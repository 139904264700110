import React, { useEffect, useState } from "react";

import "./styles.scss";
import { useSortBy, useTable } from "react-table";
import { databaseManager } from "../../services";

const EditableCell = ({ value: initialValue, row: { index }, column: { id } }) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue || "");

  const onChange = e => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} />;
};

const defaultColumn = {
  Cell: EditableCell,
};

const ReactTable = ({ columns, data, updateMyData, fetchData, type, activeFilters, handleSortFilters }) => {
  //TODO adicionar paginacao e fetchData nas props

  const sortTypes = {
    basic: (rowA, rowB, columnId) => {
      const valueA = rowA.values[columnId];
      const valueB = rowB.values[columnId];
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    },
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn,
    //        sortTypes
  });

  /*
    const useStoredSorting = (headerGroups, tableName) => {
        const parsedHeader = JSON.parse(sessionStorage.getItem(`${tableName}_sortedHeader`));
        if (!parsedHeader) return headerGroups;
        let headers = [...headerGroups[0]?.headers];
        const header = headers[parsedHeader.headerIndex];
        if (!header || header?.Header !== parsedHeader?.headerInfo?.Header) return headerGroups;
        header.isSorted = parsedHeader?.headerInfo?.isSorted;
        header.isSortedDesc = parsedHeader?.headerInfo?.isSortedDesc;
        headers[parsedHeader.headerIndex] = header;
        headers = headers.map((header) => {
            if (header.Header !== parsedHeader?.headerInfo?.Header)
                header.isSortedDesc = false;
            return header;
        })
        return [{...headerGroups[0], headers}];
    }

    const storeSorting = (info, headerGroups, tableName) => {
        setTimeout(() => {
            const headers = [...info.headers];
            let header;
            let headersTest = headers?.filter((header) => header.isSorted);
            console.log("Headers: ", headersTest);
            if (!headersTest?.length){
                sessionStorage.removeItem(`${tableName}_sortedHeader`);
                return;
            } else if (headersTest?.length > 1){
                const alreadyStored = JSON.parse(sessionStorage.getItem(`${tableName}_sortedHeader`));
                console.log("Was already stored: ", alreadyStored);
                if (alreadyStored){
                    header = headersTest?.find((header) => header.Header !== alreadyStored.headerInfo.Header);
                    sessionStorage.removeItem(`${tableName}_sortedHeader`);
                }
            }else {
                header = headersTest[0];
            }
            if (!header){
                sessionStorage.removeItem(`${tableName}_sortedHeader`);
                return;
            }
            const index = headers.findIndex((entry) => entry.Header === header.Header) || headers?.indexOf(headers?.find((entry) => entry.Header === header.Header));
            const headerToStore = {headerIndex: index, headerInfo : {Header : header.Header, isSorted : true, isSortedDesc: !header.isSortedDesc}};

            console.log("Saved: ", headerToStore)
            sessionStorage.setItem(`${tableName}_sortedHeader`, JSON.stringify(headerToStore));
            console.log("IN LOCAL STORAGE: ", JSON.parse(sessionStorage.getItem(`${tableName}_sortedHeader`)));
        }, 0);
    }

    const [sortedHeaderGroups, setSortedHeaderGroups] = useState(useStoredSorting(headerGroups, type));*/

  const storeSorting = (info, column) => {
    setTimeout(() => {
      const headers = [...info.headers];
      let header;
      let headersTest = headers?.filter(header => header.id === column.id);
      if (!headersTest?.length) {
        handleSortFilters(null);
      } else if (headersTest.length > 0) {
        if (headersTest.length > 1) {
          header = headersTest?.find(header => header.Header !== activeFilters[`${type}_sorter`]?.headerInfo?.Header);
        } else header = headersTest[0];
        const index =
          headers.findIndex(entry => entry.Header === header.Header) ||
          headers?.indexOf(headers?.find(entry => entry.Header === header.Header));
        const order = header.isSortedDesc === undefined ? "asc" : header.isSortedDesc === "asc" ? "desc" : null;
        //console.log(headersTest)
        if (order === null) {
          handleSortFilters(null);
          return;
        }
        const newFilter = {
          headerIndex: index,
          headerInfo: { Header: header.Header, isSorted: header.isSorted, isSortedDesc: order, id: header.id },
        };
        //console.log("New filter: ", newFilter)
        handleSortFilters(newFilter);
      }
    }, 0);
  };

  const identifier = `${type}_sorter`;
  //const sortedHeaders = !activeFilters[identifier] ? headerGroups : headerGroups.map((headerGroup) => {
  const sortedHeaders = headerGroups.map(headerGroup => {
    const headers = headerGroup.headers.map((header, index) => {
      if (
        header.Header === activeFilters[identifier]?.headerInfo?.Header &&
        index === activeFilters[identifier]?.headerIndex
      ) {
        header.canSort = true;
        header.isSorted = activeFilters[identifier].headerInfo.isSorted;
        header.isSortedDesc = activeFilters[identifier].headerInfo.isSortedDesc;
      } else {
        header.isSorted = undefined;
        header.isSortedDesc = undefined;
      }
      return header;
    });
    return { ...headerGroup, headers };
  });

  const forbiddenColumns = databaseManager.getForbiddenSorts(type);

  return (
    <>
      <button className={"simple-button green"} style={{ margin: "0 3px" }} onClick={updateMyData}>
        Atualizar dados
      </button>
      <table style={type === "properties" ? { minWidth: "1560px" } : {}} {...getTableProps()} className={"Listing"}>
        <thead>
          {sortedHeaders?.map(headerGroup => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  onClick={forbiddenColumns?.includes(column?.id) ? () => {} : () => storeSorting(headerGroup, column)}
                  {...column?.getHeaderProps({})}
                >
                  {column.render("Header")}
                  {column.canSort &&
                    (column.isSortedDesc ? (
                      column.isSortedDesc === "asc" ? (
                        <span> &#x2193;</span>
                      ) : (
                        <span> &#x2191;</span>
                      )
                    ) : (
                      ""
                    ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr style={i % 2 === 0 ? { backgroundColor: "white" } : {}} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    style={
                      cell.column.Header === "Status do Cliente"
                        ? {
                            textAlign: "center",
                            minWidth: "160px",
                          }
                        : { textAlign: "center" }
                    }
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows?.length > 10 && (
        <button className={"simple-button green"} style={{ margin: "0 3px" }} onClick={updateMyData}>
          Atualizar dados
        </button>
      )}
    </>
  );
};

export default ReactTable;
