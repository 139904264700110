import { Checkbox, Flex, Input } from "antd";
import React, { useEffect, useState } from "react";
import { formatCamelCase } from "../../../services";

import { getPropertyFeatures } from "../../../services/propertyService";

const PropertyFeatures = ({ property, setProperty }) => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const fetchedFeatures = await getPropertyFeatures();
        setFeatures(fetchedFeatures);
      } catch (error) {
        console.error("Error fetching property features:", error);
      }
    };

    fetchFeatures();
  }, []);

  return (
    <Flex vertical gap={8}>
      {features.map(f => {
        switch (f.feature_type) {
          case "balcony":
            return (
              <FeatureInput
                property={property}
                setProperty={setProperty}
                type={f.feature_type}
                private_feature={false}
              />
            );
          case "parking":
            return <FeatureInput property={property} setProperty={setProperty} type={f.feature_type} area={false} />;
          case "storageRoom":
            return (
              <FeatureInput property={property} setProperty={setProperty} type={f.feature_type} multiple={false} />
            );
          case "elevator":
            return (
              <FeatureInput
                property={property}
                setProperty={setProperty}
                type={f.feature_type}
                area={false}
                private_feature={false}
              />
            );
          case "touristLicense":
            return (
              <FeatureInput
                property={property}
                setProperty={setProperty}
                type={f.feature_type}
                area={false}
                private_feature={false}
                multiple={false}
              />
            );
          case "garage":
            return (
              <FeatureInput
                property={property}
                setProperty={setProperty}
                type={f.feature_type}
                private_feature={false}
              />
            );

          default:
            // Terrace, Yard
            return <FeatureInput property={property} setProperty={setProperty} type={f.feature_type} />;
        }
      })}
    </Flex>
  );
};

const FeatureInput = ({ property, setProperty, type, area = true, private_feature = true, multiple = true }) => {
  const [showInput, setShowInput] = useState(Object.keys(property.featuresObject[type] || {}).length > 0);

  const handleCheck = e => {
    setShowInput(e.target.checked);
    if (!e.target.checked) {
      setProperty(prevProperty => {
        let remainingFeatures = prevProperty.featuresObject;
        delete remainingFeatures[type];

        return {
          ...prevProperty,
          featuresObject: remainingFeatures,
        };
      });
    } else {
      let _e = { target: { value: 1 } };
      handleAmountChange(_e);
    }
  };

  const handleAmountChange = e => {
    setProperty(prevProperty => ({
      ...prevProperty,
      featuresObject: {
        ...prevProperty.featuresObject,
        [type]: {
          ...prevProperty.featuresObject[type],
          amount: e.target.value,
        },
      },
    }));
  };

  const handleAreaChange = e => {
    setProperty(prevProperty => ({
      ...prevProperty,
      featuresObject: {
        ...prevProperty.featuresObject,
        [type]: {
          ...prevProperty.featuresObject[type],
          feature_area: e.target.value,
        },
      },
    }));
  };

  const handlePrivateChange = e => {
    setProperty(prevProperty => ({
      ...prevProperty,
      featuresObject: {
        ...prevProperty.featuresObject,
        [type]: {
          ...prevProperty.featuresObject[type],
          private: e.target.checked,
        },
      },
    }));
  };

  return (
    <Flex vertical>
      <Flex align="center" style={{ padding: 4 }} gap={6}>
        <Checkbox style={{ flexDirection: "row-reverse" }} checked={showInput} onChange={handleCheck}>
          {formatCamelCase(type)}
        </Checkbox>
      </Flex>

      {showInput && (
        <Flex gap={8} style={{ marginLeft: 16, marginBottom: 4 }}>
          {multiple && (
            <Flex gap={6}>
              <Input
                type="number"
                min={0}
                size="small"
                addonBefore={"Amount"}
                value={property.featuresObject[type]?.amount || 1}
                onChange={handleAmountChange}
                style={{ width: 120 }}
              />
            </Flex>
          )}

          {area && (
            <Flex gap={6}>
              <Input
                type="number"
                min={0}
                suffix={
                  <span style={{ fontSize: 12 }}>
                    m<sup>2</sup>
                  </span>
                }
                size="small"
                addonBefore={"Area"}
                value={property.featuresObject[type]?.feature_area}
                onChange={handleAreaChange}
                style={{ width: 150 }}
              />
            </Flex>
          )}

          {private_feature && (
            <Checkbox
              style={{ flexDirection: "row-reverse" }}
              checked={property.featuresObject[type]?.private}
              onChange={handlePrivateChange}
            >
              Private?
            </Checkbox>
          )}
        </Flex>
      )}
    </Flex>
  );
};
export default PropertyFeatures;
