import React, { useMemo, useState } from "react";
import Modal from "../../Modal";
import { acceptVisit, cancelVisitRequest, markVisitAsCompleted, rejectVisit } from "../../../services/propertyService";
import { DateTime } from "luxon";

const VisitModal = ({ visit, handleClose }) => {
  //status => "rejected" | "completed" | "booked" | "request" | "cancelled" | "pending_buyer" | "pending_seller"
  const getDateFromIso = date => {
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
  };

  const statusTrans = {
    rejected:
      "Rejeitada a " +
      getDateFromIso(visit.rejected) +
      "." +
      (visit.rejection_reason === "new_visit_reschedule" ? "O comprador fez um novo pedido" : ""),
    completed: "Completa. Realizada a " + getDateFromIso(visit.date),
    booked: "Agendada. Data: " + getDateFromIso(visit.date),
    request: "Pedido feito a " + getDateFromIso(visit.created_at) + ". Aguarda confirmação do vendedor.",
    cancelled: "Cancelada no dia " + getDateFromIso(visit.cancelled) + ".",
    pending_buyer:
      "Vendedor fez uma sugestão de nova data a " +
      getDateFromIso(visit.seller_suggestion_date) +
      ". Aguarda confirmação do buyer",
    expired: "Expirada",
  };

  return (
    <Modal closeModal={handleClose}>
      {["request", "completed", "cancelled", "expired", "rejected"].includes(visit.status) && (
        <div>Estado: {statusTrans[visit.status]}</div>
      )}
      {visit.status === "request" && (
        <>
          <div style={{ marginBottom: "50px" }}>
            Agendar visita a <strong>{visit.property_title}</strong> Ref.<strong>{visit.property_reference}</strong>
          </div>
          <button
            className={"simple-button green mt-1"}
            style={{ width: "25%", margin: "10px auto" }}
            onClick={async () => {
              await acceptVisit(visit.id, visit.date);
              handleClose();
            }}
          >
            Aceitar
          </button>
          <button
            className={"simple-button red mt-1"}
            style={{ width: "25%", margin: "10px auto" }}
            onClick={async () => {
              await rejectVisit(visit.id);
              handleClose();
            }}
          >
            Rejeitar
          </button>
        </>
      )}
      {visit.status === "booked" && (
        <button
          className={"simple-button mt-1"}
          style={{ width: "25%", margin: "10px auto" }}
          onClick={async () => {
            await cancelVisitRequest(visit.id);
            handleClose();
          }}
        >
          Cancelar Pedido
        </button>
      )}
      {visit.status === "completed" && !visit.done && (
        <div>
          <h1>Gerir Visita</h1>
          <span>Marcar visita como completa:</span>
          <button
            className={"simple-button green ml-1"}
            onClick={async () => {
              await markVisitAsCompleted(visit.id);
              handleClose();
            }}
          >
            Confirmar
          </button>
        </div>
      )}
    </Modal>
  );
};

export default VisitModal;
