import { get, post } from "./index";
import React, { createContext, useContext, useEffect, useState } from "react";

const authContext = createContext();

const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await loadUser();
      setLoading(false);
    })();
  }, []);

  const loadUser = async () => {
    if (user) return user;
    try {
      let { data } = await get("/backoffice/me");
      setUser(data.user);
      return data.user;
    } catch (err) {
      console.log("error on load user", err);
      //navigate("/login");
      return null;
    }
  };

  const login = async (email, password) => {
    let res = await post("/backoffice/login", { email, password });
    setUser(res.data.user);
    return res.data?.user;
  };

  const logout = async () => {
    await post("/backoffice/logout");
    //navigate("/login");
    setUser(null);
  };

  return { user, login, logout, loading };
};

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{auth.loading ? <h1>Loading</h1> : children}</authContext.Provider>;
};

export { ProvideAuth, useAuth };
