import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { capitalizeOnlyFirstLetter, clampText, databaseManager, isEmpty } from "../../services";
import { useAuth } from "../../services/auth";
import {
  associateDocumentToAdvisor,
  deleteAdvisor,
  getAllAdvisors,
  removeAdvisorAssociation,
} from "../../services/user";
import LeadForm from "../Lead/createLead";
import Modal from "../Modal";
import CreateAdvisor from "../Modals/CreateAdvisor";
import CreateUser from "../Modals/CreateUser";
import CreateVisit from "../Modals/CreateVisit";
import VisitModal from "../Modals/Visit";
import Pagination from "../Pagination";
import ReactTable from "../ReactTable";
import "./styles.scss";

const SelectableCell = ({ value, selectGenerator, row }) => {
  const [selectable, setSelectable] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (typeof selectGenerator === "function") {
        const selectableData = await selectGenerator(value, row);
        setSelectable(selectableData);
      }
    }

    fetchData();
  }, [value, selectGenerator]);

  return selectable === null ? <p>Loading...</p> : selectable;
};

const AssociateAdvisorButton = ({ documentToAssociate, type, allAdvisors, currentAdvisors }) => {
  const [advisors, setAdvisors] = useState(allAdvisors);
  const [selectedAdvisors, setSelectedAdvisors] = useState(currentAdvisors || []);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const selectInputRef = useRef();

  const clearValue = () => {
    selectInputRef.current.clearValue();
    setSelectedAdvisor(null);
  };

  useEffect(() => {
    setAdvisors(allAdvisors);
  }, [allAdvisors?.length]);

  const handleAssociation = async () => {
    try {
      let showSuccess = false;
      for (let selectedAdvisor of selectedAdvisors) {
        if (!currentAdvisors?.includes(selectedAdvisor)) {
          await associateDocumentToAdvisor(selectedAdvisor, documentToAssociate, type);
          showSuccess = true;
        }
      }

      if (currentAdvisors?.length > 0) {
        for (let currentAdvisor of currentAdvisors) {
          if (!selectedAdvisors.includes(currentAdvisor)) {
            await removeAdvisorAssociation(currentAdvisor, documentToAssociate, type);
            showSuccess = true;
          }
        }
      }

      showSuccess && toast.success("Advisors atualizados com sucesso!");
    } catch (e) {
      let message =
        e.data?.message === type + "_already_associated" ? type + " já associado ao advisor." : e.data?.message || e;
      alert("Erro ao associar advisor. \n" + message);
      console.error(e);
    }
  };

  return (
    <div>
      <Select
        ref={selectInputRef}
        isMulti
        defaultValue={advisors.filter(a => currentAdvisors?.includes(a.value))}
        options={advisors}
        onBlur={handleAssociation}
        onChange={o => setSelectedAdvisors(o.map(o => o.value))}
        placeholder={"Selecionar advisor"}
      />
    </div>
  );
};

const NotesModal = ({ id, notes, submit, closeModal, refetch }) => {
  const [text, setText] = useState(notes);

  const handleSubmition = async () => {
    try {
      await submit(id, text);
      refetch();
      toast.info("Notas submetidas com sucesso.");
      closeModal();
    } catch (e) {
      toast.error("Erro ao submeter notas.");
      console.error(e);
    }
  };

  return (
    <Modal onlyCross={true} closeModal={closeModal} style={{ width: "100%" }}>
      <h3>Escreva as suas notas:</h3>
      <textarea
        value={text}
        style={{ resize: "none", width: "90%", margin: "0 auto 10px auto", fontSize: "15px" }}
        rows={20}
        onChange={e => setText(e.target.value)}
      ></textarea>
      <button className={"simple-button green"} style={{ width: "100px", margin: "auto" }} onClick={handleSubmition}>
        Submeter
      </button>
    </Modal>
  );
};

const GenericListing = props => {
  const { id } = useParams(); // Only used for sublists since the function wont be the default
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(databaseManager.getEntriesNumber(props.table) || 20);
  const [activeFilters, setActiveFilters] = useState({ pageSize: databaseManager.getEntriesNumber(props.table) || 20 });
  const [searchInput, setSearchInput] = useState("");
  const fetchData = useCallback(
    props.url !== "sublist"
      ? () => props.getDataFunction(page, searchInput, activeFilters)
      : () => props.getDataFunction(id),
    [page, activeFilters, props.getDataFunction],
  );
  const { isLoading, data, isError, error, isPreviousData, refetch } = useQuery([props.table, page], fetchData, {
    keepPreviousData: false,
    refetchOnMount: true,
  });
  let noMoreDataEntries = data && data.length < pageSize;
  const [showForm, setShowForm] = useState(false);
  const [modal, setModal] = useState(null);
  const [editableData, setEditableData] = useState(null);
  const [advisors, setAdvisors] = useState([]);
  const s3Url = process.env.REACT_APP_S3_AWS_URL;
  const { user } = useAuth();
  const [showNotesModal, setShowNotesModal] = useState({
    id: null,
    notes: "",
    submit: () => {},
  });

  const handleSortFilters = (newFilter = null) => {
    const identifier = `${props.table}_sorter`;
    if (!newFilter && activeFilters[identifier]) {
      const newFilters = { ...activeFilters };
      delete newFilters[identifier];
      setActiveFilters(newFilters);
    } else if (newFilter) {
      const newFilters = { ...activeFilters };
      newFilters[identifier] = newFilter;
      setActiveFilters(newFilters);
    }
  };

  useEffect(() => {
    //console.log("Active filters: ", activeFilters);
    refetch();
  }, [activeFilters]);

  useEffect(() => {
    setSearchInput("");
    setActiveFilters({ pageSize });
  }, [props]);

  useEffect(() => {
    if (editableData) {
      setShowForm(true);
    }
  }, [editableData]);

  useEffect(() => {
    refetch();
  }, [modal]);

  useEffect(() => {
    async function fetchAllAdvisors() {
      const _advisors = await getAllAdvisors();
      setAdvisors(
        _advisors.map(advisor => {
          return { value: advisor.id, label: advisor.name };
        }),
      );
    }

    ["service_request", "lead", "evaluation", "user"].includes(props.table) && fetchAllAdvisors();
  }, [props.table]);

  const dateFormat = (value, numeric = false, includeTime = false) => {
    const options = !numeric ? { year: "numeric", month: "long", day: "numeric" } : {};
    if (includeTime) {
      options.hour = "numeric";
    }
    return new Date(value).toLocaleDateString("pt-PT", options);
  };

  const handleManageVisit = async visit => {
    //show modal
    setModal(<VisitModal visit={visit} handleClose={() => setModal(null)} />);
  };

  const handleDeleteAdvisor = async advisor_id => {
    window.confirm("Tem a certeza que quer apagar este advisor?");
    try {
      await deleteAdvisor(advisor_id);
      alert("Advisor apagado com sucesso.");
    } catch (e) {
      alert("Erro ao apagar advisor. " + (e.data?.message || e));
      console.error(e);
    }
  };

  const handleFunctionWithRefetch = async (func, row, value) => {
    await func(row, value);
    refetch();
  };

  // const handleText = async (row, value) => {
  //     setText({...text, [row.original.id]: value});
  // }

  const columns = useMemo(
    () =>
      props.fields
        ?.filter(field => user.role === "admin" || !field[2]?.includes("admin"))
        .map(field => {
          let row = {
            Header: field[0],
            accessor: field[1],
          };
          switch (field[2]) {
            case "edit":
              row.Cell = ({ value }) => (
                <button
                  style={{ borderRadius: "15px", fontSize: "11px" }}
                  onClick={async () => {
                    const data = await field[3](value);
                    if (!data) return;
                    setEditableData({ ...data?.data });
                  }}
                >
                  ID {value}
                </button>
              );
              break;
            case "link":
              row.Cell = ({ value }) => (
                <Link to={field[2].includes("s3") ? s3Url + value : value}>{clampText(value, 21)}</Link>
              );
              break;
            case "route":
              row.Cell = ({ value }) => (
                <Link className={"simple-button blue"} to={field[3](value)}>
                  {" "}
                  Gerir{" "}
                </Link>
              );
              break;
            case "boolean":
              row.Cell = ({ value }) => (
                <p>{(typeof value === "boolean" ? value : /*String(value) === "1"*/ !!value) ? "Sim" : "Não"}</p>
              );
              break;
            case "date":
              //If you want to only show a numeric date like 12/12/2020, pass any truthy value in the 4th index of the props array, eg: ["Data", "request_date", "date", true]
              row.Cell = ({ value }) => (
                <p style={{ textAlign: "center" }}>
                  {!isEmpty(value)
                    ? field[2].includes("hour")
                      ? dateFormat(value, false, true) + "h"
                      : dateFormat(value, !!field[3])
                    : value}
                </p>
              );
              break;
            case "hour":
              row.Cell = ({ value }) => (
                <p style={{ textAlign: "center" }}>
                  {!isEmpty(value)
                    ? new Date(value).toLocaleDateString("pt-PT", {
                        hour: "numeric",
                        minute: "numeric",
                      })
                    : ""}
                </p>
              );
              break;
            case "visit_suggestion":
              row.Cell = ({ value, row }) => (
                <div>
                  {value ? (
                    <>
                      <p>{dateFormat(value, false, true)}</p>
                      <p>Mensagem: {row.original.seller_suggestion_message || " - "}</p>
                      <small>Feita a {dateFormat(row.original.seller_suggestion_date, false, true)}</small>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              );
              break;
            case "selectable":
              row.Cell = ({ value, row }) => (
                <SelectableCell value={value} selectGenerator={field[3]} row={row.original} />
              );
              break;
            case "datepicker":
              row.Cell = ({ value, row }) => (
                <DatePicker
                  selected={value && new Date(value)}
                  name={"follow_up_date"}
                  id={"follow_up_date"}
                  onChange={date => handleFunctionWithRefetch(field[3], row.original, date)}
                  locale="pt-PT"
                  timeIntervals={10}
                  //minDate={new Date()}
                  placeholderText="Escolher data"
                  dateFormat="dd/MM/yyyy"
                />
              );
              break;
            case "textArea":
              row.Cell = ({ value, row }) => {
                // return <div style={{display : "flex"}}>
                // <textarea
                //     onChange={(e) => handleText(row, e.target.value)}
                //     style={{resize: "none"}}
                //     defaultValue={text[row.original.id] || ""}/>
                //     <button className={"simple-button"} style={{height : "18px", padding : "0 4px", backgroundColor : "#339999", margin: "auto 0 auto 5px"}} onClick={() => handleFunctionWithRefetch(field[3], row.original, text[row.original.id])}>Editar</button>
                // </div>
                return (
                  <button
                    className={"simple-button"}
                    onClick={() =>
                      setShowNotesModal({
                        id: row.original,
                        notes: value,
                        submit: field[3],
                      })
                    }
                  >
                    Notas
                  </button>
                );
              };
              break;
            case "advisor":
              row.Cell = ({ row }) => (
                <div>
                  <Link
                    to={"/advisors/" + row.original.id}
                    className={"simple-button blue mr-1"}
                    style={{ fontSize: "12px" }}
                  >
                    Gerir
                  </Link>
                  <button
                    className={"simple-button red mr-1"}
                    style={{ fontSize: "12px" }}
                    onClick={() => handleDeleteAdvisor(row.original.id)}
                  >
                    Apagar
                  </button>
                </div>
              );
              break;
            case "request_admin":
              row.Cell = ({ row, value }) => (
                <AssociateAdvisorButton
                  documentToAssociate={row.original.id}
                  type={props.table}
                  allAdvisors={advisors}
                  currentAdvisors={value}
                />
              );
              break;
            case "redirect":
              row.Cell = ({ row, value }) => <Link to={`${field[3]}${row.original.id}`}>{value}</Link>;
              break;
            case "visit":
              row.Cell = ({ row }) => (
                <button className={"simple-button blue"} onClick={() => handleManageVisit(row.original)}>
                  Gerir
                </button>
              );
              break;
            case "lead_name":
              row.Cell = ({ row }) => <p>{row.original.lead_name || row.original.buyer_name}</p>;
              break;
            case "checkbox":
              row.Cell = ({ row }) => (
                <input
                  type={"checkbox"}
                  checked={row.original[field[1]]}
                  onChange={() => handleRowCheckbox(field[3], row.original.id)}
                />
              );
              break;
            default:
              row.Cell = ({ value }) => <p>{value}</p>;
              break;
          }
          return row;
        }),
    [data, advisors],
  );

  const update = () => {
    if (props.url === "leads" && showForm) setShowForm(false);
    refetch().then(() => {
      toast.info("Dados atualizados com sucesso!");
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setActiveFilters({ ...activeFilters });
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const handleFilter = filter => {
    activeFilters[filter]
      ? setActiveFilters(previous => {
          delete previous[filter];
          return previous;
        })
      : setActiveFilters({ ...activeFilters, [filter]: true });
  };

  useEffect(() => {
    const filters = { ...activeFilters, pageSize: pageSize };
    setActiveFilters(filters);
    databaseManager.setEntriesNumber(props.table, pageSize);
  }, [pageSize]);

  useEffect(() => {
    const number = databaseManager.getEntriesNumber(props.table);
    if (number) setPageSize(number);
    setPage(1);
  }, [props.table]);

  const handleSearch = search => {
    setSearchInput(search);
  };

  const handleSelect = async (field, choice) => {
    if (field !== choice) setActiveFilters({ ...activeFilters, [field]: choice });
    else if (activeFilters[field]) {
      await handleFilter(field);
      update();
    }
  };

  const handleCheckbox = async field => {
    await handleFilter(field);
    update();
  };

  const handleRowCheckbox = async (functionToRun, id) => {
    await functionToRun(id)
      .then(r => {
        if (r) toast.info("Estado do pedido alterado com sucesso!", { autoClose: 2000 });
      })
      .catch(e => {
        console.log(e);
        toast.error("Erro ao alterar estado do pedido!", { autoClose: 2000 });
      });
    update();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong. {error.message}</div>;
  }

  //const sortedData = !activeFilters?.visit_sorter ? data : databaseManager.postDatabaseSorting(data, activeFilters?.visit_sorter?.headerInfo?.id, !!activeFilters?.visit_sorter?.headerInfo?.isSorted)
  //if (activeFilters?.visit_sorter) console.log(sortedData);
  return (
    <div>
      <h1 onClick={() => console.log(data)}>{props.name}</h1>
      {props.url === "leads" && (
        <button
          className={"simple-button " + (showForm ? "red" : "blue")}
          onClick={() => {
            setEditableData(null);
            setShowForm(!showForm);
          }}
        >
          {showForm ? "Cancelar" : "Criar Lead"}
        </button>
      )}
      {props.table === "lead" && (
        <button
          className={"simple-button yellow"}
          style={{ margin: "0 10px" }}
          onClick={() => setModal(<CreateVisit isLeads={props.table === "lead"} handleClose={() => setModal(null)} />)}
        >
          Agendar Visita
        </button>
      )}
      {props.url === "leads" && showForm && (
        <LeadForm showForm={showForm} update={update} editableData={editableData} />
      )}
      {props.url === "advisors" && (
        <button
          className={"simple-button blue"}
          style={{ margin: "0 3px" }}
          onClick={() => setModal(<CreateAdvisor handleClose={() => setModal(null)} />)}
        >
          Criar
        </button>
      )}
      {data?.length > 0 && data[0]?.total_count && (
        <Pagination totalCount={data[0]?.total_count} currentPage={page} pageSize={pageSize} onPageChange={setPage} />
      )}
      {!isEmpty(props.filters) && props.url !== "sublist" && (
        <div>
          {props.filters?.map(filter => {
            if (filter[1].includes("checkbox")) {
              return (
                <label key={"checkbox_" + filter[0]} style={{ marginRight: "20px" }}>
                  {capitalizeOnlyFirstLetter(filter[0])}
                  <input
                    name={filter[0]}
                    checked={activeFilters[filter[0]]}
                    value={filter[0]}
                    onChange={e => handleCheckbox(filter[0])}
                    type={filter[1]}
                  />{" "}
                </label>
              );
            } else if (filter[1].includes("button")) {
              return (
                <button
                  key={"button_" + filter[0]}
                  className={"mb-1 simple-button " + (activeFilters[filter[0]] ? "red" : "green")}
                  onClick={e => handleCheckbox(filter[0])}
                >
                  {!isEmpty(activeFilters[filter[0]]) ? "Ver sem filtros" : "Filtrar por " + filter[0]}
                </button>
              );
            } else if (filter[1].includes("text")) {
              return (
                <input
                  key={"input_" + filter[0]}
                  className={"simple-input"}
                  placeholder={"Pesquisar"}
                  style={{ marginRight: "20px", marginBottom: "20px", marginTop: "20px" }}
                  type={filter[1]}
                  onChange={e => handleSearch(e.target.value)}
                />
              );
            } else if (filter[1].includes("selectable") && filter[2]) {
              return (
                <select
                  key={"select_" + filter[0]}
                  onChange={e => handleSelect(filter[0], e.target.value)}
                  style={{ marginRight: "20px", marginBottom: "20px" }}
                >
                  {[<option key={"filter0"}>{filter[0]}</option>].concat(
                    filter[2].map((filter, i) => <option key={"filter_" + i}>{filter}</option>),
                  )}
                </select>
              );
            }
          })}
        </div>
      )}
      {/*
            <button className={"simple-button"} style={{margin: "0 3px"}} disabled={isPreviousData || page === 1}
                    onClick={() => setPage(prev => prev - 1)}>Previous
            </button>
            <button className={"simple-button"} style={{margin: "0 3px"}} disabled={isPreviousData || noMoreDataEntries}
                    onClick={() => setPage(prev => prev + 1)}>Next
            </button>
            */}
      {props.url === "users" && (
        <button
          className={"simple-button blue"}
          style={{ margin: "0 3px" }}
          onClick={() => setModal(<CreateUser handleClose={() => setModal(null)} />)}
        >
          Criar
        </button>
      )}

      {props.table === "visit" && (
        <button
          className={"simple-button"}
          style={{ margin: "0 3px" }}
          onClick={() =>
            setModal(
              <CreateVisit
                property_id={props.property_id}
                user_id={props.user_id}
                lead_id={props.lead_id}
                isLeads={!props.user_id && !!props.lead_id}
                handleClose={() => setModal(null)}
              />,
            )
          }
        >
          Agendar Visita
        </button>
      )}
      {activeFilters?.pageSize && (
        <select className={"simple-input"} style={{ margin: "0 20px" }} onChange={e => setPageSize(e.target.value)}>
          {[10, 20, 50, 100, 200, 500].map(size => (
            <option
              key={"page_size_" + size}
              value={size}
              selected={activeFilters.pageSize === size}
            >{`${size}`}</option>
          ))}
        </select>
      )}
      <ReactTable
        columns={columns}
        data={data}
        updateMyData={update}
        type={props.table}
        activeFilters={activeFilters}
        handleSortFilters={handleSortFilters}
      />
      {/*data?.length > 10 && <>
                <button className={"simple-button"} style={{marginRight: "10px"}}
                        disabled={isPreviousData || page === 1} onClick={() => setPage(prev => prev - 1)}>Previous
                </button>
                <button className={"simple-button"} style={{marginRight: "10px"}}
                        disabled={isPreviousData || noMoreDataEntries} onClick={() => setPage(prev => prev + 1)}>Next
                </button>
            </>*/}
      {!!showNotesModal.id && (
        <NotesModal
          {...showNotesModal}
          refetch={refetch}
          closeModal={() =>
            setShowNotesModal({
              id: null,
              notes: "",
              submit: () => {},
            })
          }
        />
      )}
      {(props.table === "visit" || props.table === "lead") && (
        <button
          className={"simple-button"}
          style={{ margin: "0 3px" }}
          onClick={() =>
            setModal(
              <CreateVisit
                property_id={props.property_id}
                user_id={props.user_id}
                lead_id={props.lead_id}
                isLeads={(!props.user_id && !!props.lead_id) || props.table === "lead"}
                handleClose={() => setModal(null)}
              />,
            )
          }
        >
          Agendar Visita
        </button>
      )}
      {props.children}
      {data?.length > 0 && data[0]?.total_count && (
        <Pagination totalCount={data[0]?.total_count} currentPage={page} pageSize={pageSize} onPageChange={setPage} />
      )}
      {modal}
    </div>
  );
};

export default GenericListing;
