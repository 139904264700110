import { useEffect, useState } from "react";
import PageTabs from "../PageTabs";
import { getAdvisor, removeAdvisorAssociation, updateAdvisor } from "../../services/user";
import { Link, useParams, useNavigate } from "react-router-dom";
import ResetAdvisorPassword from "../Modals/ResetAdvisorPassword";
import { toast } from "react-toastify";
import AdvisorPropertiesSelect from "../AdvisorPropertiesSelect";

const AssociatedToAdvisorSelect = ({ currentAssociated, advisor, type, typePt, setAdvisor }) => {
  const [selected, setSelected] = useState([]);
  const getDocLabel = doc => {
    if (type === "service_requests") return [doc.id, doc.name, doc.service_type].join(" | ");
    if (type === "leads" || type === "users") return [doc.id, doc.name, doc.email].join(" | ");
    if (type === "evaluations") return [doc.id, doc.client_email || "no email", doc.client_name].join(" | ");
  };

  useEffect(() => {
    setSelected(currentAssociated.map(doc => ({ label: getDocLabel(doc), value: doc.id })));
  }, [type]);

  const handleRemove = async () => {
    const deletedDocs = currentAssociated.filter(r => !selected.map(c => c.value).includes(r.id)).map(r => r.id);
    for (let deletedDocId of deletedDocs) {
      await removeAdvisorAssociation(advisor, deletedDocId, type.slice(0, -1));
    }
    setAdvisor(prev => {
      console.log("advisor[type", prev[type]);
      return { ...prev, [type]: prev[type].filter(r => !deletedDocs.includes(r.id)) };
    });
    return deletedDocs;
  };

  return (
    <div className={"d-flex flex-column"}>
      {selected.map(o => (
        <div key={o.value} className={"d-flex align-center mb-1"}>
          {o.label}
          <div
            className={"simple-button red round ml-1"}
            onClick={() => setSelected(curr => curr.filter(op => op.value !== o.value))}
          >
            x
          </div>
        </div>
      ))}
      {selected.length === 0 && (
        <div className={"d-flex align-center mb-1"}>Nenhum(a) {typePt.slice(0, -1).toLowerCase()} associado(a)</div>
      )}
      {!!currentAssociated.length && (
        <div
          className={"simple-button green mt-2"}
          style={{ width: "200px" }}
          onClick={() => {
            toast.promise(handleRemove(), {
              pending: "A remover " + type,
              success: {
                render({ data }) {
                  return `${type}${data.length > 1 ? "s removidos" : " removido"} com sucesso!`;
                },
              },
              error: "Erro ao remover " + type,
            });
          }}
        >
          Guardar
        </div>
      )}
      <Link
        to={"/" + (type === "service_requests" ? "requests" : type)}
        className={"simple-button blue mt-1"}
        style={{ width: "200px", textDecoration: "none" }}
      >
        Associar {typePt}
      </Link>
    </div>
  );
};

//TODO use react-table
const Advisor = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [advisor, setAdvisor] = useState({});
  const [selectedTab, setSelectedTab] = useState(params.tab || "info");
  const [editMode, setEditMode] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const tabs = {
    info: "Perfil",
    properties: "Propiedades",
    users: "Clientes",
    requests: "Pedidos",
    leads: "Leads",
    evaluations: "Avaliações",
  };

  useEffect(() => {
    getAdvisor(params.id)
      .then(res => {
        console.log("advisor", res);
        setAdvisor(res);
      })
      .catch(() => {
        console.error("Error getting advisor", params.id);
        navigate("/advisors");
      });
  }, []);

  const fields = [
    {
      name: "Nome",
      field: "name",
      type: "text",
      edit: true,
    },
    {
      name: "Email",
      field: "email",
      type: "text",
      edit: true,
    },
    {
      name: "Criado a",
      field: "registration_date",
      type: "date",
    },
  ];

  const handleEdit = () => {
    let fields = {};
    if (newName && newName !== advisor.name) fields.name = newName;
    if (newEmail && newEmail !== advisor.email) fields.email = newEmail;
    setLoading(true);
    updateAdvisor(advisor.id, fields)
      .then(() => {
        toast.success("Advisor atualizado com sucesso!");
        setEditMode(false);
        setAdvisor(prev => ({ ...prev, ...fields }));
      })
      .catch(e => {
        console.log("e", e);
        toast.error("Erro ao guardar alterações.\n" + e.data.message);
      })
      .finally(() => setLoading(false));
  };

  if (!advisor.id) return null;

  return (
    <div>
      <h1>{advisor.name}</h1>
      <PageTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} pathPrefix={"/advisors"} />
      {selectedTab === "info" && (
        <div>
          {editMode && (
            <button className={"simple-button green mr-1"} disabled={loading} onClick={handleEdit}>
              {loading ? "A gravar..." : "Guardar"}
            </button>
          )}
          <button
            className={"simple-button mb-1 " + (editMode ? "red" : "blue")}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Cancelar" : "Editar"}
          </button>
          {!editMode && (
            <button
              className={"simple-button yellow ml-1"}
              onClick={() =>
                setShowingModal(<ResetAdvisorPassword advisor={advisor} handleClose={() => setShowingModal(null)} />)
              }
            >
              Alterar Password
            </button>
          )}
          {fields.map(f => (
            <div key={f.field} className={"d-flex align-center mb-1"}>
              <div style={{ width: "200px" }}>{f.name}</div>
              {editMode && f.edit ? (
                <input
                  className={"simple-input"}
                  type={f.type}
                  defaultValue={advisor[f.field]}
                  onChange={e => {
                    f.field === "name" ? setNewName(e.target.value) : setNewEmail(e.target.value);
                  }}
                />
              ) : (
                <div>{f.type === "date" ? new Date(advisor[f.field]).toLocaleDateString() : advisor[f.field]}</div>
              )}
            </div>
          ))}
        </div>
      )}
      {selectedTab === "properties" && (
        <AdvisorPropertiesSelect advisorProperties={advisor.properties} advisorId={advisor.id} />
      )}
      {!["info", "properties"].includes(selectedTab) && advisor.id && (
        <AssociatedToAdvisorSelect
          currentAssociated={advisor[selectedTab === "requests" ? "service_requests" : selectedTab]}
          advisor={advisor.id}
          typePt={tabs[selectedTab]}
          type={selectedTab === "requests" ? "service_requests" : selectedTab}
          setAdvisor={setAdvisor}
        />
      )}
      {showingModal}
    </div>
  );
};

export default Advisor;
