import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { capitalizeOnlyFirstLetter, dateFormat, formatNumber, isEmpty } from "../../../services";
import {
  archiveProperty,
  deleteProperty,
  preparePropertyCMI,
  turnProcessInactive,
  verifyProperty,
} from "../../../services/propertyService";
import PropertyClosingLog from "../../Modals/PropertyClosingLog";

const PropertyTab = ({
  create = false,
  house,
  setHouse,
  stageHistoryData,
  setSelectedTab,
  changeSoldStatus,
  setChangeSoldStatus,
  changeVerificationStatus,
  setChangeVerificationStatus,
  process,
  resetFields,
  handleChange,
  save,
}) => {
  const [cancelProcess, setCancelProcess] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const styles = {
    labelStyles: {
      //textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    scrollStyles: {
      height: "200px",
      overflowX: "overlay",
    },
    inputStyles: {
      margin: "10px",
    },
    dropdownStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      maxWidth: "50%",
      flexDirection: "column",
      margin: "15px",
    },
    textAreaStyles: {
      width: "40%",
      minWidth: "300px",
      marginRight: "10px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    checkBoxStyles: {
      display: "flex",
      justifyContent: "space-between",
    },
    additionalInfoStyles: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "1000px",
    },
    selectableStyles: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    containerStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      width: "875px",
      flexDirection: "row",
      margin: "15px",
    },
    tableStyle: {
      border: "1px solid black",
      borderSpacing: "0",
      minWidth: "180px",
      maxWidth: "1570px",
    },
    tabStyle: {
      outsideDiv: {
        display: "flex",
        flexDirection: "row",
        margin: "50px 0",
        borderBottom: "1px solid rgb(213 213 213)",
      },
      selected: {
        cursor: "auto",
        borderBottom: "4px solid rgb(102, 153, 153)",
      },
      other: {
        width: "135px",
        height: "50px",
        backgroundColor: "white",
        //border: "1px solid #339999",
        color: "#303030",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 5px",
        fontWeight: "600",
        cursor: "pointer",
      },
    },
    valuationStyles: {
      outerSpan: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      ball: {
        width: "20px",
        height: "20px",
        marginLeft: "-20px",
        borderRadius: "20px",
        marginRight: "5px",
      },
    },
  };

  const handleProcessRemoval = () => {
    turnProcessInactive(process().id).then(r => {
      resetFields();
    });
  };

  const handleVerifyProperty = () => {
    setChangeVerificationStatus(false);
    verifyProperty(house.id)
      .then(r => {
        setHouse({ ...house, verified: true });
        window.alert("Propriedade verificada com sucesso!");
        resetFields();
      })
      .catch(e => {
        console.error("erro verificar propriedade", e);
        window.alert("Erro ao verificar a propriedade!\n");
      });
  };

  const handleDeleteProperty = () => {
    if (
      !house.verified &&
      window.confirm("Tem a certeza que quer apagar a propriedade? \nEste passo não é reversível!")
    ) {
      deleteProperty(house.id)
        .then(r => {
          window.alert("Propriedade apagada com sucesso!");
          window.location.href = "/admin/properties";
        })
        .catch(e => {
          console.error("erro a apagar propriedade", e);
          window.alert("Erro ao apagar a propriedade!\n" + (e.data?.code || e.data));
        });
    } else if (
      house.verified &&
      window.confirm(
        "Tem a certeza que quer arquivar esta propriedade? \nSerá excluída de toda a plataforma/backoffice.",
      )
    ) {
      archiveProperty(house.id)
        .then(r => {
          window.alert("Propriedade arquivada com sucesso!");
          window.location.href = "/admin/properties";
        })
        .catch(e => {
          console.error("erro a arquivar propriedade", e);
          window.alert("Erro ao arquivar a propriedade!\n" + (e.data?.code || e.data));
        });
    }
  };

  const stageTableEntries = [
    { name: "Docs em falta", accessor: "pending_documents" },
    {
      name: "Docs por verificar",
      accessor: "pending_verification",
    },
    { name: "CMI pendente", accessor: "pending_cmi" },
    { name: "Publicado", accessor: "published" },
    {
      name: "Reservado",
      accessor: "reserved",
    },
    { name: "Vendido", accessor: "sold" },
  ];

  const fieldsForForm = [
    /*['property_type', 'number'], ['property_condition_id', 'number'], ['year_of_construction', 'number'],*/
    ["address", "string"],
    ["address_number", "string"],
    ["parish", "string"],
    ["county", "string"],
    ["city", "string"],
    ["postal_code", "string"],
    ["premium", "number"],
    ["asking_price", "number"],
    ["constructionYear", "number"],
    ["condition", "string"],
    ["gross_private_area", "number"],
    ["dependent_gross_area", "number"],
    ["plot_area", "number"],
    ["floor", "string"],
    ["number_of_floors", "number"],
    ["number_of_rooms", "number"],
    ["number_of_bathrooms", "number"],
    ["number_of_suites", "number"],
    ["energy_certificate", "string"],
    /*['owner_comment', 'string'],*/ ["condominium_fee", "number"],
    ["imi", "number"],
    ["rent", "number"],
    ["latitude", "number"],
    ["longitude", "number"],
    ["verified", "number"],
    ["features", "object"],
    ["sun_exposure", "object"],
    ["surrounding_areas", "object"],
    ["documents", "object"],
    ["photos", "object"],
  ];

  let property_url = `http://localhost:3000/properties/${house.id}`;
  const current_url = window.location.host;
  if (current_url.includes("backoffice.staging.listoo.pt")) {
    property_url = `http://properties.staging.listoo.pt/properties/${house.id}`;
  } else if (current_url.includes("backoffice.listoo.pt")) {
    property_url = `http://properties.listoo.pt/properties/${house.id}`;
  }

  return (
    <div style={styles.pageStyles}>
      <div style={{ ...styles.containerStyles, maxWidth: "100%", width: "845px" }}>
        {fieldsForForm.map((element, i) => {
          if (element[1] !== "object" && !["condition", "premium", "verified"].includes(element[0])) {
            return (
              <div key={i} style={{ ...styles.inputStyles, marginBottom: "25px" }}>
                <label
                  style={{
                    fontWeight: "600",
                    marginBottom: "2px",
                  }}
                >
                  {capitalizeOnlyFirstLetter(element[0].replaceAll("_", " "))}
                </label>
                <br />
                {element[0] !== "floor" ? (
                  <input
                    className={"simple-input"}
                    readOnly={["latitude", "longitude"].includes(element[0])}
                    value={create ? "" : !isEmpty(house[element[0]]) ? house[element[0]] : ""}
                    type={element[1] === "string" ? "text" : "number"}
                    onChange={e => handleChange(e, element[0])}
                  />
                ) : (
                  <select
                    className={"simple-input"}
                    style={{ width: "177px" }}
                    value={create ? "" : house[element[0]]}
                    key={i}
                    onChange={e => handleChange(e, element[0])}
                  >
                    {Array.from({ length: 13 }, (_, i) => i - 1).map(value => (
                      <option key={value} value={value}>
                        {value === -1 ? "BASEMENT" : value === 0 ? "GROUND-FLOOR" : value}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            );
          } else if (["condition", "premium"].includes(element[0])) {
            const array =
              element[0] === "condition"
                ? create
                  ? ""
                  : Array.from(house?.property_conditions)
                : element[0] === "premium"
                  ? ["liberty", "premium", "premium_visits"]
                  : house[element[0]];
            return (
              <div style={{ ...styles.selectableStyles, ...styles.inputStyles, justifyContent: "initial" }} key={i}>
                <label
                  style={{
                    fontWeight: "600",
                    marginBottom: "2px",
                  }}
                >
                  {capitalizeOnlyFirstLetter(element[0].replaceAll("_", " "))}
                </label>
                <select
                  style={{
                    marginBottom: "15px",
                    width: "177px",
                  }}
                  className={"simple-input"}
                  key={i}
                  onChange={e => handleChange(e, element[0])}
                  value={create ? "" : house[element[0]]}
                >
                  {create
                    ? ""
                    : array.map(object => {
                        if (element[0] === "documents" || element[0] === "features") {
                        } else {
                          return <option key={i * (Math.random() * 999999)}>{object.type || object}</option>;
                        }
                      })}
                </select>
              </div>
            );
          }
        })}
      </div>

      <div>
        link: <a href={property_url}>{property_url}</a>
      </div>

      {!create && !isEmpty(process()) && (
        <div style={{ textAlign: "center", padding: "10px", border: "3px solid green" }}>
          {process()?.status === "sold" && house.sold ? (
            <span style={{ fontWeight: "700" }}>Esta propriedade já foi vendida</span>
          ) : (
            <span style={{ fontWeight: "700" }}>Esta propriedade tem um processo de venda atualmente a decorrer:</span>
          )}
          <br />
          {isEmpty(cancelProcess) && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <button
                className={"simple-button"}
                style={{ marginTop: "15px", backgroundColor: "#339999" }}
                onClick={() => setSelectedTab("processes")}
              >
                {!house.sold ? "Gerir" : "Ver processo"}
              </button>
              <br />
              {!house.sold && (
                <button
                  className={"simple-button ml-1"}
                  style={{ marginTop: "15px" }}
                  onClick={() => setCancelProcess("cancel")}
                >
                  Cancelar processo
                </button>
              )}
            </div>
          )}
          {!isEmpty(cancelProcess) && (
            <div>
              <span style={{ marginTop: "10px", fontWeight: "700", color: "darkred" }}>
                Isto irá declarar o processo como inativo e não terá acesso ao mesmo
              </span>
              <br />
              <input
                style={{ marginTop: "10px" }}
                type={"checkbox"}
                checked={cancelProcess === "confirm"}
                onChange={
                  cancelProcess === "confirm" ? () => setCancelProcess("cancel") : () => setCancelProcess("confirm")
                }
              />
              <label>Declaro que li o aviso acima e pretendo continuar</label>
              <div style={{ marginTop: "25px" }}>
                <button
                  disabled={cancelProcess !== "confirm"}
                  className={"simple-button"}
                  style={{ marginRight: "15px", backgroundColor: "#fa7575", color: "white" }}
                  onClick={handleProcessRemoval}
                >
                  Confirmar
                </button>
                <button className={"simple-button"} onClick={() => setCancelProcess("")}>
                  Cancelar
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {!create && ["quick_price", "market_price", "high_price"].every(price => !isEmpty(house[price])) && (
        <div style={{ marginLeft: "70px" }}>
          <h3>Valores da avaliação</h3>
          <div style={styles.labelStyles}>
            <div style={{ display: "flex", width: "250px", justifyContent: "space-between" }}>
              <span style={styles.valuationStyles.outerSpan}>
                <div style={{ ...styles.valuationStyles.ball, backgroundColor: "#3dcc4a" }} />
                {`Venda rápida: `}
              </span>
              <span>{`${formatNumber(house.quick_price)}`}</span>
            </div>
            <br />
            <div style={{ display: "flex", width: "250px", justifyContent: "space-between" }}>
              <span style={styles.valuationStyles.outerSpan}>
                <div style={{ ...styles.valuationStyles.ball, backgroundColor: "#CCB43DFF" }} />
                {`Valor de mercado: `}
              </span>
              <span>{`${formatNumber(house.market_price)}`}</span>
            </div>
            <br />
            <div style={{ display: "flex", width: "250px", justifyContent: "space-between" }}>
              <span style={styles.valuationStyles.outerSpan}>
                <div style={{ ...styles.valuationStyles.ball, backgroundColor: "darkred" }} />
                {`Valor alto: `}
              </span>
              <span>{`${formatNumber(house.high_price)}`}</span>
            </div>
          </div>
        </div>
      )}
      {!create && (
        <div style={styles.pageStyles}>
          <h3 style={{ marginTop: "80px" }}>Stages da Propriedade</h3>
          <table style={styles.tableStyle}>
            <tr style={styles.tableStyle}>
              <th
                style={{
                  ...styles.tableStyle,
                  backgroundColor: "black",
                  color: "white",
                  minWidth: "100px",
                }}
              >
                Stages
              </th>
              {stageTableEntries?.map(stage => (
                <th style={styles.tableStyle}>{stage.name}</th>
              ))}
            </tr>
            <tr style={styles.tableStyle}>
              <th
                style={{
                  ...styles.tableStyle,
                  backgroundColor: "#383838",
                  color: "white",
                  minWidth: "100px",
                }}
              >
                Data
              </th>
              {stageTableEntries?.map(stage =>
                !stageHistoryData[stage.accessor] ? (
                  stage.accessor === "pending_documents" /*&& !house.stage_last_change*/ ? (
                    <th style={styles.tableStyle}>{dateFormat(house.creation_date)}</th>
                  ) : (
                    <th style={{ ...styles.tableStyle, backgroundColor: "lightgrey" }}></th>
                  )
                ) : (
                  <th style={styles.tableStyle}>{dateFormat(stageHistoryData[stage.accessor])}</th>
                ),
              )}
            </tr>
            <tr style={styles.tableStyle}>
              <th
                style={{
                  ...styles.tableStyle,
                  backgroundColor: "#383838",
                  color: "white",
                  minWidth: "100px",
                }}
              >
                Hora
              </th>
              {stageTableEntries?.map(stage =>
                !stageHistoryData[stage.accessor] ? (
                  stage.accessor === "pending_documents" /*&& !house.stage_last_change*/ ? (
                    <th style={styles.tableStyle}>
                      {new Date(house?.creation_date).toLocaleTimeString("pt-PT", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      h
                    </th>
                  ) : (
                    <th style={{ ...styles.tableStyle, backgroundColor: "lightgrey" }}></th>
                  )
                ) : (
                  <th style={styles.tableStyle}>
                    {new Date(stageHistoryData[stage.accessor]).toLocaleTimeString("pt-PT", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                    h
                  </th>
                ),
              )}
            </tr>
          </table>
          {openModal && <PropertyClosingLog property={house} handleClose={() => setOpenModal(false)} save={save} />}
          {!!house?.sold && (
            <button
              onClick={() => setOpenModal(true)}
              style={{ width: "200px", marginTop: "20px" }}
              className={"simple-button"}
            >
              Gerir Closing Log de Venda
            </button>
          )}
        </div>
      )}
      <h3 style={{ marginTop: "100px" }}>Ações</h3>
      {!create && ((changeVerificationStatus && house.verified === 1) || (changeSoldStatus && house.sold === null)) && (
        <span style={{ fontWeight: "700", margin: "15px", color: "darkred" }}>
          Retirar uma verificação ou declarar uma casa como vendida irá desativar o anúncio
        </span>
      )}

      {!create && (
        <div style={{ ...styles.additionalInfoStyles, justifyContent: "space-around" }}>
          <div style={styles.selectableStyles}>
            {house?.verified === 1 && (
              <div
                style={
                  changeVerificationStatus
                    ? {
                        ...styles.selectableStyles,
                        flexDirection: "column-reverse",
                      }
                    : styles.selectableStyles
                }
              >
                <button
                  className={"simple-button"}
                  style={
                    changeVerificationStatus
                      ? {
                          backgroundColor: "#fa7575",
                          color: "white",
                        }
                      : { backgroundColor: "rgb(245 238 68)", color: "black" }
                  }
                  onClick={
                    !changeVerificationStatus
                      ? () => setChangeVerificationStatus(true)
                      : () => handleChange("false", "verified")
                  }
                >
                  {!changeVerificationStatus ? "Retirar publicação" : "Desativar anúncio"}
                </button>
                {changeVerificationStatus && (
                  <button className={"simple-button"} onClick={() => setChangeVerificationStatus(false)}>
                    Cancelar
                  </button>
                )}
              </div>
            )}
            <div style={styles.selectableStyles}>
              <button
                className={"simple-button"}
                style={{
                  background: "#C8102E",
                  color: "white",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={handleDeleteProperty}
              >
                {`${house.verified ? "Arquivar" : "Apagar"} propriedade`}
              </button>
              {house?.verified !== 1 && (
                <div
                  style={
                    changeVerificationStatus
                      ? {
                          ...styles.selectableStyles,
                          flexDirection: "column-reverse",
                        }
                      : styles.selectableStyles
                  }
                >
                  <button
                    className={"simple-button"}
                    style={
                      changeVerificationStatus
                        ? {
                            backgroundColor: "#fa7575",
                            color: "white",
                          }
                        : { backgroundColor: "rgb(245 238 68)", color: "black" }
                    }
                    onClick={
                      !changeVerificationStatus ? () => setChangeVerificationStatus(true) : () => handleVerifyProperty()
                    }
                  >
                    {!changeVerificationStatus ? "Publicar casa" : "Ativar anúncio"}
                  </button>
                  {changeVerificationStatus && (
                    <button className={"simple-button"} onClick={() => setChangeVerificationStatus(false)}>
                      Cancelar
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={styles.selectableStyles}>
            {house?.sold !== null && (
              <div
                style={
                  changeSoldStatus
                    ? {
                        ...styles.selectableStyles,
                        flexDirection: "column-reverse",
                      }
                    : styles.selectableStyles
                }
              >
                <button
                  className={"simple-button"}
                  style={
                    changeSoldStatus
                      ? {
                          backgroundColor: "#fa7575",
                          color: "white",
                        }
                      : { backgroundColor: "rgb(245 238 68)", color: "black" }
                  }
                  onClick={!changeSoldStatus ? () => setChangeSoldStatus(true) : () => handleChange("false", "sold")}
                >
                  {!changeSoldStatus ? "Declarar casa como por vender" : "Ativar anúncio"}
                </button>
                {changeSoldStatus && (
                  <button
                    className={"simple-button"}
                    style={{ backgroundColor: "gainsboro" }}
                    onClick={() => setChangeSoldStatus(false)}
                  >
                    Cancelar
                  </button>
                )}
              </div>
            )}
            {house?.sold === null && (
              <div
                style={
                  changeSoldStatus
                    ? {
                        ...styles.selectableStyles,
                        flexDirection: "column-reverse",
                      }
                    : styles.selectableStyles
                }
              >
                <button
                  className={"simple-button"}
                  style={
                    changeSoldStatus
                      ? {
                          backgroundColor: "#fa7575",
                          color: "white",
                        }
                      : { backgroundColor: "rgb(245 238 68)", color: "black" }
                  }
                  onClick={!changeSoldStatus ? () => setChangeSoldStatus(true) : () => handleChange("true", "sold")}
                >
                  {!changeSoldStatus ? "Declarar casa como vendida" : "Desativar anúncio"}
                </button>
                {changeSoldStatus && (
                  <button className={"simple-button"} onClick={() => setChangeSoldStatus(false)}>
                    Cancelar
                  </button>
                )}
              </div>
            )}
          </div>
          {!house?.cmi_ready && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "20%" }}>
              <button
                className={"simple-button"}
                style={{ width: "fit-content" }}
                onClick={async () => {
                  await preparePropertyCMI(house.id);
                  resetFields();
                  setHouse({ ...house, cmi_ready: 1 });
                }}
              >
                Preparar CMI
              </button>
              <small style={{ color: "red", marginTop: "20px" }}>
                O CMI será enviado imediatamente ao cliente através do DocuSign, pronto para assinar
              </small>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PropertyTab;
