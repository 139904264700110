import "./styles.scss";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const PageTabs = ({ tabs, setSelectedTab, selectedTab, pathPrefix }) => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.tab && params.tab !== selectedTab) setSelectedTab(params.tab);
  }, [params.tab]);

  useEffect(() => {
    if (params.tab !== selectedTab) navigate(`${pathPrefix}/${params.id}/${selectedTab}`);
  }, [selectedTab]);

  return (
    <div className={"PageTabs"}>
      {Object.keys(tabs).map(tab => {
        return (
          <div
            className={"tab" + (selectedTab === tab ? " selected" : "")}
            key={"tab_" + tab}
            onClick={() => setSelectedTab(tab)}
          >
            {tabs[tab]}
          </div>
        );
      })}
    </div>
  );
};

export default PageTabs;
