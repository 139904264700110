import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "../../services";
import {
  getAllProcesses,
  getAllRequests,
  getCompleteProperty,
  getPropertyIdFromProposalId,
  getStageHistory,
  getVisits,
  updateProperty,
} from "../../services/propertyService";
import GenericListing from "../GenericListing";
import PropertyClosingLog from "../Modals/PropertyClosingLog";
import PageTabs from "../PageTabs";
import ProcessPage from "../ProcessPage";
import AdditionalInfoTab from "../PropertyPage/AdditionalInfoTab";
import MediaDocsTab from "../PropertyPage/MediaDocsTab";
import PropertyTab from "../PropertyPage/PropertyTab";
import UserTab from "../PropertyPage/UserTab";
import "./styles.scss";

const Property = () => {
  const params = useParams();
  const {
    isLoading,
    data: property,
    isError,
    error,
    isPreviousData,
    refetch,
  } = useQuery([`property`], () => getCompleteProperty(params.id), { keepPreviousData: false, refetchOnMount: true });

  const [selectedTab, setSelectedTab] = useState(params.tab || "property");
  const [isMedia, setIsMedia] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const listingProps = {
    processes: {
      name: "",
      url: "processes",
      table: "process",
      getDataFunction: () => getAllProcesses(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Vendedor", "seller_name", ""],
        ["Comprador", "buyer_name", ""],
        ["CPCV Vendedor", "cpcv_seller", "date"],
        ["CPCV Comprador", "cpcv_buyer", "date"],
        ["Data CPCV", "cpcv_date", "date"],
        ["Escritura Vendedor", "deed_seller", "date"],
        ["Escritura Comprador", "deed_buyer", "date"],
        ["Data Escritura", "deed_date", "date"],
        ["Escritura assinada", "deed_signed", "boolean"],
        ["Estado", "status", ""],
        [
          "Propriedade",
          "proposal_id",
          "route",
          async proposal_id => {
            let { property_id } = await getPropertyIdFromProposalId(proposal_id);
            return `/properties/${property_id}`;
          },
        ],
      ],
      filters: [["status", "selectable", ["on_going", "pending", "closed", "sold"]]],
    },
    visits: {
      name: "",
      url: "visits",
      table: "visit",
      getDataFunction: () => getVisits(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Casa", "property_reference", ""],
        ["Buyer", "buyer_name", "lead_name"],
        ["Pedido a", "created_at", "date"],
        ["Mensagem", "request_message", ""],
        ["Agendada para", "date", "hour"],
        ["Aceite", "accepted", "date"],
        ["Rejeitada", "rejected", "date"],
        ["Completa", "done", "boolean"],
        ["Rating", "rating", ""],
        ["Feedback", "feedback", ""],
        ["Ações", "", "visit"],
        ["Lead?", "lead_id", "boolean"],
      ],
      filters: [],
      property_id: params.id,
    },
    requests: {
      name: "",
      url: "requests",
      table: "service_request",
      getDataFunction: () => getAllRequests(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Tipo de serviço", "service_type", ""],
        ["Data", "requested_date", "date"],
        ["Nome", "name", ""],
        ["Telemóvel", "phone", ""],
        ["Email", "email_address", ""],
        ["Trusted", "trusted", "boolean"],
        ["Assunto", "subject", ""],
        ["Mensagem", "message", ""],
        ["Interessado em", "interested_in", ""],
        ["Assoc. Advisor", "", "request_admin"],
      ],
      filters: [],
      property_id: params.id,
      // filters: [["Pesquisar", "text"], ["service_type", "selectable", ["schedule_photo_session", "schedule_energy_evaluation", "contact_us", "house_visit", "price_change", "delete_property"]]]
    },
  };

  const [stageHistoryData, setStageHistoryData] = useState(property?.stage_history || {});
  const [activeFilters, setActiveFilters] = useState({ property_id: params.id });
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setPage(1);
    setActiveFilters({ property_id: params.id });
  }, [selectedTab]);

  useEffect(() => {
    async function fetchStageHistory() {
      try {
        const { data } = await getStageHistory(params.id);

        if (!data) return;

        setStageHistoryData(data);
      } catch (error) {
        console.error(error);
      }
    }

    if (params?.id) fetchStageHistory();
  }, [isLoading]);

  const tabs = {
    property: "Propriedade",
    user: "Utilizador",
    info: "Info Adicional",
    media: "Media & Docs",
    requests: "Pedidos",
    visits: "Visitas",
    processes: "Processos",
  };

  //TODO turn into css when there's extra time
  const pageStyles = {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    flexDirection: "column",
  };

  const [house, setHouse] = useState({ ...property });
  const [confirmReset, setConfirmReset] = useState(false);
  const [changeVerificationStatus, setChangeVerificationStatus] = useState(false);
  const [changeSoldStatus, setChangeSoldStatus] = useState(false);
  const [load, setLoad] = useState(false);

  const resetFields = () => {
    if (house?.id) {
      getStageHistory(params?.id).then(r => {
        setStageHistoryData(r.data);
      });
    }
    refetch().then(r => {
      setConfirmReset(false);
      setHouse(r.data);
    });
  };

  useEffect(() => {
    resetFields();
  }, []);

  useEffect(() => {
    if (load) {
      refetch().then(r => {
        setHouse(r.data);
        setLoad(false);
      });
      getStageHistory(params?.id).then(r => {
        setStageHistoryData(r.data);
      });
    }
  }, [load]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong. {error.message}</div>;
  }

  const handleChange = (e, field) => {
    if (field === "verified") {
      setHouse({ ...house, [field]: e === "true" ? 1 : 0 });
      if (e === "false") setOpenModal(true);
      setChangeVerificationStatus(false);
      window.alert("Deve clicar em 'Guardar' para guardar as alterações");
    } else if (field === "sold") {
      setHouse({ ...house, [field]: e === "true" ? new Date() : null, verified: 1 });
      setChangeSoldStatus(false);
      if (e === "true") setOpenModal(true);
    } else setHouse({ ...house, [field]: e.target.value });
  };

  const handleInput = event => {
    event.target.classList.remove("inputError");
  };

  const handleInvalid = event => {
    event.target.classList.add("inputError");
  };

  const handleOptionsChange = (e, check, field) => {
    setHouse(prevValues => {
      if (field === "extras") {
        if (!prevValues.features.extras) {
          return {
            ...prevValues,
            features: {
              ...prevValues.features,
              extras: [e],
            },
          };
        }
        return check
          ? {
              ...prevValues,
              features: { ...prevValues.features, extras: [...prevValues.features.extras, e] },
            }
          : {
              ...prevValues,
              features: {
                ...prevValues.features,
                extras: [...prevValues.features.extras.filter(value => value !== e)],
              },
            };
      } else {
        if (!prevValues[field]) {
          return { ...prevValues, [field]: [e] };
        }
        return check
          ? { ...prevValues, [field]: prevValues[field]?.concat(e) }
          : { ...prevValues, [field]: prevValues[field].filter(value => value !== e) };
      }
    });
  };

  const handleSave = async () => {
    try {
      if (String(params.id) === String(house.id)) {
        const result = await updateProperty(params.id, house);
        window.alert("Alterações guardadas com sucesso");
        resetFields();
      }
    } catch (e) {
      window.alert("Erro ao guardar a propriedade");
      console.error("Error gravar alterações", e);
    }
  };

  const process = () => {
    let process = house.processes.find(
      process => process.status === "on_going" || process.status === "pending" || process.status === "sold",
    );

    if (process) {
      delete process?.property_financial_info;
      delete process?.property_id;
      return process;
    }
  };

  return (
    <div style={pageStyles}>
      <h1 onClick={() => console.log(house)}>{property.title.pt}</h1>
      <h2 style={{ margin: "0" }}>Ref {property.reference}</h2>
      {openModal && (
        <PropertyClosingLog
          property={house}
          handleClose={() => setOpenModal(false)}
          resetFields={resetFields}
          save={handleSave}
        />
      )}
      <PageTabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabs={tabs} pathPrefix={"/properties"} />
      {/*Propriedade*/}
      {selectedTab === "property" && (
        <PropertyTab
          house={house}
          setHouse={setHouse}
          setSelectedTab={setSelectedTab}
          setChangeSoldStatus={setChangeSoldStatus}
          changeVerificationStatus={changeVerificationStatus}
          changeSoldStatus={changeSoldStatus}
          stageHistoryData={stageHistoryData}
          setChangeVerificationStatus={setChangeVerificationStatus}
          handleChange={handleChange}
          handleInput={handleInput}
          save={handleSave}
          resetFields={resetFields}
          process={process}
        />
      )}
      {/*Propriedade*/}

      {/*Info Adicional*/}
      {selectedTab === "info" && (
        <AdditionalInfoTab
          house={house}
          setHouse={setHouse}
          handleChange={handleChange}
          handleInput={handleInput}
          handleOptionsChange={handleOptionsChange}
          handleInvalid={handleInvalid}
        />
      )}
      {/*Info Adicional*/}

      {/*Utilizador*/}
      {selectedTab === "user" && <UserTab property={house} />}
      {/*Utilizador*/}

      {/*Media & Docs*/}
      {selectedTab === "media" && (
        <MediaDocsTab house={house} setHouse={setHouse} isMedia={isMedia} setIsMedia={setIsMedia} refetch={refetch} />
      )}
      {/*Media & Docs*/}

      {/*Processos/Visitas/Pedidos*/}
      {!isEmpty(process()) && !load && selectedTab === "processes" && (
        <div id={"process"} style={{ textAlign: "center", padding: "10px", border: "3px solid green" }}>
          <ProcessPage process={process()} setLoad={setLoad} />
        </div>
      )}
      {["visits", "requests"].includes(selectedTab) && !isLoading && (
        <div>
          <GenericListing {...listingProps[String(selectedTab).toLowerCase()]} />
        </div>
      )}
      {/*Processos/Visitas/Pedidos*/}

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "600px",
          margin: "30px",
        }}
      ></div>

      {!["processes", "visits", "requests"].includes(selectedTab) && (
        <div style={{ display: "flex", justifyContent: "space-between", width: "400px", margin: "25px" }}>
          <button
            className={"simple-button"}
            onClick={handleSave}
            style={{ backgroundColor: "#339999", color: "white" }}
          >
            Salvar alterações
          </button>
          <button
            className={"simple-button"}
            style={
              confirmReset
                ? {
                    backgroundColor: "#fa7575",
                    color: "white",
                  }
                : { backgroundColor: "rgb(245 238 68)", color: "black" }
            }
            onClick={!confirmReset ? () => setConfirmReset(true) : resetFields}
          >
            {!confirmReset ? "Fazer reset dos campos" : "Confirmar reset?"}
          </button>
          {confirmReset && (
            <button className={"simple-button"} onClick={() => setConfirmReset(false)}>
              Cancelar reset
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Property;
