import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select/base";
import { toast } from "react-toastify";
import { dateFormat } from "../../services";
import {
  getAllRequests,
  getEvaluations,
  getUser,
  getVisits,
  updateEvaluation,
  updateUser,
} from "../../services/propertyService";
import GenericListing from "../GenericListing";
import ChangeUserEmail from "../Modals/ChangeUserEmail";
import DeleteAccount from "../Modals/DeleteAccount";
import ResetAdvisorPassword from "../Modals/ResetAdvisorPassword";
import PageTabs from "../PageTabs";
import Properties from "../Properties";
import FinancialProfiles from "./FinancialProfiles";

const User = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    isLoading,
    data: user_data,
    isError,
    error,
    isPreviousData,
    refetch,
  } = useQuery([`user`], () => getUser(params.id), { keepPreviousData: false, refetchOnMount: true });

  if (!!user_data?.archived) {
    navigate("/users");
  }

  const [user, setUser] = useState({});
  const [selectedTab, setSelectedTab] = useState(params.tab || "info");
  const [confirmReset, setConfirmReset] = useState(false);
  const [activeFilters, setActiveFilters] = useState({ property_id: params.id });
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(!!user_data?.inactive ? "delete" : "");

  const fields = [
    {
      label: "Data de registo",
      accessor: "registration_date",
      type: "date",
      editable: false,
    },
    {
      label: "Nome",
      accessor: "name",
      type: "string",
      editable: true,
    },
    {
      label: "Idioma",
      accessor: "nationality",
      type: "string",
      editable: false,
    },
    {
      label: "Email",
      accessor: "email",
      type: "email",
      editable: false,
      email_verified: user_data?.email_verified,
    },
    {
      label: "Nº de telefone",
      accessor: "phone",
      type: "string",
      editable: true,
    },
    {
      label: "Morada",
      accessor: "address",
      type: "string",
      editable: true,
    },
    {
      label: "Último login",
      accessor: "last_login",
      type: "date",
      editable: false,
    },
    {
      label: "Nº de visitas realizadas",
      accessor: "",
      type: "number",
      editable: false,
    },
  ];

  const tabs = {
    info: "Utilizador",
    properties: "Propriedades",
    visits: "Visitas",
    requests: "Pedidos",
    evaluations: "Avaliações",
    financial_profile: "Perfil",
  };

  const styles = {
    inputStyles: {
      margin: "10px",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    containerStyles: {
      display: "flex",
      flexWrap: "wrap",
      width: "875px",
    },
  };

  const s3Url = process.env.REACT_APP_S3_AWS_URL;
  //console.log("s3Url", s3Url)

  const financialProfileTrans = {
    created_at: "Preenchido em",
    name: "Nome",
    address: "Morada",
    type: "Tipo",
    fiscal_number: "NIF/NIPC",
    commercial_certificate_file: "Certificado Comercial",
    commercial_certificate_number: "Número de Certificado Comercial",
    id_doc_front_file: "Cartão de Cidadão (frente)",
    id_doc_back_file: "Cartão de Cidadão (verso)",
    id_doc_exp_date: "Validade Doc.",
    id_doc_type: "Tipo de Doc.",
    id_number: "Número do Doc.",
    manager_fiscal_number: "NIF/NIPC do Gestor",
    manager_id_doc_front_file: "Gestor Cartão de Cidadão (frente)",
    manager_id_doc_back_file: "Gestor Cartão de Cidadão (verso)",
    manager_id_doc_exp_date: "Gestor Validade Doc.",
    manager_id_number: "Gestor Numero do Doc.",
    marital_status: "Estado Civil",
    marital_property_regime: "Regime de Bens",
    occupation: "Ocupação",
    spouse_name: "Nome do Cônjuge",
    spouse_fiscal_number: "NIF do Cônjuge",
    spouse_id_doc_front_file: "Cônjuge Cartão de Cidadão (frente)",
    spouse_id_doc_back_file: "Cônjuge Cartão de Cidadão (verso)",
    spouse_id_doc_exp_date: "Cônjuge Validade Doc.",
    spouse_id_doc_type: "Cônjuge Tipo de Doc.",
    spouse_id_number: "Cônjuge Numero do Doc.",
    spouse_occupation: "Cônjuge Ocupação",
  };

  useEffect(() => {
    setPage(1);
    setActiveFilters({ user_id: Number(params.id) });
  }, [selectedTab]);

  useEffect(() => {
    if (openModal === "") {
      refetch().then(r => setUser({ ...r.data }));
    }
  }, [openModal]);

  const listingProps = {
    visits: {
      name: "",
      url: "visits",
      table: "visit",
      getDataFunction: () => getVisits(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Casa", "property_reference", ""],
        ["Buyer", "buyer_name", "lead_name"],
        ["Pedido a", "created_at", "date"],
        ["Mensagem", "request_message", ""],
        ["Agendada para", "date", "hour"],
        ["Aceite", "accepted", "date"],
        ["Rejeitada", "rejected", "date"],
        ["Completa", "done", "boolean"],
        ["Rating", "rating", ""],
        ["Feedback", "feedback", ""],
        ["Ações", "", "visit"],
        ["Lead?", "lead_id", "boolean"],
      ],
      filters: [],
      user_id: Number(params.id),
    },
    requests: {
      name: "",
      url: "requests",
      table: "service_request",
      getDataFunction: () => getAllRequests(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Tipo de serviço", "service_type", ""],
        ["Data", "requested_date", "date"],
        ["Nome", "name", ""],
        ["Telemóvel", "phone", ""],
        ["Email", "email_address", ""],
        ["Trusted", "trusted", "boolean"],
        ["Assunto", "subject", ""],
        ["Mensagem", "message", ""],
        ["Interessado em", "interested_in", ""],
        ["Assoc. Advisor", "", "request_admin"],
      ],
      filters: [],
      // filters: [["Pesquisar", "text"], ["service_type", "selectable", ["schedule_photo_session", "schedule_energy_evaluation", "contact_us", "house_visit", "price_change", "delete_property"]]]
    },
    evaluations: {
      name: "",
      url: "evaluations",
      table: "evaluation",
      getDataFunction: () => getEvaluations(page, "", { ...activeFilters, user_id: Number(params.id) }),
      fields: [
        ["ID", "id", ""],
        ["Data", "request_date", "date", true],
        ["Tipo", "property_type", ""],
        ["Quando", "time_to_sell", ""],
        ["Concelho", "county", ""],
        ["Nome", "client_name", ""],
        ["Email", "client_email", ""],
        ["Telemóvel", "client_phone", ""],
        ["Casafari URL", "casafari_link", "link"],
        [
          "Origem",
          "origin",
          "selectable",
          (origin, row) => {
            const options = [
              { value: "placeholder", label: "Escolha a origem" },
              {
                label: "Marketing",
                value: "marketing",
              },
              { label: "Contactos pessoais", value: "personal_contacts" },
              {
                label: "Prospeção",
                value: "prospection",
              },
              { label: "Referências", value: "references" },
              { label: "Placas/Outdoor", value: "outdoor" },
            ];
            return (
              <Select
                className={"SelectableCell"}
                classNamePrefix={"SelectableCell"}
                options={options}
                defaultValue={options.find(
                  origin
                    ? entry => entry.value === (row.origin || "placeholder")
                    : entry => entry.value === "placeholder",
                )}
                onChange={s => updateEvaluation(row.id, { origin: s.value })}
              />
            );
          },
        ],
        ["Notas", "notes", "textArea", (row, notes) => updateEvaluation(row.id, { notes: notes })],
        [
          "Follow Up",
          "follow_up_date",
          "datepicker",
          (row, date) => updateEvaluation(row.id, { follow_up_date: date }),
        ],
        ["Advisors", "advisors", "request_admin"],
      ],
      filters: [],
      user_id: Number(params.id),
    },
  };

  const handleChange = (e, field) => {
    setUser({ ...user, [field]: e.target.value });
  };

  const handleSave = async () => {
    try {
      delete user.financial_profile;
      await updateUser(user.id, { ...user });
      toast.success("Alterações guardadas com sucesso!");
    } catch (e) {
      toast.error("Erro ao guardar o utilizador");
      console.log("Error a gravar alterações", e);
    }
  };

  const resetFields = async () => {
    await setUser(user_data);
  };

  useEffect(() => {
    if (user_data && Number(params.id) === user_data.id) {
      setUser(user_data);
    }
  }, [isLoading]);

  useEffect(() => {
    refetch().then(r => {
      if (Number(params.id) === r.data?.id) setUser({ ...r.data });
    });
  }, []);

  return (
    <div style={styles.pageStyles}>
      {!isLoading && <h1 onClick={() => console.log(user)}>{user_data.name}</h1>}
      <PageTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} pathPrefix={"/users"} />
      {/*Utilizador*/}
      {selectedTab === "info" && (
        <div style={{ ...styles.containerStyles }}>
          {fields.map((element, i) => {
            return (
              <div key={i} style={{ ...styles.inputStyles, marginBottom: "25px" }}>
                <label
                  style={{
                    fontWeight: "600",
                    marginBottom: "2px",
                    textTransform: "capitalize",
                  }}
                >
                  {element.type === "email" ? `${element.label} ${element.email_verified ? "✓" : "⤫"}` : element.label}
                </label>
                <br />
                <input
                  className={"simple-input"}
                  readOnly={!element.editable}
                  value={
                    element.type === "date"
                      ? dateFormat(user[element.accessor])
                      : /*element.accessor === "address" && !!user?.financial_profile ? user?.financial_profile?.address :*/ user[
                          element.accessor
                        ]
                  }
                  type={"text"}
                  onChange={e => handleChange(e, element.accessor)}
                />
              </div>
            );
          })}
        </div>
      )}
      {selectedTab === "info" && (
        <div
          style={{
            ...styles.containerStyles,
            maxWidth: "100%",
            width: "400px",
            marginTop: "30px",
          }}
          className={"mb-1"}
        >
          <button onClick={() => setOpenModal("delete")} className={"simple-button red mr-1"}>
            Apagar conta
          </button>
          <button onClick={() => setOpenModal("email")} className={"simple-button mr-1"}>
            Alterar email
          </button>
          <button onClick={() => setOpenModal("password")} className={"simple-button"}>
            Alterar password
          </button>
        </div>
      )}
      {openModal === "password" && (
        <ResetAdvisorPassword advisor={user} handleClose={() => setOpenModal("")} isUser={true} />
      )}
      {openModal === "email" && <ChangeUserEmail user={user} handleClose={() => setOpenModal("")} />}
      {(openModal === "delete" || !!user_data?.inactive) && (
        <DeleteAccount
          user={user}
          handleClose={!!user_data?.inactive ? () => navigate("/users") : () => setOpenModal("")}
        />
      )}
      {selectedTab === "info" && (
        <div style={{ display: "flex", width: "400px" }}>
          <button className={"simple-button green mr-1"} onClick={handleSave}>
            Salvar alterações
          </button>
          <button
            className={"simple-button" + (confirmReset ? " red" : " yellow")}
            onClick={!confirmReset ? () => setConfirmReset(true) : resetFields}
          >
            {!confirmReset ? "Fazer reset dos campos" : "Confirmar reset?"}
          </button>
          {confirmReset && (
            <button className={"simple-button"} onClick={() => setConfirmReset(false)}>
              Cancelar reset
            </button>
          )}
        </div>
      )}
      {/*Utilizador*/}

      {selectedTab === "properties" && !isLoading && <Properties user_id={user_data.id} />}

      {["visits", "requests", "evaluations"].includes(selectedTab) && !isLoading && (
        <div>
          <GenericListing {...listingProps[String(selectedTab).toLowerCase()]} />
        </div>
      )}

      {selectedTab === "financial_profile" && <FinancialProfiles userId={user_data.id} />}
    </div>
  );
};

export default User;
