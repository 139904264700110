import { useState } from "react";
import { createAdvisor } from "../../../services/user";
import Modal from "../../Modal";

const CreateAdvisor = ({ handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateAdvisor = async () => {
    if (!name || !email) {
      alert("Verifique os campos.");
      return;
    }
    setLoading(true);
    try {
      const { data } = await createAdvisor(name, email);
      alert("Advisor criado com sucesso. A password é: " + data.password);
      handleClose();
    } catch (e) {
      alert("Erro ao criar advisor. " + (e.data?.message || e));
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <Modal closeModal={handleClose}>
      <h1>Novo Advisor</h1>
      <label>Nome</label>
      <input className={"simple-input"} type="text" value={name} onChange={e => setName(e.target.value)} />
      <label>Email</label>
      <input className={"simple-input"} type="text" value={email} onChange={e => setEmail(e.target.value)} />
      <button className={"simple-button green mt-1"} disabled={loading} onClick={handleCreateAdvisor}>
        Criar
      </button>
    </Modal>
  );
};

export default CreateAdvisor;
