import React from "react";

const Pagination = ({ totalCount, currentPage, onPageChange, pageSize }) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  const pageButtonStyle = {
    marginRight: "5px",
    borderRadius: "25px",
    width: "27px",
    height: "27px",
    padding: "5px",
    backgroundColor: "#bfbfbf",
  };

  const ellipsisStyles = {
    color: "black",
    cursor: "default",
    opacity: "0.6",
    backgroundColor: "rgb(191 191 191 / 17%)",
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button key="page-1" style={pageButtonStyle} className="simple-button" onClick={() => onPageChange(1)}>
          1
        </button>,
      );

      if (startPage > 2) {
        pageNumbers.push(
          <button
            key="ellipsis-start"
            style={{ ...pageButtonStyle, ...ellipsisStyles }}
            className="simple-button"
            disabled
          >
            ...
          </button>,
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          style={i === currentPage ? { ...pageButtonStyle, backgroundColor: "#339999" } : pageButtonStyle}
          className={`simple-button ${i === currentPage ? "green" : ""}`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>,
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <button
            key="ellipsis-end"
            style={{ ...pageButtonStyle, ...ellipsisStyles }}
            className="simple-button"
            disabled
          >
            ...
          </button>,
        );
      }

      pageNumbers.push(
        <button
          key={`page-${totalPages}`}
          style={pageButtonStyle}
          className="simple-button"
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div style={{ margin: "20px 0", display: "flex", justifyContent: "center" }} className="pagination-container">
      {renderPageNumbers()}
    </div>
  );
};

export default Pagination;
