import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import dayjs from "dayjs";
import {
  Button,
  Form,
  Input,
  Radio,
  Space,
  Select,
  Collapse,
  Divider,
  Flex,
  Typography,
  Descriptions,
  DatePicker,
  Upload,
  Row,
  Spin,
  Col,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ConfirmationModal from "../ConfirmationModal";
import { Link } from "react-router-dom";

const s3Url = process.env.REACT_APP_S3_AWS_URL;

const fields = [
  "id",
  "user_id",

  "created_at",
  "buyer",
  "type",
  "properties",

  "name",
  "address",
  "fiscal_number",
  "occupation",
  "marital_status",
  "marital_property_regime",

  "id_doc_type",
  "id_number",
  "id_doc_exp_date",
  "id_doc_front_file",
  "id_doc_back_file",

  "spouse_name",
  "spouse_fiscal_number",
  "spouse_occupation",
  "spouse_id_doc_type",
  "spouse_id_number",
  "spouse_id_doc_exp_date",
  "spouse_id_doc_front_file",
  "spouse_id_doc_back_file",

  "commercial_certificate_number",
  "commercial_certificate_file",

  "manager_fiscal_number",
  "manager_id_number",
  "manager_id_doc_exp_date",
  "manager_id_doc_front_file",
  "manager_id_doc_back_file",
];

const FinancialProfile = ({ id, profile, isNewProfile, onSave, handleProfileChanges, properties, onRemove }, ref) => {
  const [editable, setEditable] = useState(isNewProfile);
  const [creatable, setCreatable] = useState(isNewProfile);
  const [editedProfile, setEditedProfile] = useState({ ...profile });
  const [loading, setLoading] = useState(false);
  const [clearAdditionalFields, setClearAdditionalFields] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  // State handlers
  const handleEdit = () => {
    setEditable(true);
  };

  const handleCancel = () => {
    if (changesMade) {
      setIsCancelModalVisible(true);
    } else {
      setEditable(false);
    }
  };

  const handleConfirmCancel = () => {
    if (isNewProfile) onRemove(id);
    handleProfileChanges(false);
    setIsCancelModalVisible(false);
    setEditedProfile({ ...profile });
    setEditable(false);
  };

  useImperativeHandle(ref, () => ({
    handleConfirmCancel,
  }));

  const handleCancelModal = () => {
    setIsSaveModalVisible(false);
    setIsCancelModalVisible(false);
    setChangesMade(false);
    handleProfileChanges(false);
  };

  const saveData = async () => {
    setLoading(true);
    let formData = new FormData();
    for (let [key, value] of Object.entries(editedProfile).filter(e => e[1] !== null)) {
      if (isAdditionalField(key)) {
        formData.append(key, clearAdditionalFields ? null : value);
      } else if (key === "buyer") {
        formData.append(key, Number(value));
      } else {
        formData.append(key, value);
      }
    }

    await onSave(formData, profile.id, isNewProfile);
    setLoading(false);
  };

  const handleSave = () => {
    if (!changesMade) {
      setEditable(false);
    } else {
      setIsSaveModalVisible(true);
    }
  };

  const handleConfirmSave = async () => {
    setIsSaveModalVisible(false);
    await saveData();
  };

  const handleChange = (key, value) => {
    setChangesMade(true);
    handleProfileChanges(true);
    setEditedProfile(prevProfile => ({
      ...prevProfile,
      [key]: value,
    }));
  };

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    handleChange(key, value);
  };

  const handleFileRemove = (e, key) => {
    handleChange(key, null);
    return true;
  };

  const handleFileChange = (e, key) => {
    const status = e.file.status;
    if (status === "removed") return handleFileRemove(e, key);
    else if (status != "uploading") {
      handleChange(key, e.file.originFileObj);
    }
  };

  const handleDateChange = (date, key) => {
    handleChange(key, new Date(date.toString()).toISOString());
  };

  // Info presentation
  const getLabel = key => {
    if (key === "created_at") return "Data de criação";
    if (key === "buyer") return "Comprador / Vendedor";
    if (key === "type") return "Tipo";
    if (key === "properties") return "Propriedades";
    if (key.includes("name")) return "Nome";
    if (key.includes("occupation")) return "Ocupação";
    if (key.includes("fiscal_number"))
      return getValue("type") === "individual" && !key.includes("manager") ? "NIF" : "NIPC";
    if (key === "address") return getValue("type") === "individual" ? "Morada" : "Sede";
    if (key === "marital_status") return "Estado civil";
    if (key === "marital_property_regime") return "Regime de bens";
    if (key.includes("id_doc_type")) return "Documento";
    if (key.includes("_number")) return "Número de identificação";
    if (key.includes("exp_date")) return "Data de validade";
    if (key === "commercial_certificate_number") return "Número de identificação";
    if (key.includes("doc_front_file")) return "Frente";
    if (key.includes("doc_back_file")) return "Verso";
    if (key === "commercial_certificate_file") return "Certificado Comercial";
  };

  const getValue = key => {
    return editedProfile[key] !== undefined ? editedProfile[key] : profile[key];
  };

  const getValueTranslation = (value, key) => {
    if (key === "created_at" || key.includes("date")) return !value ? "" : new Date(value).toLocaleDateString("pt-PT");
    if (key === "type") return value === "company" ? "Comercial" : "Individual";
    if (key === "buyer") return value === 0 ? "Vendedor" : "Comprador";
    if (value === "married") return "Casado";
    if (value === "single") return "Solteiro";
    if (value === "divorced") return "Divorciado";
    if (value === "citizen") return "Cartão de Cidadão";
    if (value === "residence") return "Certificado de Residência";
    if (value === "passport") return "Passaporte";
    if (value === "separation") return "Separação";
    if (value === "acquired") return "Adquiridos";
    if (value === "general") return "Geral";
    if (value === "null") return null;
    return value;
  };

  const getChildren = key => {
    if (key.includes("file")) {
      const fileValue = getValue(key) === "null" ? null : getValue(key);
      return (
        <Upload
          key={key}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          }}
          defaultFileList={fileValue ? [{ name: fileValue, status: "done", url: `${s3Url}/${fileValue}` }] : []}
          disabled={!editable}
          onChange={e => handleFileChange(e, key)}
          maxCount={1}
        >
          {editable && !fileValue && <Button icon={<UploadOutlined />}>Carregar</Button>}
        </Upload>
      );
    }

    if (editable && key.includes("date")) {
      return (
        <DatePicker
          defaultValue={getValue(key) ? dayjs(new Date(getValue(key))) : ""}
          onChange={e => handleDateChange(e, key)}
          format="DD/MM/YYYY"
        />
      );
    }

    if (key === "properties") {
      let options =
        properties?.map(p => ({
          key: p.id,
          label: p.reference,
          value: p.id,
          financial_profile_id: p.financial_profile_id,
        })) || [];
      let defaultOptions = creatable ? [] : options?.filter(p => p.financial_profile_id === getValue("id")) || [];
      return getValue("buyer") === 0 && editable ? (
        <Select
          mode="multiple"
          defaultValue={defaultOptions}
          options={options}
          onChange={value => handleChange(key, value)}
          style={{
            minWidth: 100,
            width: "auto",
          }}
        />
      ) : (
        <div>
          {defaultOptions.map(o => (
            <div>
              <Link to={`/properties/${o.value}`}>{o.label}</Link>
            </div>
          ))}
        </div>
      );
    }

    if (editable && ["marital_status", "marital_property_regime", "id_doc_type", "spouse_id_doc_type"].includes(key)) {
      let options;
      if (key === "marital_status") options = ["married", "single", "divorced"];
      if (key === "marital_property_regime") options = ["general", "acquired", "separation"];
      if (key === "id_doc_type" || key === "spouse_id_doc_type") options = ["passport", "residence", "citizen"];

      return (
        <Select
          defaultValue={getValue(key) === "null" ? "" : getValue(key)}
          options={options.map(o => {
            return {
              label: getValueTranslation(o, key),
              value: o,
            };
          })}
          onChange={value => handleChange(key, value)}
          style={{
            minWidth: 100,
            width: "auto",
          }}
        />
      );
    }

    if (creatable && ["type", "buyer"].includes(key)) {
      let options;
      if (key === "type") options = ["individual", "company"];
      if (key === "buyer") options = [0, 1];

      return (
        <Select
          defaultValue={getValue(key)}
          options={options.map(o => {
            return {
              label: getValueTranslation(o, key),
              value: o,
            };
          })}
          onChange={value => handleChange(key, value)}
          style={{
            minWidth: 100,
            width: "auto",
          }}
        />
      );
    } else if (!editable || isInfoField(key)) {
      return <Typography.Text>{getValueTranslation(getValue(key), key)}</Typography.Text>;
    } else {
      return (
        <Input defaultValue={getValue(key) === "null" ? "" : getValue(key)} onChange={e => handleInputChange(e, key)} />
      );
    }
  };

  // Field section booleans
  const isAdditionalField = key => {
    return key.includes("spouse") || key.includes("manager");
  };

  const isInfoField = key => {
    return ["created_at", "buyer", "type"].includes(key);
  };

  const isPropertyField = key => {
    return ["properties"].includes(key);
  };

  const isBearerField = key => {
    return !isInfoField(key) && !isAdditionalField(key) && !isPropertyField(key);
  };

  const isIndividualField = key => {
    return (
      key.includes("occupation") ||
      key.includes("marital") ||
      key.includes("spouse") ||
      ["id_doc_type", "id_number", "id_doc_exp_date", "id_doc_front_file", "id_doc_back_file"].includes(key)
    );
  };

  const isCommercialField = key => {
    return key.includes("commercial") || key.includes("manager");
  };

  // Conditional renders
  const [showAdditionalFields, setShowAdditionalFields] = useState(
    profile.type === "company" ||
      (profile.marital_status === "married" && profile.marital_property_regime !== "separation"),
  );

  const [showBearerFields, setShowBearerFields] = useState(!isNewProfile);

  useEffect(() => {
    if (getValue("type") === "individual") {
      if (
        getValue("marital_status") === "married" &&
        getValue("marital_property_regime") &&
        getValue("marital_property_regime") !== "separation"
      ) {
        setShowAdditionalFields(true);
        setClearAdditionalFields(false);
      } else {
        setShowAdditionalFields(false);
        setClearAdditionalFields(true);
      }
    }
  }, [getValue("marital_status"), getValue("marital_property_regime")]);

  useEffect(() => {
    if (creatable) {
      if (!getValue("type") || typeof getValue("buyer") != "number") {
        setShowBearerFields(false);
        setShowAdditionalFields(false);
        setClearAdditionalFields(true);
      } else {
        if (getValue("type") === "company") {
          setShowAdditionalFields(true);
        } else {
          setShowAdditionalFields(false);
          setClearAdditionalFields(true);
        }
        setShowBearerFields(true);
      }
    }
  }, [getValue("type"), getValue("buyer")]);

  useEffect(() => {
    console.log(clearAdditionalFields);
  }, [clearAdditionalFields]);

  // Generate data
  const customSort = (a, b) => {
    for (let key of fields) {
      if (a[key] !== b[key]) {
        return a[key] < b[key] ? -1 : 1;
      }
    }
    return 0;
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = fields
      .map(key => {
        return {
          key,
          label: getLabel(key),
          children: getChildren(key),
        };
      })
      .filter(field => {
        const condition1 = creatable && field.key === "created_at";
        const condition2 = ["id", "user_id"].includes(field.key);
        const condition3 = getValue("type") === "individual" && isCommercialField(field.key);
        const condition4 = getValue("type") === "company" && isIndividualField(field.key);
        const condition5 = getValue("marital_status") !== "married" && field.key === "marital_property_regime";

        return !(condition1 || condition2 || condition3 || condition4 || condition5);
      })
      .sort(customSort);

    setItems(items); // Assuming you have a state variable to hold items
  }, [editable, editedProfile, showAdditionalFields, getValue("marital_status")]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Descriptions
            items={items.filter(f => isInfoField(f.key))}
            // items={fields}
            column={1}
            labelStyle={{
              alignItems: "center",
            }}
          />
        </Col>
        <Col span={12}>
          <Descriptions
            // title="Propriedades"
            items={items.filter(f => isPropertyField(f.key))}
            // items={fields}
            column={1}
            labelStyle={{
              alignItems: "center",
            }}
          />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col span={12}>
          {showBearerFields && (
            <Descriptions
              title="Titular"
              items={items.filter(f => isBearerField(f.key))}
              column={1}
              labelStyle={{
                alignItems: "center",
              }}
            />
          )}
        </Col>
        <Col span={12}>
          {showAdditionalFields && (
            <Descriptions
              title={getValue("type") === "individual" ? "Cônjuge" : "Gestor"}
              items={items.filter(f => isAdditionalField(f.key))}
              column={1}
              labelStyle={{
                alignItems: "center",
              }}
            />
          )}
        </Col>
      </Row>

      <Flex gap="small" justify="end">
        {loading ? (
          <>
            <Spin size="small" />
            <Button type="primary" onClick={handleSave} disabled={true}>
              Guardar
            </Button>
          </>
        ) : (
          <>
            {editable ? (
              <>
                <Button type="primary" onClick={handleSave}>
                  Guardar
                </Button>
                <Button onClick={handleCancel}>Cancelar</Button>
              </>
            ) : (
              <Button type="primary" onClick={handleEdit}>
                Editar
              </Button>
            )}
          </>
        )}
      </Flex>

      <ConfirmationModal
        message="Confirmar alterações?"
        isOpen={isSaveModalVisible}
        onConfirm={handleConfirmSave}
        onCancel={handleCancelModal}
      />
      <ConfirmationModal
        message="Cancelar alterações?"
        isOpen={isCancelModalVisible}
        onConfirm={handleConfirmCancel}
        onCancel={handleCancelModal}
      />
    </>
  );
};

export default forwardRef(FinancialProfile);
