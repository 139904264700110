import heic2any from "heic2any";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { capitalizeOnlyFirstLetter, dateFormat, getPropertyNecessaryDocs, isEmpty } from "../../../services";
import {
  deletePhoto,
  getPropertyPhotosZip,
  getS3SignedUrl,
  removeDocument,
  removeVideo,
  upload3DVideo,
  uploadDocument,
  uploadPhoto,
  uploadVideo,
  validateDocument,
} from "../../../services/propertyService";
import MissingDocNotifier from "../../Modals/MissingDocNotifier";

const MediaDocsTab = ({ house, setHouse, isMedia, refetch, setIsMedia }) => {
  const s3Url = process.env.REACT_APP_S3_AWS_URL;

  const [missingDocuments, setMissingDocuments] = useState([]);
  const [matterportLink, setMatterportLink] = useState("");
  const [isUploadingVideo, setIsUploadingVideo] = useState("");
  const [removeDocConfirmation, setRemoveDocConfirmation] = useState("");
  const [notifyModal, setNotifyModal] = useState(false);
  const [loadingZip, setLoadingZip] = useState(false);

  const styles = {
    labelStyles: {
      //textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    allFilesStyles: {
      width: "1000px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    mediaStyles: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    mediaTypeStyles: {
      display: "flex",
      //justifyContent: "center",
      alignItems: "baseline",
    },
  };

  const handleDocValidation = (doc, action) => {
    if (action === "validate" || action === "removeValidation") {
      validateDocument(house.id, doc, action === "validate").then(r => {
        setHouse({
          ...house,
          documents: {
            ...house?.documents,
            [doc]: { ...house?.documents[doc], validated: action === "validate" },
          },
        });
      });
    } else if (action === "remove") {
      removeDocument(house.id, doc).then(r => {
        const temp = { ...house };
        delete temp.documents[doc];
        setHouse(temp);
        setRemoveDocConfirmation("");
      });
    }
  };

  const handleVideoRemoval = type => {
    if (
      (!isEmpty(house.documents.video) && type === "video") ||
      (!isEmpty(house.documents.matterport) && type === "matterport")
    ) {
      removeVideo(house.id, type).then(r => {
        const temp = { ...house };
        delete temp.documents[type];
        setHouse(temp);
      });
    }
  };

  const fillMissingDocs = () => {
    let missingDocs = [];
    for (const type of getPropertyNecessaryDocs(house)) {
      if (house?.documents && !Object.keys(house?.documents).includes(type)) missingDocs.push(type);
    }
    return missingDocs;
  };
  const docName = "";

  const missingDocsBuilder = () => {
    const missingDocs = fillMissingDocs().sort();
    const missing = [];

    for (let i = 0; i < missingDocs.length; i++) {
      missing.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "150px",
            height: "90px",
            margin: "10px",
            textAlign: "center",
            border: "2px solid darkred",
            borderRadius: "10px",
            cursor: "pointer",
            maxWidth: "150px",
            minWidth: "150px",
          }}
          className={"Doc"}
          key={i}
        >
          <label
            style={{
              display: "block",
              padding: "10px",
              fontWeight: "600",
            }}
            htmlFor={missingDocs[i]}
          >
            {/*capitalizeOnlyFirstLetter(missingDocs[i]).replaceAll("_", " ")*/ documentTranslations[missingDocs[i]]}
            <input
              style={{
                display: "none",
              }}
              type={"file"}
              name={missingDocs[i]}
              id={missingDocs[i]}
              onChange={e => handleDocUpload(e, missingDocs[i])}
            />
          </label>
        </div>,
      );
    }

    return missing;
  };

  const handleDocUpload = async (e, doc) => {
    let file = e.target.files[0];
    let propertyId = house?.id;
    if (!file || !propertyId) return;
    let formData = new FormData();
    formData.append("document", file);
    formData.append("document_type", doc);
    await uploadDocument(propertyId, formData);
    const temp = house;
    refetch().then(r => {
      setHouse({ ...temp, documents: r.data?.documents });
      setMissingDocuments(fillMissingDocs());
    });
  };

  const handlePhotoUpload = async files => {
    if (!files) return;

    const imageTypes = ["image/jpeg", "image/png"];

    const isHeicFile = fileName => {
      return fileName.slice(fileName.lastIndexOf(".")).toLowerCase() === ".heic";
    };

    const isValid = file => {
      return imageTypes.includes(file.type) || isHeicFile(file.name);
    };

    const convertToPng = async file => {
      try {
        let blob = new Blob([file], { type: "image/heic" });
        return heic2any({ blob });
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const newPhotoIds = [];
    try {
      for (let i = 0; i < files?.length; i++) {
        let file = files[i];

        if (!isValid(file)) {
          console.warn(`Invalid file type for ${file.name}. Skipping.`);
          continue;
        }

        if (isHeicFile(file.name)) {
          try {
            file = await convertToPng(file);
          } catch (error) {
            console.error("Error uploading file");
            continue;
          }
        }

        const formData = new FormData();
        formData.append("photo", file);
        const { photo_id } = (await uploadPhoto(house.id, formData)).data;
        newPhotoIds.push(photo_id);
      }
      const photos = house?.photos?.flat() || [];
      photos.push(...newPhotoIds);

      setHouse(prevHouse => ({ ...prevHouse, photos }));
    } catch (err) {
      console.error("Error uploading photos", err);
    }
  };

  const handlePhotoDelete = async photo_id => {
    deletePhoto(house.id, photo_id)
      .then(() => {
        setHouse({ ...house, photos: house.photos.filter(id => id !== photo_id) });
      })
      .catch(e => {
        console.error("error deleting photo", photo_id, e);
      });
  };

  const handleVideoUpload = async video => {
    if (!video) return;
    const allowedTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/x-matroska"]; // Add other allowed types as needed
    if (!allowedTypes.includes(video.type)) {
      console.warn("Invalid video type");
      return;
    }
    // Set loading state
    setIsUploadingVideo("loading");
    try {
      let { url, videoId } = await getS3SignedUrl(house.id, video.type);
      if (!url || !videoId) throw new Error("Invalid response from server");
      await uploadVideo(url, video, videoId, house.id);
      setHouse({ ...house, documents: { ...house.documents, video: { url: videoId, status: "pending" } } });
    } catch (err) {
      window.alert("Erro ao fazer upload do vídeo " + (err.data?.message || err.message || err));
      console.error(err);
    }
  };

  const handle3DVideoUpload = () => {
    if (!isEmpty(matterportLink)) {
      upload3DVideo(house.id, matterportLink).then(r => {
        setHouse({ ...house, documents: { ...house.documents, matterport: matterportLink } });
      });
    }
  };

  const downloadPhotos = async () => {
    setLoadingZip(true);
    try {
      const link = document.createElement("a");
      const blob = await getPropertyPhotosZip(house.id);
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = house.reference + "_photos.zip";
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error("error downloading photos", e);
      window.alert("Erro ao fazer download das fotos");
    } finally {
      setLoadingZip(false);
    }
  };

  const downloadFiles = files => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    files.forEach(file => {
      const fileName = file.name;
      fetch(file.url)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          anchor.href = url;
          anchor.download = fileName;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    });
  };

  const documentTranslations = {
    property_book: "Caderneta Predial Urbana",
    building_permanent_land_registry: "CRP - Certidão da Conservatória do Registo Predial",
    energy_certificate: "Certificado Energético",
    floor_plan: "Planta do Imóvel",
    use_license: "Licença de Utilização",
    housing_technical_sheet: "Ficha Técnica de Habitação",
    lease_agreement: "Contrato de Arrendamento",
    tenant_first_refusal: "Direito de Preferência do Inquilino",
    debt_free_condominium: "Declaração de não dívida ao condomínio",
    fraction_permanent_land_registry: "CRP - Certidão do Registo Predial - Fração",
    commercial_certificate: "Certificado Comercial",
    first_refusal: "Direito de Preferência",
  };

  return (
    <div style={styles.pageStyles}>
      <div style={{ width: "300px", display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
        <h3 onClick={() => setIsMedia(true)} style={isMedia ? {} : { color: "rgb(170, 170, 170)", cursor: "pointer" }}>
          Media
        </h3>
        <h3
          onClick={() => setIsMedia(false)}
          style={!isMedia ? {} : { color: "rgb(170, 170, 170)", cursor: "pointer" }}
        >
          Documentos
        </h3>
      </div>
      {isMedia && (
        <>
          <div style={{ ...styles.mediaTypeStyles, width: "1000px" }}>
            <div style={styles.mediaStyles}>
              <h3>Video</h3>
              <div>
                {isEmpty(house?.documents?.video) && (
                  <div style={styles.mediaStyles}>
                    <label htmlFor="files">Selecione o video:</label>
                    {isUploadingVideo === "loading" ? (
                      <div>Loading...</div>
                    ) : (
                      <div>
                        <input
                          className="my-input"
                          type="file"
                          id="files"
                          name="files"
                          onChange={e => handleVideoUpload(...e.target.files)}
                        />
                        {isUploadingVideo?.includes("size_exceed") && (
                          <div>
                            <br /> <span style={{ color: "darkred" }}>{isUploadingVideo.split(":")[1]}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {!isEmpty(house?.documents?.video) && (
                  <div style={styles.mediaTypeStyles}>
                    {house.documents.video?.status === "uploaded" ? (
                      <>
                        <Link style={{ marginRight: "15px" }} to={s3Url + house?.documents?.video?.url}>
                          Video
                        </Link>
                        <button onClick={() => handleVideoRemoval("video")} style={{ backgroundColor: "gainsboro" }}>
                          {" "}
                          X
                        </button>
                      </>
                    ) : (
                      <div>A ser processado...</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div style={{ ...styles.mediaStyles, marginTop: "30px" }}>
              <h3>3D Video</h3>
              {!isEmpty(house.documents.matterport?.url) && (
                <div style={styles.mediaTypeStyles}>
                  <Link style={{ marginRight: "15px" }} to={house.documents.matterport?.url} target={"_blank"}>
                    {" "}
                    3D Video
                  </Link>
                  <button onClick={() => handleVideoRemoval("matterport")} style={{ backgroundColor: "gainsboro" }}>
                    {" "}
                    X
                  </button>
                </div>
              )}
              {isEmpty(house.documents.matterport) && (
                <div style={{ ...styles.mediaTypeStyles, flexDirection: "column" }}>
                  <label>Inserir um link válido para o video 3D</label>
                  <input
                    className={"simple-input"}
                    type={"text"}
                    placeholder={"Insert matterport url"}
                    onChange={e => setMatterportLink(e.target.value)}
                  />
                  {!isEmpty(matterportLink) && (
                    <button onClick={handle3DVideoUpload} className={"simple-button"}>
                      {" "}
                      Adicionar{" "}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={{ ...styles.mediaStyles, width: "80%" }}>
            <h3 style={{ margin: "20px 0 20px 0" }}>Fotos</h3>
            <button
              style={{
                maxWidth: "200px",
                borderRadius: "10px",
                backgroundColor: "#339999",
                color: "white",
              }}
              className={"simple-button"}
              disabled={loadingZip}
              onClick={downloadPhotos}
            >
              {loadingZip ? "Downloading..." : "Download fotos"}
            </button>
            <div>
              <div style={{ ...styles.mediaStyles, marginBottom: "15px" }}>
                <label style={{ margin: "30px 0", fontWeight: "600" }} htmlFor="files">
                  Selecione a(s) foto(s):
                </label>
                <input
                  style={{ width: "127px" }}
                  onChange={e => handlePhotoUpload(e.target.files)}
                  className="my-input"
                  type="file"
                  id="files"
                  name="files"
                  multiple
                />
              </div>
              {!isEmpty(house?.photos) && (
                <div style={{ display: "flex", maxWidth: "1200px", flexWrap: "wrap" }}>
                  {house?.photos?.map((photo, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          maxHeight: "250px",
                          maxWidth: "300px",
                          padding: "5px",
                          backgroundColor: "#339999",
                          margin: "5px",
                          borderRadius: "15px",
                        }}
                      >
                        <Link style={{ marginRight: "15px" }} key={i} target={"_blank"} to={s3Url + photo + "_hd"}>
                          <img
                            style={{
                              height: "200px",
                              width: "300px",
                              cursor: "pointer",
                              borderRadius: "10px",
                              objectFit: "cover",
                            }}
                            loading={"lazy"}
                            src={s3Url + photo + "_hd"}
                            alt={""}
                          />
                        </Link>
                        <button
                          style={{
                            backgroundColor: "darkred",
                            color: "white",
                            margin: "3px auto auto",
                            maxWidth: "25%",
                            borderRadius: "30px",
                          }}
                          className={"simple-button"}
                          onClick={() => handlePhotoDelete(photo)}
                        >
                          {" "}
                          Apagar
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {!isMedia && (
        <div style={{ ...styles.allFilesStyles, marginTop: "50px", flexDirection: "column" }}>
          {fillMissingDocs().length > 0 && (
            <div style={{ textAlign: "center" }}>
              <h3 style={styles.labelStyles}>Documentos em falta</h3>
              <button className={"simple-button"} onClick={() => setNotifyModal(true)}>
                Notificar Utilizador
              </button>
              {notifyModal && (
                <MissingDocNotifier
                  handleClose={() => setNotifyModal(false)}
                  user_info={{
                    user_name: house.user_name,
                    user_email: house.user_email,
                  }}
                  missingDocs={fillMissingDocs()}
                />
              )}
              <div style={{ display: "flex", maxWidth: "1015px", flexWrap: "wrap" }}>{missingDocsBuilder()}</div>
            </div>
          )}

          {/*</div>*/}
          <div style={{ textAlign: "center" }}>
            <h3 style={styles.labelStyles}>Documentos da propriedade</h3>
            <div style={{ display: "flex", maxWidth: "1015px", flexWrap: "wrap" }}>
              {!isEmpty(house?.documents) &&
                Object.entries(house.documents)
                  .filter(
                    doc => !["cmi", "video", "matterport"].includes(doc[0]) && true,
                    // !!doc[1].validated !== true
                  )
                  .map((doc, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          justifyContent: "initial",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "170px",
                          height: "150px",
                          margin: "10px",
                          textAlign: "center",
                          border: "2px solid #339999",
                          borderRadius: "10px",
                          // cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <label
                          style={{
                            display: "block",
                            padding: "10px",
                            fontWeight: "600",
                          }}
                          htmlFor={doc[0]}
                        >
                          <Link
                            target={"_blank"}
                            to={s3Url + doc[1].url}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            {/*capitalizeOnlyFirstLetter(doc[0]).replaceAll("_", " ")*/ documentTranslations[doc[0]]}
                          </Link>
                        </label>
                        <div>
                          {Object.hasOwn(doc[1], "validated") && (
                            <label>
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "40px",
                                  height: "20px",
                                  background: doc[1].validated ? "#339999" : "gray",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  bottom: "45px",
                                  left: "50%",
                                  transform: "translate(-50%)",
                                }}
                                onClick={() =>
                                  handleDocValidation(doc[0], doc[1].validated ? "removeValidation" : "validate")
                                }
                              >
                                <div
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    background: "white",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    transition: "0.3s",
                                    left: doc[1].validated ? "20px" : "2px",
                                    marginTop: "1px",
                                  }}
                                ></div>
                              </div>
                            </label>
                          )}
                        </div>
                        {!isEmpty(removeDocConfirmation) && removeDocConfirmation === doc[0] ? (
                          <button
                            className={"simple-button"}
                            style={{
                              backgroundColor: "#fa7575",
                              color: "white",
                              position: "absolute",
                              bottom: "10px",
                              left: "1px",
                            }}
                            onClick={() => handleDocValidation(doc[0], "remove")}
                          >
                            Aceitar
                          </button>
                        ) : (
                          <button
                            className={"simple-button"}
                            style={{
                              backgroundColor: "rgb(245 238 68)",
                              color: "black",
                              position: "absolute",
                              bottom: "10px",
                            }}
                            onClick={() => setRemoveDocConfirmation(doc[0])}
                          >
                            {removeDocConfirmation === doc[0] ? "Confirmar?" : "Remover"}
                          </button>
                        )}
                        {removeDocConfirmation === doc[0] && (
                          <button
                            className={"simple-button"}
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "1px",
                              left: "auto",
                            }}
                            onClick={() => setRemoveDocConfirmation("")}
                          >
                            Cancelar
                          </button>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
      {!isMedia && (
        <button
          className={"simple-button"}
          style={{
            margin: "30px 0px",
            borderRadius: "10px",
            backgroundColor: "#339999",
            color: "white",
            width: "165px",
          }}
          onClick={() =>
            downloadFiles(
              Object.keys(house?.documents)
                .filter(k => k !== "video")
                .map(k => {
                  return {
                    url: s3Url + house.documents[k].url,
                    name: house?.reference + "_" + k,
                  };
                }),
            )
          }
        >
          Download Documentos
        </button>
      )}
      {!isEmpty(house?.documents?.cmi?.url) && (
        <div style={{ textAlign: "center" }}>
          <h3>Contrato de Mediação Imobiliária</h3>
          {/*<br/>*/}
          <Link className={"simple-button"} style={{ textDecoration: "auto" }} to={s3Url + house.documents.cmi?.url}>
            Ver contrato
          </Link>
        </div>
      )}
    </div>
  );
};

export default MediaDocsTab;
