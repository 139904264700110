import "./App.scss";
import React from "react";
import Login from "./components/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProvideAuth } from "./services/auth";
import Layout from "./components/Layout";
import Properties from "./components/Properties";
import PropertyTab from "./components/PropertyPage/PropertyTab";
import { QueryClient, QueryClientProvider } from "react-query";
import Property from "./components/Property";
import { dynamicRoutes } from "./services";
import GenericListing from "./components/GenericListing";
import { getProposalHistory } from "./services/propertyService";
import User from "./components/User";
import Lead from "./components/Lead/Lead";
import Advisor from "./components/Advisor";

const NotFound = () => {
  return <Navigate to={"/properties"} replace={true} />;
};

const queryClient = new QueryClient();

//TODO talvez usar lazy loading
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvideAuth>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            {/*<Route path="/" element={<div>HOME</div>}/>*/}
            {/*<Route path="/evaluations" element={<Evaluations/>}/>*/}
            <Route path="/properties" element={<Properties />} />
            <Route path="/properties/:id/:tab?" element={<Property />} />
            <Route path="/properties/create" element={<PropertyTab create={true} />} />
            <Route path="/leads/:id/:tab?" element={<Lead />} />
            <Route path="/users/:id/:tab?" element={<User />} />
            <Route path="/advisors/:id/:tab?" element={<Advisor />} />
            {dynamicRoutes().flat()}
            <Route
              path={`/proposalHistory/:id`}
              element={
                <GenericListing
                  {...{
                    name: "Histórico de Ofertas",
                    url: "sublist",
                    table: "proposal",
                    getDataFunction: id => getProposalHistory(id),
                    fields: [
                      ["ID", "id", ""],
                      ["Propriedade", "property_id", ""],
                      ["ID User", "user_id", ""],
                      ["Valor", "value", ""],
                      ["Depósito", "down_payment", ""],
                      ["Valor empréstimo", "loan_amount", ""],
                      ["Aprov. Empréstimo", "bank_pre_approval", ""],
                      ["Comentários", "comments", ""],
                      ["Contingência de venda", "buyer_property", ""],
                      ["Aceite", "accepted", "date"],
                      ["Rejeitada", "rejected", "date"],
                      ["Vista", "seen", "boolean"],
                    ],
                    filters: [["accepted", "button"]],
                  }}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ProvideAuth>
    </QueryClientProvider>
  );
}

export default App;
