import React, { useEffect, useState } from "react";
import { addLead, deleteLead, updateLead } from "../../services/propertyService";
import { validateEmail } from "../../services";
import { toast } from "react-toastify";

const LeadForm = ({ showForm, update, editableData }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
    origin: "",
    nationality: "pt",
  });
  const [showRequired, setShowRequired] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    if (!showForm) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        comments: "",
        origin: "",
        nationality: "pt",
      });
    }
    setConfirmDelete(false);
  }, [showForm]);

  useEffect(() => {
    if (editableData) setFormData({ ...editableData });
  }, [editableData]);

  useEffect(() => {
    setConfirmDelete(false);
  }, [formData]);

  useEffect(() => {
    if (showRequired.includes("name") && formData.name !== "") {
      setShowRequired(showRequired.filter(n => n !== "name"));
    }
    if (showRequired.includes("email") && formData.email !== "") {
      setShowRequired(showRequired.filter(n => n !== "email"));
    }
  }, [formData]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "email" ? value.replace(/\s/g, "") : value,
    });
  };

  const handleNationalityChange = e => {
    const { value } = e.target;
    setFormData({
      ...formData,
      nationality: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    //console.log(formData)
    if (editableData) {
      try {
        const result = await updateLead(editableData.id, formData);
        console.log(result);
        toast.info("Lead atualizada com sucesso", {
          position: "top-right",
          autoClose: 2000,
        });
        await update();
      } catch (error) {
        console.error("Error updating lead:", error);
        toast.error("Erro na atualização da lead", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      return;
    }
    let isEmailValid = false;
    if (formData.email !== "") {
      const test = await validateEmail(formData.email, true);
      if (test === "valid") isEmailValid = true;
      if (test === "not_unique") alert("Este email já existe");
    }
    if (formData.name !== "" && formData.email !== "" && isEmailValid) {
      addLead(formData)
        .then(r => {
          toast.info("Lead criada com sucesso", {
            position: "top-right",
            autoClose: 2000,
          });
          update();
          console.log("Form submitted with data: ", formData);
        })
        .catch(e => {
          toast.error("Erro ao criar lead", {
            position: "top-right",
            autoClose: 2000,
          });
          console.log("Error submitting form: ", e);
        });
    } else {
      const newRequired = [];
      if (formData.name === "") newRequired.push("name");
      if (formData.email === "" || !isEmailValid) newRequired.push("email");
      setShowRequired([...showRequired, ...newRequired]);
    }
  };

  const handleDelete = async () => {
    await deleteLead(editableData.id);
    await setFormData({
      name: "",
      email: "",
      phone: "",
      comments: "",
      origin: "",
      nationality: "pt",
    });
    update();
  };

  const originOptions = [
    "Listoo.pt",
    "Facebook",
    "Idealista",
    "Imovirtual",
    "Instagram",
    "Outros Portais",
    "Parceiros",
    "Recomendação",
    "Supercasa",
    "Wechat",
  ];

  const styles = {
    column: {
      display: "flex",
      flexDirection: "column",
      maxWidth: editableData ? "80%" : "50%",
      marginBottom: "20px",
      fontWeight: "600",
    },
    button: {
      margin: "20px 18%",
      width: "10%",
      minWidth: "100px",
      //backgroundColor: "darkgreen"
    },
    commentary: {
      height: "40%",
    },
    checkbox: {
      marginLeft: "10px",
    },
    selectable: {
      marginLeft: "15px",
    },
    delete: {
      margin: "20px, 18%",
      width: "10%",
      minWidth: "100px",
      backgroundColor: "#7e7a06",
    },
  };

  return (
    <div>
      <form style={styles.column} onSubmit={handleSubmit}>
        <label style={!showRequired?.includes("name") ? { ...styles.column } : { ...styles.column, color: "red" }}>
          Nome (obrigatório):
          <input
            maxLength={40}
            type="text"
            name="name"
            className={"simple-input"}
            required={true}
            value={formData.name}
            onChange={handleChange}
          />
        </label>
        <label style={!showRequired?.includes("email") ? { ...styles.column } : { ...styles.column, color: "red" }}>
          Email (obrigatório):
          <input
            maxLength={40}
            type="text"
            name="email"
            className={"simple-input"}
            required={true}
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <label style={styles.column}>
          Telemóvel:
          <input
            maxLength={40}
            type="text"
            name="phone"
            className={"simple-input"}
            value={formData.phone}
            onChange={handleChange}
          />
        </label>
        <label style={{ ...styles.column, ...styles.commentary }}>
          Comentários:
          <textarea
            style={{ resize: "none" }}
            rows={10}
            name="comments"
            className={"simple-input"}
            value={formData.comments}
            onChange={handleChange}
            maxLength={1000}
          />
        </label>
        <label style={{ ...styles.column, flexDirection: "row" }}>
          Origem:
          <select
            name="origin"
            value={formData.origin}
            onChange={handleChange}
            className={"simple-input"}
            style={styles.selectable}
          >
            {/*<option value="">Selecione a origem</option>*/}
            {originOptions?.map((origin, index) => (
              <option key={index} value={origin}>
                {origin}
              </option>
            ))}
          </select>
        </label>
        <label style={styles.column}>
          Idioma a utilizar:
          <div>
            <label>
              <input
                type="radio"
                name="nationality"
                value="pt"
                checked={formData.nationality === "pt"}
                onChange={handleNationalityChange}
              />
              Portuguese (pt)
            </label>
            <label style={styles.checkbox}>
              <input
                type="radio"
                name="nationality"
                value="en"
                checked={formData.nationality === "en"}
                onChange={handleNationalityChange}
              />
              English (en)
            </label>
          </div>
        </label>
        <button className={"simple-button green"} style={styles.button} onClick={handleSubmit} type="submit">
          Submeter
        </button>
      </form>
      {editableData && (
        <div style={{ width: "500px", margin: "20px 14%" }}>
          {confirmDelete && (
            <p
              style={{
                color: "red",
                fontWeight: "700",
              }}
            >{`Está prestes a apagar a lead com o email ${editableData?.email}`}</p>
          )}
          <button
            className={"simple-button"}
            style={styles.delete}
            onClick={!confirmDelete ? () => setConfirmDelete(true) : () => setConfirmDelete(false)}
          >
            {confirmDelete ? "Cancelar" : "Apagar lead"}
          </button>
          {confirmDelete && (
            <button
              onClick={handleDelete}
              className={"simple-button"}
              style={{ backgroundColor: "darkred", marginLeft: "100px" }}
            >
              Confirmar
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default LeadForm;
