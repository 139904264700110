import Modal from "../../Modal";
import { useState } from "react";
import { changeAdvisorPassword, changeUserPassword } from "../../../services/user";

const ResetAdvisorPassword = ({ handleClose, advisor, isUser = false }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  console.log("advisor", advisor);

  const generatePassword = () => {
    const length = 9;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setPassword(retVal);
  };

  const handleUpdatePassword = async () => {
    if (!password) {
      alert("Verifique os campos.");
      return;
    }
    setLoading(true);
    try {
      const res = !isUser
        ? await changeAdvisorPassword(advisor.id, password)
        : await changeUserPassword(advisor.id, password);
      alert("Password atualizada com sucesso.");
      handleClose();
    } catch (e) {
      alert("Erro ao atualizar password. " + (e.data?.message || e));
      console.error(e);
    }
  };

  return (
    <Modal closeModal={handleClose}>
      <h1>Reset Password</h1>
      <p>
        Insira a nova password para o {!isUser ? `advisor` : `utilizador`} <strong>{advisor.name}</strong>
      </p>
      <label>Password</label>
      <input className={"simple-input"} type="text" value={password} onChange={e => setPassword(e.target.value)} />
      <button className={"simple-button mt-1 mb-1"} onClick={generatePassword}>
        Gerar Password
      </button>
      <button className={"simple-button green"} disabled={loading} onClick={handleUpdatePassword}>
        Atualizar
      </button>
    </Modal>
  );
};

export default ResetAdvisorPassword;
