import { capitalizeOnlyFirstLetter } from "../../../services";
import PropertyFeatures from "../PropertyFeatures";

const AdditionalInfoTab = ({ house, setHouse, handleOptionsChange, handleInvalid, handleInput, handleChange }) => {
  const styles = {
    labelStyles: {
      //textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    scrollStyles: {
      height: "200px",
      overflowX: "overlay",
    },
    dropdownStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      maxWidth: "50%",
      flexDirection: "column",
      margin: "15px",
    },
    textAreaStyles: {
      width: "40%",
      minWidth: "300px",
      marginRight: "10px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    checkBoxStyles: {
      display: "flex",
      justifyContent: "space-between",
    },
    additionalInfoStyles: {
      display: "flex",
      gap: "32px",
      // justifyContent: "space-between",
      alignItems: "baseline",
      // width: "1000px",
    },
    selectableStyles: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  };
  return (
    <div style={styles.pageStyles}>
      <h3>Informação adicional</h3>
      <div style={styles.additionalInfoStyles}>
        <div style={styles.dropdownStyles}>
          <label style={styles.labelStyles}>Exposição solar</label>
          {["east", "west", "north", "south"].map((e, i) => (
            <div key={i} className={"CheckOption"}>
              <label style={styles.checkBoxStyles}>
                {capitalizeOnlyFirstLetter(e)}
                <input
                  name={e}
                  checked={house?.sun_exposure?.includes(e)}
                  value={e}
                  onChange={x => handleOptionsChange(e, x.target.checked, "sun_exposure")}
                  type={"checkbox"}
                />{" "}
              </label>
            </div>
          ))}
        </div>
        <div style={styles.dropdownStyles}>
          <label style={styles.labelStyles}>Áreas circundantes</label>
          {[
            "pharmacies",
            "subway",
            "bus",
            "beach",
            "train",
            "shopping Area",
            "green Areas",
            "banks",
            "supermarket",
            "schools",
          ]
            .map(e => e.replace(" ", ""))
            .map((e, i) => (
              <div key={i} className={"CheckOption"}>
                <label style={styles.checkBoxStyles}>
                  {capitalizeOnlyFirstLetter(e)}
                  <input
                    name={e}
                    checked={house?.surrounding_areas?.includes(e)}
                    value={e}
                    onChange={x => handleOptionsChange(e, x.target.checked, "surrounding_areas")}
                    type={"checkbox"}
                  />
                </label>
              </div>
            ))}
        </div>
        <div style={styles.dropdownStyles}>
          <label style={styles.labelStyles}>Extras</label>
          {[
            "open Kitchen",
            "independent Kitchen",
            "laundry Room",
            "double Glasses",
            "sea View",
            "city View",
            "built In Wardrobe",
            "smoke Extractor",
            "equipped Kitchen",
            "central Heating",
            "air Conditioning",
            "fireplace",
            "pool",
          ]
            .map(e => e.replace(/ /g, ""))
            .map((e, i) => (
              <div key={i} className={"CheckOption"}>
                <label style={styles.checkBoxStyles}>
                  {capitalizeOnlyFirstLetter(e)}
                  <input
                    name={e}
                    checked={house?.features?.extras?.includes(e)}
                    value={e}
                    onChange={x => handleOptionsChange(e, x.target.checked, "extras")}
                    type={"checkbox"}
                  />
                </label>
              </div>
            ))}
        </div>
        <div style={styles.dropdownStyles}>
          <label style={styles.labelStyles}>Features</label>
          <PropertyFeatures property={house} setProperty={setHouse} styles={styles} />
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={styles.textAreaStyles}>
          <h3 style={styles.labelStyles}>Descrição da Listoo (PT)</h3>
          <textarea
            style={{
              height: "160px",
              verticalAlign: "top",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              resize: "none",
            }}
            onChange={e => handleChange(e, "listoo_comment")}
            name={"listoo_comment"}
            value={house.listoo_comment || ""}
          ></textarea>
        </div>
        <div style={styles.textAreaStyles}>
          <h3 style={styles.labelStyles}>Descrição da Listoo (EN)</h3>
          <textarea
            style={{
              height: "160px",
              verticalAlign: "top",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              resize: "none",
            }}
            onChange={e => handleChange(e, "listoo_comment_en")}
            name={"listoo_comment"}
            value={house.listoo_comment_en || ""}
          ></textarea>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={styles.textAreaStyles}>
          <h3 style={styles.labelStyles}>Comentários do proprietário</h3>
          <textarea
            style={{ height: "160px", resize: "none" }}
            name={"owner_comment"}
            value={house.owner_comment || ""}
            readOnly={true}
          ></textarea>
        </div>
      </div>

      <div>
        <h3>Notas</h3>
        <textarea
          style={{
            height: "160px",
            verticalAlign: "top",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            resize: "none",
            width: "300px",
          }}
          maxLength={1000}
          onChange={e => handleChange(e, "notes")}
          name={"notes"}
          value={house.notes || ""}
        ></textarea>
      </div>
    </div>
  );
};

export default AdditionalInfoTab;
