import ptPt from "date-fns/locale/pt";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { useAuth } from "../../../services/auth";
import { createVisit, getAllProperties, getAllUsers, getLeads, getUser } from "../../../services/propertyService";
import Modal from "../../Modal";

registerLocale("pt-PT", ptPt);
setDefaultLocale("pt-PT");

const CreateVisit = ({ handleClose, isLeads = false, property_id = 0, user_id = 0, lead_id = 0 }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [userId, setUserId] = useState(user_id || null);
  const [propertyId, setPropertyId] = useState(property_id || null);

  const [allProperties, setAllProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //fetch users and properties

    (isLeads ? getLeads() : !user_id ? getAllUsers() : getUser(user_id)).then(res => {
      !user_id
        ? setUsers(!isLeads ? res : res.filter(r => !r.user_id))
        : setUsers([
            {
              value: res.id,
              label: res.name,
              user_id: res.id,
            },
          ]);
    });
    getAllProperties(1, "", {
      status: "active",
      allHouses: true,
      id: property_id,
    }).then(res => {
      const _properties = res.properties.map(p => {
        return { value: p.id, label: p.title.pt + " | " + p.reference, user_id: p.user_id };
      });
      setProperties(_properties);
      setAllProperties(_properties);
    });
  }, []);

  useEffect(() => {
    if (userId && !isLeads) {
      setProperties(allProperties.filter(p => p.user_id !== userId));
      setPropertyId(property_id || null);
    }
  }, [userId]);

  const session = useAuth();

  const handleCreateVisit = async () => {
    if (!selectedDate || !userId || !propertyId) {
      alert("Verifique os campos.");
      return;
    }
    setLoading(true);
    try {
      if (isLeads) {
        //TODO: Once Listoo sets up an advisor/realtor account, it should link these backoffices to it instead of the signed-in admin user.
        await createVisit(session?.user.id || session?.user?.data?.user?.id, propertyId, selectedDate, userId);
      } else await createVisit(userId, propertyId, selectedDate);

      toast.info("Visita criada com sucesso", {
        position: "top-right",
        autoClose: 2000,
      });
      handleClose();
    } catch (e) {
      toast.error("Erro ao criar visita. " + (e.data?.message || e), {
        position: "top-right",
        autoClose: 2000,
      });
      console.log(e);
    }
    setLoading(false);
    //create visit
  };

  return (
    <Modal closeModal={handleClose}>
      <h1 onClick={() => console.log(users)}>Criar Visita</h1>
      <h3>Escolher {!isLeads ? "utilizador" : "lead"}:</h3>
      {users && !isLeads && !user_id && (
        <Select
          onChange={o => setUserId(o.value)}
          options={users
            ?.filter(p => p.id !== userId)
            .map(user => ({
              value: user.id,
              label: user.name + " | " + user.email,
            }))}
        />
      )}
      {users && isLeads && !user_id && (
        <Select
          onChange={o => setUserId(o.value)}
          options={users
            ?.filter(user => (lead_id ? user.id === lead_id : user.id))
            .map(user => ({
              value: user.id,
              label: user.name + " | " + user.email,
            }))}
        />
      )}
      {!!user_id && <Select isDisabled={true} value={users[0]} />}
      {userId && properties && (
        <>
          <h3>Escolher propriedade:</h3>
          {!property_id ? (
            <Select onChange={o => setPropertyId(o.value)} options={properties} />
          ) : (
            <Select isDisabled={true} value={properties.find(property => property.value === Number(property_id))} />
          )}
          {/*<Select onChange={(o) => setPropertyId(o.value)} options={property_id ? properties.filter((property) => property.value === Number(property_id)) : properties}/>*/}
        </>
      )}
      {propertyId && userId && (
        <>
          <h3>Escolher data</h3>
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            locale="pt-PT"
            showTimeSelect
            timeIntervals={10}
            minDate={new Date()}
            placeholderText="Escolher data"
            dateFormat="dd/MM/yyyy HH:mm"
          />
        </>
      )}
      <button
        style={{ marginTop: "10px" }}
        disabled={!propertyId || !userId || !selectedDate}
        onClick={handleCreateVisit}
      >
        Agendar
      </button>
    </Modal>
  );
};

export default CreateVisit;
