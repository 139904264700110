import React, { useState } from "react";
import { Link } from "react-router-dom";
import { confirmSale, removeProcessDocument, saveProcess, uploadProcessDocument } from "../../services/propertyService";
import { capitalizeOnlyFirstLetter, dateFormat, isEmpty } from "../../services";

function ProcessPage(props) {
  const [process, setProcess] = useState({ ...props.process } || {});
  const [removeDoc, setRemoveDoc] = useState("");
  const [deedSignedConfirmation, setDeedSignedConfirmation] = useState(false);
  const s3Url = process.env.REACT_APP_S3_AWS_URL;

  const handleDocUpload = async (e, doc) => {
    let file = e.target.files[0];
    if (!file || !process.id) return;
    let formData = new FormData();
    formData.append("document", file);
    formData.append("document_type", doc);
    await uploadProcessDocument(process.id, formData);
    //const temp = process;
    props.setLoad(true);
  };

  const handleDocRemoval = async doc => {
    if (!isEmpty(removeDoc)) {
      removeProcessDocument(process.id, doc).then(r => {
        const temp = { ...process };
        temp[doc] = "";
        setProcess(temp);
        setRemoveDoc("");
        props.setLoad(true);
      });
    }
  };

  const handleCPCVPayment = event => {
    setProcess({ ...process, cpcv_payment: event.target.value });
  };

  const handleSave = async () => {
    await saveProcess(process.id, process);
    window.alert("Alterações feitas ao processo guardadas com sucesso!");
    props.setLoad(true);
  };

  const removalButton = docType => {
    return !isEmpty(process[docType]) && (isEmpty(removeDoc) || removeDoc !== docType) ? (
      <button
        disabled={process?.deed_signed}
        style={{ backgroundColor: "rgb(245 238 68)", color: "black" }}
        onClick={() => setRemoveDoc(docType)}
      >
        Remover {capitalizeOnlyFirstLetter(docType)}?
      </button>
    ) : !isEmpty(process[docType]) && removeDoc === docType ? (
      <div>
        <button className={"simple-button"} onClick={() => setRemoveDoc("")}>
          Cancelar
        </button>
        <button
          className={"simple-button"}
          style={{ backgroundColor: "#fa7575", color: "white" }}
          onClick={() => handleDocRemoval(docType)}
        >
          Confirmar
        </button>
      </div>
    ) : (
      ""
    );
  };

  const datePicker = docType => {
    return (
      <div>
        <h2>Data de assinatura - {docType === "deed" ? "Escritura" : docType.toUpperCase()}</h2>
        <div>
          <p>
            Comprador sugeriu: {!isEmpty(process[docType + "_buyer"]) ? dateFormat(process[docType + "_buyer"]) : "/"}
          </p>
          <p>
            Vendedor sugeriu: {!isEmpty(process[docType + "_seller"]) ? dateFormat(process[docType + "_seller"]) : "/"}
          </p>
        </div>
        <div>
          <p>Data final: {!isEmpty(process[docType + "_date"]) ? dateFormat(process[docType + "_date"]) : "/"}</p>
          <input
            disabled={process?.deed_signed}
            value={process[docType + "_date"]?.split("T")?.[0]}
            onChange={e =>
              setProcess({
                ...process,
                [docType + "_date"]: e.target.value.split(".").reverse().join("-"),
              })
            }
            min={new Date().toISOString().split("T")[0]}
            type={"date"}
            name={docType}
            placeholder={"DD/MM/YYYY"}
          />
        </div>
      </div>
    );
  };

  const handleDeedSigned = () => {
    const finishedProcess = { ...process, deed_signed: true, status: "sold" };
    confirmSale(process.id, finishedProcess).then(r => {
      setProcess({ ...process, deed_signed: true, status: "sold" });
      setDeedSignedConfirmation(false);
    });
  };

  const [showInfo, setShowInfo] = useState("");

  return (
    <div style={{ textAlign: "center" }}>
      <h1 onClick={() => console.log(process)}>Processo número {process.id}</h1>
      <div>
        <button className={"simple-button"} style={{ margin: "10px" }} onClick={() => setShowInfo("seller_profile")}>
          Dados do vendedor
        </button>
        <button className={"simple-button"} style={{ margin: "10px" }} onClick={() => setShowInfo("buyer_profile")}>
          Dados do comprador
        </button>
      </div>
      {!isEmpty(showInfo) && process !== {} && (
        <div style={{ margin: "10px", border: "1px solid black", maxHeight: "250px", overflowX: "scroll" }}>
          {Object.entries(JSON.parse(process[showInfo]))
            .filter(([key, value]) => !isEmpty(value))
            .map(([key, value], i) => (
              <p key={i}>
                {capitalizeOnlyFirstLetter(key).replaceAll("_", " ")} :{" "}
                {key.includes("date") ? (
                  dateFormat(value)
                ) : key.includes("file") ? (
                  <Link to={s3Url + value}>Ver</Link>
                ) : (
                  value
                )}
              </p>
            ))}
        </div>
      )}
      {!isEmpty(showInfo) && process !== {} && (
        <button className={"simple-button"} onClick={() => setShowInfo("")}>
          Fechar info
        </button>
      )}
      <div style={{ maxWidth: "150px", margin: "auto" }}>
        {[
          ["cpcv_draft", "Rascunho CPCV"],
          ["cpcv", "CPCV"],
          ["deed", "Escritura"],
        ].map((doc, i) => (
          <div key={i}>
            <h2>{doc[1]}</h2>
            {isEmpty(process[doc[0]]) && <input type="file" onChange={e => handleDocUpload(e, [doc[0]])} />}
            {!isEmpty(process[doc[0]]) && (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Link to={s3Url + process[doc[0]]}>Ver {process[doc[1]]}</Link>{" "}
                {!process?.deed_signed && removalButton(doc[0])}
              </div>
            )}
          </div>
        ))}
      </div>
      {datePicker("cpcv")}
      <div>
        <h2>Valor pago durante a assinatura do CPCV</h2>
        <input
          type="number"
          disabled={process?.deed_signed}
          value={process?.cpcv_payment}
          onChange={handleCPCVPayment}
        />{" "}
        euros
      </div>
      {datePicker("deed")}
      <div>
        <h2>Data de limite para a Escritura</h2>
        <input
          disabled={process?.deed_signed}
          value={process?.deed_deadline?.split("T")?.[0]}
          onChange={e =>
            setProcess({
              ...process,
              deed_deadline: e.target.value.split(".").reverse().join("-"),
            })
          }
          min={new Date().toISOString().split("T")[0]}
          type={"date"}
          name={"deed_deadline"}
          placeholder={"DD/MM/YYYY"}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <button
          style={{ margin: "15px" }}
          disabled={process?.deed_signed}
          className={"simple-button"}
          onClick={handleSave}
        >
          Guardar alterações ao processo
        </button>
        {!process?.deed_signed && (
          <div style={{ margin: "15px" }}>
            {!deedSignedConfirmation && (
              <button
                className={"simple-button"}
                style={{ backgroundColor: "#44f5de", color: "black" }}
                onClick={() => setDeedSignedConfirmation(true)}
              >
                Marcar escritura como assinada
              </button>
            )}
            {deedSignedConfirmation && (
              <div style={{ margin: "15px" }}>
                <label style={{ fontWeight: "700" }} htmlFor="deedSignedConfirmation">
                  Confirmo que a escritura foi assinada e pretendo concluir este processo
                </label>
                <div style={{ marginTop: "25px" }}>
                  <button
                    className={"simple-button"}
                    style={{ marginRight: "15px", backgroundColor: "#fa7575", color: "white" }}
                    onClick={handleDeedSigned}
                  >
                    Confirmar
                  </button>
                  <button className={"simple-button"} onClick={() => setDeedSignedConfirmation(false)}>
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProcessPage;
