import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fromPath = location.state?.from?.pathname || "/";

  const handleLogin = async () => {
    try {
      let user = await login(email, password);
      if (user) {
        navigate(fromPath, { replace: true });
      }
    } catch (e) {
      setErrorMessage(e.data?.message || "Erro ao fazer login.");
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, [password, email]);

  return (
    <div className={"LoginPage"}>
      <div className={"LoginForm"} onKeyDown={e => e.code === "Enter" && handleLogin()}>
        <h2>Login</h2>
        <input type="email" placeholder="Email" name={"email"} onChange={e => setEmail(e.target.value)} />
        <input
          type="password"
          minLength={6}
          name={"password"}
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />
        {errorMessage && <p className={"error-message small"}>{errorMessage}</p>}
        <button disabled={!password || !email} onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
