import Modal from "../../Modal";
import { useState } from "react";
import { changeUserEmail, deleteUser, reactivateUser, updateUser } from "../../../services/propertyService";
import { dateFormat } from "../../../services";

const DeleteAccount = ({ handleClose, user }) => {
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAccountDeletion = async () => {
    setLoading(true);
    try {
      const res = !!user.inactive ? await reactivateUser(user.id) : await deleteUser(user.id);
      alert(!!user.inactive ? "Conta reactivada com sucesso." : "Conta removida com sucesso.");
      if (!!user.inactive) window.location.href = `/admin/user/${user.id}`;
      if (!user.inactive) window.location.href = "/admin/users";
    } catch (e) {
      alert("Erro ao remover conta. " + (e.data?.message || e));
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Modal closeModal={handleClose}>
      <h1>{!!user.inactive ? "Reactivar" : "Apagar"} conta</h1>
      <p>
        Tem certeza de que deseja {!!user.inactive ? "reactivar" : "excluir"} a conta de <strong>{user.name}</strong>?{" "}
        <br />
        {!!user.inactive ? "" : "Esta ação é irreversível."}
        <br />
        {!!user.inactive
          ? "Todos as propriedades do utilizador deixarão de estar arquivadas e o mesmo poderá voltar a fazer login"
          : "\nTodos os dados pessoais, configurações e informações associadas serão removidos permanentemente do sistema."}
      </p>
      {!!user.financial_profile && <span>⬤ Este user já preencheu o perfil financeiro</span>}
      {!!user.evaluation_count && (
        <span>
          ⬤ Este utilizador já completou{" "}
          {user.evaluation_count === 1 ? "uma avaliação" : `${user.evaluation_count} avaliações`}{" "}
        </span>
      )}
      {!!user.property_count && (
        <span>
          ⬤ Este utilizador já registou {user.property_count === 1 ? "uma propriedade" : `${user.property_count} casas`}{" "}
        </span>
      )}
      {!!user.inactive && (
        <span>
          ⬤ A conta será permanentemente arquivada dia{" "}
          {dateFormat(new Date(user.inactive).setDate(new Date(user.inactive).getDate() + 30))}
        </span>
      )}
      <div style={{ margin: "auto" }}>
        <input
          style={{ marginTop: "10px" }}
          type={"checkbox"}
          checked={consent === true}
          onChange={() => setConsent(!consent)}
        />
        <label style={{ fontSize: "12px", color: "darkred" }}>
          Declaro que pretendo {!!user.inactive ? "reactivar" : "apagar"} esta conta, reconhecendo que o utilizador
          {!!user.inactive ? " voltará a ter" : " perderá"} acesso à plataforma
        </label>
      </div>
      <button
        style={consent ? { marginTop: "auto" } : { marginTop: "auto", cursor: "auto", backgroundColor: "gray" }}
        className={"simple-button green"}
        disabled={loading || !consent}
        onClick={handleAccountDeletion}
      >
        Confirmar
      </button>
    </Modal>
  );
};

export default DeleteAccount;
