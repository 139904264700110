import bell from "../../assets/images/notification_bell.svg";
import { usePopper } from "react-popper";
import { useEffect, useState } from "react";
import { getNotifications, markNotificationRead } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./styles.scss";
import { useAuth } from "../../services/auth";
import { base_url } from "../../services";
const { io } = require("socket.io-client");
const socket = io(base_url);

const Notifications = () => {
  const { user } = useAuth();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const navigate = useNavigate();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: 10,
        },
      },
    ],
    placement: "bottom-end",
    strategy: "fixed",
  });

  const handleNotificationClick = (e, notification) => {
    e.stopPropagation();
    setOpen(false);
    if (!notification.read) {
      setNotifications(
        notifications.map(n => {
          if (n.id === notification.id) {
            n.read = new Date();
          }
          return n;
        }),
      );
      markNotificationRead(notification.id).catch(err => {
        console.error("error updating notification as read", err);
      });
    }
    if (notification.url) {
      navigate(notification.url);
    }
  };

  useEffect(() => {
    if (user?.socketToken) {
      socket.emit("subscribe", { token: user?.socketToken });
    }
  }, [user?.socketToken]);

  useEffect(() => {
    getNotifications().then(res => {
      setNotifications(res);
    });

    socket.on("connect", () => {
      console.log("connected to socket io:", socket.connected);
    });
    socket.on("connect_error", err => {
      console.log("error connecting to socket", err);
      setTimeout(() => {
        socket.connect();
      }, 2000);
    });
    socket.on("notification", newNotification => {
      if (!newNotification.visibleFrom || new Date(newNotification.visibleFrom) < new Date()) {
        setNotifications(prev => [{ ...newNotification, date: new Date() }, ...prev]);
        toast.info(newNotification.message_pt, {
          position: "top-right",
          onClick: () => {
            setOpen(true);
          },
          hideAfter: 5,
        });
        //const {hide} = cogoToast.info(t("notifications." + newNotification.type + ".title"), {position: 'top-right', onClick: () => {hide();setOpenNotifications(true)}, hideAfter: 5})
      }
    });
  }, []);

  useEffect(() => {
    setUnreadNotifications(notifications.filter(notification => !notification.read).length);
  }, [notifications]);

  const getDateFromNow = date => {
    const now = new Date();
    const diff = now - new Date(date);
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) {
      return weeks + " semana" + (weeks > 1 ? "s" : "");
    } else if (days > 0) {
      return days + " dia" + (days > 1 ? "s" : "");
    } else if (hours > 0) {
      return hours + " hora" + (hours > 1 ? "s" : "");
    } else if (minutes > 0) {
      return minutes + " minuto" + (minutes > 1 ? "s" : "");
    } else {
      return seconds + " segundo" + (seconds === 1 ? "" : "s");
    }
  };

  return (
    <>
      <div
        className={"notification-bell"}
        ref={setReferenceElement}
        onClick={e => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <div className={"image-wrapper"}>
          <img src={bell} alt={"bell"} className={"bell-icon"} />
          {unreadNotifications > 0 && (
            <div style={unreadNotifications > 99 ? { width: "20px" } : {}} className={"unread-warning"}>
              {unreadNotifications}
            </div>
          )}
        </div>
      </div>
      {open && (
        <div className={"NotificationsWrapper"} onClick={() => open && setOpen(false)}>
          <div className={"NotificationList"} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {notifications.map((notification, index) => {
              return (
                <div
                  key={index}
                  className={"NotificationList__item" + (notification.read ? "" : " warning-dot")}
                  onClick={e => handleNotificationClick(e, notification)}
                >
                  <div className={"message"}>{notification.message_pt}</div>
                  <p className={"date"}>Há {getDateFromNow(notification.date)}</p>
                </div>
              );
            })}
            {notifications.length === 0 && <div className={"NotificationList__item"}>Não tem notificações</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
