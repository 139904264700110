import axios from "axios";
import { Route } from "react-router-dom";

import Cookies from "js-cookie";
import React from "react";
import Select from "react-select";
import GenericListing from "../components/GenericListing";
import {
  getAllProcesses,
  getAllRequests,
  getAllUsers,
  getEvaluations,
  getLeads,
  getProposals,
  getVisits,
  handleRequestCompletion,
  updateEvaluation,
  updateUserStatus,
  verifyUniqueEmail,
} from "./propertyService";
import { getAllAdvisors } from "./user";

//try to clean this code - heroku doesnt rebuild after pushing to prod
let base_url = "http://localhost:3030";
const current_url = window.location.host;
if (current_url.includes("backoffice.staging.listoo.pt")) {
  base_url = process.env.REACT_APP_API_BASE;
} else if (current_url.includes("backoffice.listoo.pt")) {
  base_url = process.env.REACT_APP_API_BASE_PROD;
}
const _axios = axios.create({ withCredentials: true });

const post = async function (url, params, setProgress) {
  const cookie = Cookies.get("connect.sid.backoffice");
  let headers = {
    ...(cookie ? { Authorization: "auth_cookie " + encodeURIComponent(cookie) } : {}),
  };

  const config = {
    onUploadProgress: setProgress
      ? function (progressEvent) {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      : undefined,
    withCredentials: true,
    headers,
  };

  try {
    let res = (await _axios.post(base_url + url, params, config)).data;
    let connectionSid = res?.data?.connectionSid;
    if (connectionSid) {
      Cookies.set("connect.sid.backoffice", connectionSid);
    }
    return res;
  } catch (error) {
    throw (error.response && error.response.data) || error;
  }
};

const get = async function (url, params, responseType) {
  const cookie = Cookies.get("connect.sid.backoffice");
  let headers = {
    ...(cookie ? { Authorization: "auth_cookie " + encodeURIComponent(cookie) } : {}),
  };

  try {
    return (
      await _axios.get(base_url + url, {
        withCredentials: true,
        params,
        headers,
        responseType,
      })
    ).data;
  } catch (error) {
    throw (error.response && error.response.data) || error;
  }
};

const isEmpty = element => {
  if (Array.isArray(element)) {
    return element.length === 0;
  } else {
    return element === undefined || element === null || element === "";
  }
};

async function validateEmail(email, checkUnique = false) {
  const regex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  if (checkUnique) {
    const response = await verifyUniqueEmail(email);
    if (!!response?.data) return "not_unique";
    return regex.test(email) ? "valid" : "invalid";
  } else return regex.test(email) ? "valid" : "invalid";
}

const dateFormat = (value, numeric = false) => {
  return new Date(value).toLocaleDateString(
    "pt-PT",
    !numeric ? { year: "numeric", month: "long", day: "numeric" } : "",
  );
};

const capitalizeOnlyFirstLetter = text => {
  return text?.charAt(0).toUpperCase() + text?.substring(1).toLowerCase();
};

const formatCamelCase = str => {
  // Add space before each uppercase letter and capitalize the first letter of the string
  const formattedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  // Capitalize the first letter of the resulting string
  return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
};

function formatNumber(
  number,
  isItCurrency = true,
  dotInsteadOfSpace = true,
  howManyDecimalDigits = 0,
  maxDecimalDigits = 0,
) {
  return new Intl.NumberFormat(
    dotInsteadOfSpace ? "de-DE" : "fr-FR",
    isItCurrency
      ? {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: howManyDecimalDigits,
          maximumFractionDigits: maxDecimalDigits,
        }
      : { style: "decimal", minimumFractionDigits: howManyDecimalDigits },
  ).format(number);
}

export function getPropertyNecessaryDocs(property) {
  const isReserved = property.current_stage === "reserved";
  let docsNecessary = [
    "property_book",
    "building_permanent_land_registry",
    "energy_certificate",
    "floor_plan",
    "fraction_permanent_land_registry",
  ];
  let yearOfConstruction = property?.year_of_construction;
  if (yearOfConstruction >= 1951) docsNecessary.push("use_license");
  if (yearOfConstruction >= 2004) docsNecessary.push("housing_technical_sheet");
  if (property?.rent > 0) {
    docsNecessary.push("lease_agreement");

    if (isReserved) {
      docsNecessary.push("tenant_first_refusal");
    }
  }
  if (/* property?.condominium_fee > 0 && */ isReserved) {
    // sometimes users don't input the condominium fee which defaults to 0, so we won't assume it's 0 for this purpose
    docsNecessary.push("debt_free_condominium");
  }
  //check if owner is a company and if yes add commercial certificate
  return docsNecessary;
}

/*

Visitas
?Aceitar/Recusar visita

Ofertas
incluindo o histórico

*/

//TODO dynamicRoutes deveria estar noutro sitio
function dynamicRoutes() {
  const advisorProps = {
    name: "Advisors",
    url: "advisors",
    table: "user",
    getDataFunction: (page, searchKey, filters) => getAllAdvisors(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Nome", "name", ""],
      ["Email", "email", ""],
      ["Criado a", "registration_date", "date"],
      ["Ações", "", "advisor"],
    ],
    filters: [["Pesquisar", "text"]],
  };
  //selectable => na posicao 3 tem uma array com o [campo/field, value das options, funcao que define a label com base no campo/filed]
  const userProps = {
    name: "Utilizadores",
    url: "users",
    table: "user",
    getDataFunction: (page, searchKey, filters) => getAllUsers(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Nome", "name", "redirect", "/users/"],
      ["Email", "email", ""],
      ["Email Verif.", "email_verified", "boolean"],
      ["Telemóvel", "phone", ""],
      ["Nacionalidade", "nationality", ""],
      ["Último login", "last_login", "date"],
      ["Data de registo", "registration_date", "date"],
      ["Nº Avaliações", "evaluation_count", ""],
      ["Nº Propriedades", "property_count", ""],
      [
        "Status do Cliente",
        "status",
        "selectable",
        (status, row) => {
          const options = [
            { label: "Sem status", value: "no_status" },
            {
              label: "Sem interesse",
              value: "no_interest",
            },
            { label: "Seguir", value: "follow" },
            { label: "Interessado", value: "interested" },
          ];

          return (
            <Select
              className={"SelectableCell"}
              classNamePrefix={"SelectableCell"}
              options={options}
              defaultValue={options.find(entry => entry.value === (row.status || "no_status"))}
              placeholder={status !== null ? options.find(entry => entry.value === status)?.label : "Sem status"}
              onChange={s => updateUserStatus(row.id, s.value)}
            />
          );
        },
      ],
      ["Advisors", "advisors", "request_admin"],
    ],
    filters: [["Pesquisar", "text"]],
  };

  const serviceRequestProps = {
    name: "Pedidos de Serviço",
    url: "requests",
    table: "service_request",
    getDataFunction: (page, searchKey, filters) => getAllRequests(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Tipo de serviço", "service_type", ""],
      ["Data", "requested_date", "date"],
      ["Nome", "name", ""],
      ["Telemóvel", "phone", ""],
      ["Email", "email_address", ""],
      ["Trusted", "trusted", "boolean"],
      ["Assunto", "subject", ""],
      ["Mensagem", "message", ""],
      ["Interessado em", "interested_in", ""],
      ["Completo", "complete", "checkbox", id => handleRequestCompletion(id)],
      ["Advisors", "advisors", "request_admin"],
    ],
    filters: [
      ["Pesquisar", "text"],
      [
        "service_type",
        "selectable",
        [
          "schedule_photo_session",
          "schedule_energy_evaluation",
          "contact_us",
          "house_visit",
          "price_change",
          "delete_property",
        ],
      ],
      ["complete", "button"],
    ],
  };

  const evaluationProps = {
    name: "Avaliações",
    url: "evaluations",
    table: "evaluation",
    getDataFunction: (page, searchKey, filters) => getEvaluations(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Data", "request_date", "date", true],
      ["Tipo", "property_type", ""],
      ["Quando", "time_to_sell", ""],
      ["Concelho", "county", ""],
      ["Nome", "client_name", ""],
      ["Email", "client_email", ""],
      ["Telemóvel", "client_phone", ""],
      ["Casafari URL", "casafari_link", "link"],
      [
        "Origem",
        "origin",
        "selectable",
        (origin, row) => {
          const options = [
            { value: "placeholder", label: "Escolha a origem" },
            {
              label: "Marketing",
              value: "marketing",
            },
            { label: "Contactos pessoais", value: "personal_contacts" },
            {
              label: "Prospeção",
              value: "prospection",
            },
            { label: "Referências", value: "references" },
            { label: "Placas/Outdoor", value: "outdoor" },
          ];
          return (
            <Select
              className={"SelectableCell"}
              classNamePrefix={"SelectableCell"}
              options={options}
              defaultValue={options.find(
                origin
                  ? entry => entry.value === (row.origin || "placeholder")
                  : entry => entry.value === "placeholder",
              )}
              onChange={s => updateEvaluation(row.id, { origin: s.value })}
            />
          );
        },
      ],
      ["Notas", "notes", "textArea", (row, notes) => updateEvaluation(row.id, { notes: notes })],
      ["Follow Up", "follow_up_date", "datepicker", (row, date) => updateEvaluation(row.id, { follow_up_date: date })],
      ["Advisors", "advisors", "request_admin"],
    ],
    filters: [["Pesquisar", "text"]],
  }; //TODO continue evaluation, prepare listing to receive casafari link and go to selectedProperty

  const visitProps = {
    name: "Pedidos de Visita",
    url: "visits",
    table: "visit",
    getDataFunction: (page, searchKey, filters) => getVisits(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Casa", "property_reference", ""],
      ["Buyer", "buyer_name", "lead_name"],
      ["Pedido a", "created_at", "hour"],
      ["Data", "date", "hour"],
      ["Mensagem", "request_message", ""],
      ["Aceite", "accepted", "date"],
      ["Rejeitada", "rejected", "date"],
      ["Cancelada", "cancelled", "date"],
      ["Completa", "done", "boolean"],
      ["Rating", "rating", ""],
      ["Sugestão Vend.", "seller_suggestion", "visit_suggestion"],
      ["Feedback", "feedback", ""],
      ["Ações", "", "visit"],
      ["Lead?", "lead_id", "boolean"],
    ],
    filters: [],
  };

  const offerProps = {
    name: "Ofertas",
    url: "proposals",
    table: "proposal",
    getDataFunction: (page, searchKey, filters) => getProposals(page, searchKey, filters),
    fields: [
      [
        "Histórico",
        "id",
        "route",
        id => {
          return `/proposalHistory/${id}`;
        },
      ],
      [
        "Propriedade",
        "property_id",
        "route",
        property_id => {
          return `/properties/${property_id}`;
        },
      ],
      ["ID User", "user_id", ""],
      ["Nome User", "user_name", ""],
      ["Valor", "value", ""],
      ["Depósito", "down_payment", ""],
      ["Valor empréstimo", "loan_amount", ""],
      ["Aprov. Empréstimo", "bank_pre_approval", ""],
      ["Comentários", "comments", ""],
      ["Contingência de venda", "buyer_property", ""],
      ["Aceite", "accepted", "date"],
      ["Rejeitada", "rejected", "date"],
      ["Vista", "seen", "boolean"],
    ],
    filters: [["accepted", "button"]],
  };

  const processProps = {
    name: "Processos",
    url: "processes",
    table: "process",
    getDataFunction: (page, searchKey, filters) => getAllProcesses(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Vendedor", "seller_name", ""],
      ["Comprador", "buyer_name", ""],
      ["CPCV Vendedor", "cpcv_seller", "date"],
      ["CPCV Comprador", "cpcv_buyer", "date"],
      ["Data CPCV", "cpcv_date", "date"],
      ["Escritura Vendedor", "deed_seller", "date"],
      ["Escritura Comprador", "deed_buyer", "date"],
      ["Data Escritura", "deed_date", "date"],
      ["Escritura assinada", "deed_signed", "boolean"],
      ["Estado", "status", ""],
      [
        "Propriedade",
        "property_id",
        "route",
        property_id => {
          return `/properties/${property_id}`;
        },
      ],
    ],
    filters: [["status", "selectable", ["on_going", "pending", "closed", "sold"]]],
  };

  // const marketingProps = {
  //     name: "Marketing",
  //     url: "marketing",
  //     table: "marketing",
  //     getDataFunction: (page, searchKey, filters) => getNewsletterEmails(page),
  //     fields: [["ID", "id", ""], ["Email", "email", ""], ["Data Registo", "creation_date", "date"]],
  //     children: <>
  //         <br/>
  //         <button onClick={() => {
  //             //copy all emails to clipboard
  //             getNewsletterEmails().then(async (data) => {
  //                 let emails = data?.filter(email => email.includes("@")).map((email) => email.email);
  //                 await navigator.clipboard.writeText(emails.join(", "));
  //                 window.alert("Emails copiados para a área de transferência!");
  //             })
  //         }}>Copiar todos os emails</button>
  //     </>
  // }

  const leadProps = {
    name: "Leads",
    url: "leads",
    table: "lead",
    getDataFunction: (page, searchKey, filters) => getLeads(page, searchKey, filters),
    fields: [
      ["ID", "id", ""],
      ["Nome", "name", "redirect", "/leads/"],
      ["Email", "email", ""],
      ["Telemóvel", "phone", ""],
      ["Nacionalidade", "nationality", ""],
      ["Comentários", "comments", ""],
      ["Origem", "origin", ""],
      ["Registo", "created_at", "date"],
      ["User?", "user_id", "boolean"],
      ["Advisors", "advisors", "request_admin"],
    ],
    filters: [["Pesquisar", "text"]],
  };

  return [
    userProps,
    advisorProps,
    serviceRequestProps,
    evaluationProps,
    visitProps,
    leadProps,
    offerProps,
    processProps,
  ].map((prop, i) => <Route key={i} path={`/${prop.url}`} element={<GenericListing {...prop} />} />);
}

const databaseManager = {
  setEntriesNumber: (tableName, numberOfEntries) => {
    localStorage.setItem(`${tableName}_entries`, numberOfEntries);
  },
  getEntriesNumber: tableName => {
    return Number(localStorage.getItem(`${tableName}_entries`));
  },
  getForbiddenSorts: tableName => {
    //Add here the columns you are not allowed to sort for each table, due to them not being in the database
    const fullList = {
      visit: ["Ações"],
      //If someone updates this, remember the user table is shared between the users and the advisors listings
      user: ["advisors", "Ações", "properties", "service_requests", "evaluations", "leads", "users"],
      service_request: ["advisors", "trusted"],
      process: [],
      proposal: ["user_name"],
      property: ["photos", "user_phone", "user_name", "Status"],
      evaluation: ["advisors", "client_phone", "casafari_link", "notes"],
      lead: ["advisors"],
    };
    return fullList[tableName] || [];
  },
  clearStoredData: () => {
    localStorage.clear();
  },
  postDatabaseSorting: (data, keyNameSort, isReverse = false) => {
    let array;
    console.log(keyNameSort);
    return data;
    if (data?.length > 0) {
      array = data.sort((a, b) => {
        const valueA = a[keyNameSort];
        const valueB = b[keyNameSort];

        if (valueA === null || valueA === undefined) {
          console.log("valueA is null");
          return isReverse ? 1 : -1;
        }
        if (valueB === null || valueB === undefined) {
          console.log("valueB is null");
          return isReverse ? -1 : 1;
        }

        const dateA = Date.parse(valueA);
        const dateB = Date.parse(valueB);

        if (!isNaN(dateA) && !isNaN(dateB)) {
          return isReverse ? dateB - dateA : dateA - dateB;
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return isReverse ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        }

        if (typeof valueA === "number" && typeof valueB === "number") {
          return isReverse ? valueB - valueA : valueA - valueB;
        }

        return isReverse ? 1 : -1;
      });
      return array;
    }
    return data;
  },
};

export function clampText(text, length) {
  if (text?.length <= length) return text;
  return text?.substring(0, length - 1).trim() + "...";
}

export {
  base_url,
  capitalizeOnlyFirstLetter,
  databaseManager,
  dateFormat,
  dynamicRoutes,
  formatCamelCase,
  formatNumber,
  get,
  isEmpty,
  post,
  validateEmail,
};
