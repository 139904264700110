import Modal from "../../Modal";
import { useState } from "react";
import { changeUserEmail, updateUser } from "../../../services/propertyService";

const ChangeUserEmail = ({ handleClose, user }) => {
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!newEmail) {
      alert("Preencha o campo do email.");
      return;
    } else if (!newEmail.match(emailPattern)) {
      alert("Insira um email válido.");
      return;
    } else if (user.email === newEmail) {
      alert("O email inserido é igual ao atual.");
      return;
    }
    setLoading(true);
    try {
      const res = await changeUserEmail(user.id, newEmail);
      alert("Email atualizado com sucesso.");
      handleClose();
    } catch (e) {
      alert(
        "Erro ao atualizar email. " +
          (e.data?.message === "user_already_exists"
            ? "O email inserido já pertence a um utilizador."
            : e.data?.message || e),
      );
      setLoading(false);
      console.error(e);
    }
  };

  return (
    <Modal closeModal={handleClose}>
      <h1>Alterar Email</h1>
      <p>
        Insira o novo email para o utilizador <strong>{user.name}</strong>
      </p>
      <p>
        Email atual: <strong>{user.email}</strong>
      </p>
      <label>Novo email: </label>
      <input
        style={{ margin: "15px 0" }}
        className={"simple-input"}
        type="text"
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
      />
      <button className={"simple-button green"} disabled={loading} onClick={handleUpdatePassword}>
        Atualizar
      </button>
    </Modal>
  );
};

export default ChangeUserEmail;
