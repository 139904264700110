import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { capitalizeOnlyFirstLetter, databaseManager, formatNumber } from "../../services";
import { getAllProperties } from "../../services/propertyService";
import CreateProperty from "../Modals/CreateProperty";
import Pagination from "../Pagination";
import ReactTable from "../ReactTable";
import "./styles.scss";

const Properties = ({ user_id }) => {
  const [page, setPage] = useState(1);
  const [activeSort, setActiveSort] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [pageSize, setPageSize] = useState(databaseManager.getEntriesNumber("property") || 20);
  const [activeFilters, setActiveFilters] = useState({
    pageSize: pageSize,
    status: "active",
    active: activeSort,
    user_id: user_id || null,
  });
  const [openModal, setOpenModal] = useState(null);

  const fetchData = useCallback(
    () => getAllProperties(page, searchInput, activeFilters),
    [page, activeFilters, getAllProperties],
  );
  const {
    isLoading,
    data = {},
    isError,
    error,
    isPreviousData,
    refetch,
  } = useQuery(["properties", page], fetchData, { keepPreviousData: true });
  let noMoreDataEntries = data?.properties && data?.properties?.length < pageSize;

  const dateFormat = (value, numeric = false) => {
    return new Date(value).toLocaleDateString(
      "pt-PT",
      !numeric
        ? {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        : "",
    );
  };

  useEffect(() => {
    refetch();
  }, [activeFilters]);

  const s3Url = process.env.REACT_APP_S3_AWS_URL;

  const styles = {
    basic: {
      textAlign: "center",
      margin: "0 5px",
    },
    basic_capitalize: {
      textAlign: "center",
      margin: "0 5px",
      textTransform: "capitalize",
    },
    no_photo: {
      height: "100px",
      margin: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 700,
      backgroundColor: "lightgray",
    },
  };

  const handleSortFilters = (newFilter = null) => {
    const identifier = `property_sorter`;
    if (!newFilter && activeFilters[identifier]) {
      const newFilters = { ...activeFilters };
      delete newFilters[identifier];
      setActiveFilters(newFilters);
    } else if (newFilter) {
      console.log("NEW FILTER: ", newFilter);
      const newFilters = { ...activeFilters };
      newFilters[identifier] = newFilter;
      setActiveFilters(newFilters);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Ref",
        accessor: "reference",
        Cell: ({ value, row }) => (
          <Link style={styles.basic} to={`/properties/${row.original.id}`}>
            {value}{" "}
          </Link>
        ),
        maxWidth: 50,
      },
      {
        Header: "Foto",
        accessor: "photos",
        // Cell: ({value}) => <button onClick={() => console.log(value)}>Detalhes </button>
        Cell: ({ value }) =>
          value[0] ? (
            <img style={{ height: "100px", margin: "10px" }} src={s3Url + value[0] + "_md"} alt={"house-photo"} />
          ) : (
            <div style={styles.no_photo}> Sem fotos</div>
          ),
      },
      {
        Header: "Tipo",
        accessor: "property_type.type",
        Cell: ({ value }) => <p style={styles.basic_capitalize}>{value}</p>,
      },
      {
        Header: "Morada",
        accessor: "address",
        Cell: ({ value }) => <p style={styles.basic}>{value}</p>,
      },
      {
        Header: "Freguesia",
        accessor: "parish",
        Cell: ({ value }) => <p style={styles.basic}>{value}</p>,
        maxWidth: 100,
      },
      {
        Header: "Cidade",
        accessor: "city",
        Cell: ({ value }) => <p style={styles.basic}>{value}</p>,
        maxWidth: 100,
      },
      {
        Header: "Vendedor",
        accessor: "user_name",
        Cell: ({ value, row }) => (
          <Link to={"/users/" + row.original.user_id} style={styles.basic}>
            {value}
          </Link>
        ),
        maxWidth: 100,
      },
      {
        Header: "Nº Tlm",
        accessor: "user_phone",
        Cell: ({ value }) => <p style={styles.basic}>{value}</p>,
        maxWidth: 100,
      },
      {
        Header: "Registo",
        accessor: "creation_date",
        Cell: ({ value }) => <p style={styles.basic}>{dateFormat(value)}</p>,
      },
      {
        Header: "T#",
        accessor: "number_of_rooms",
        Cell: ({ value }) => <p style={styles.basic}>T{value}</p>,
        maxWidth: 100,
      },
      {
        Header: "Preço",
        accessor: "asking_price",
        Cell: ({ value }) => <p style={styles.basic}>{formatNumber(value, true, true) || "N.D."}</p>,
        //canSort: true,
        sortType: "basic", // Specify the sorting type here
      },
      {
        Header: "Área",
        accessor: "gross_private_area",
        Cell: ({ value }) => <p style={styles.basic}>{value}m2</p>,
        maxWidth: 100,
      },
      /*
        {
            Header: 'Pedido Publicação',
            accessor: 'cmi_requested',
            Cell: ({value}) => <p style={{textAlign: "center"}}>{value && new Date(value).toLocaleDateString('pt-PT', {hour: "numeric", minute: "numeric"})}</p>,
        },
        {
            Header: 'Detalhes',
            Cell: (cell) => (
                <button onClick={() => console.log(cell.row.original)}>Detalhes </button>
            ),
        },
        */
      {
        Header: "Stage",
        accessor: "current_stage",
        Cell: ({ value, row }) => (
          <p style={{ ...styles.basic, textTransform: "capitalize" }}>
            {value.replace("_", " ")}
            {value === "sold" && <small> ({new Date(row.original.sold).toLocaleDateString("pt-PT")})</small>}
          </p>
        ),
      },
      {
        Header: "Stage atualizado a",
        accessor: "stage_last_change",
        Cell: ({ value }) => (
          <p style={styles.basic}>{new Date(value)?.getFullYear() === 1970 ? "N/A" : dateFormat(value)}</p>
        ),
      },
      {
        Header: "Status",
        Cell: ({ cell, value }) => (
          <p
            onClick={() => console.log(cell.row.original.current_stage)}
            style={{
              ...styles.no_photo,
              backgroundColor:
                ["reserved", "published"].includes(cell.row.original.current_stage) ||
                (cell.row.original.current_stage === "sold" &&
                  new Date(cell.row.original.sold).getTime() + 30 * 24 * 60 * 60 * 1000 > new Date().getTime())
                  ? "#0fa80f"
                  : "#e30000",
              height: "20px",
              width: "20px",
              borderRadius: "50px",
            }}
          ></p>
        ),
      },
      {
        Header: "Plano",
        accessor: "premium",
        Cell: ({ value }) => (
          <p style={styles.basic}>
            {["premium", "premium_visits"].includes(value) ? capitalizeOnlyFirstLetter(value) : "Liberty"}
          </p>
        ),
        maxWidth: 100,
      },
    ],
    [data?.properties],
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const x = { ...activeFilters, status: activeFilters.status, user_id: user_id || null, pageSize: pageSize };
      setActiveFilters(x);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const updateProperties = () => {
    refetch();
  };

  const handleSearch = search => {
    setSearchInput(search);
  };

  const handleFilter = (filter, value) => {
    if (filter === "pageSize") setPageSize(value);
    let toForceRefresh = activeFilters;
    toForceRefresh = { ...toForceRefresh, [filter]: value, stage: null };
    setActiveFilters(toForceRefresh);
  };

  useEffect(() => {
    handleFilter("pageSize", pageSize);
    databaseManager.setEntriesNumber("property", pageSize);
  }, [pageSize]);

  const stagesTrans = {
    published: "Publicada",
    reserved: "Reservada",
    sold: "Vendida",
    pending_documents: "Documentos Pendentes",
    pending_verification: "Verificação Pendente",
    pending_cmi: "CMI Pendente",
    all: "Todos os Stages",
  };

  const stages = useMemo(() => {
    switch (activeFilters.status) {
      case "active":
        return ["published", "reserved", "sold"];
      case "inactive":
        return ["pending_documents", "pending_verification", "pending_cmi"];
      default:
        return [];
    }
  }, [activeFilters.status]);

  const handleSelect = choice => {
    setActiveFilters({
      ...activeFilters,
      stage: choice === "all" ? null : choice,
    });
  };

  const tabs = {
    active: "Ativas",
    inactive: "Inativas",
    sold: "Vendidas",
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong. {error.message}</div>;
  }

  return (
    <div className={"Properties"}>
      <h1 onClick={() => console.log(data.properties)}>Propriedades</h1>
      {data.properties?.length > 0 && data.total_count && (
        <Pagination totalCount={data?.total_count} currentPage={page} pageSize={pageSize} onPageChange={setPage} />
      )}
      <div className={"tabs"}>
        {Object.keys(tabs).map((tab, key) => (
          <button
            key={key}
            className={"tab" + (tab === activeFilters.status ? " active" : "")}
            onClick={() => handleFilter("status", tab)}
          >
            {tabs[tab]}
          </button>
        ))}
      </div>
      <input
        className={"simple-input"}
        style={{ marginRight: "20px" }}
        placeholder={"Pesquisar"}
        type={"text"}
        onChange={e => handleSearch(e.target.value)}
      />
      {/*{verifiedOnly && <button className={"simple-button"} style={{marginRight: "20px"}} onClick={() => handleFilter("active")}>{!activeSort ? "Ordenar por ativas" : "Ordenar por data"}</button>}*/}
      {activeFilters.status !== "sold" && (
        <select style={{ marginRight: "20px" }} onChange={e => handleSelect(String(e.target.value))}>
          {["all", ...stages].map((stage, key) => (
            <option
              key={"stage_" + key}
              style={{ textTransform: "capitalize" }}
              value={stage}
              selected={stage === "all" ? !activeFilters.stage : stage === activeFilters.stage}
            >
              {stagesTrans[stage]}
            </option>
          ))}
        </select>
      )}

      {activeFilters?.pageSize && (
        <select className={"simple-input"} style={{ margin: "0 20px" }} onChange={e => setPageSize(e.target.value)}>
          {[10, 20, 50, 100, 200, 500].map(size => (
            <option
              key={"page_size_" + size}
              value={size}
              selected={activeFilters.pageSize === size}
            >{`${size}`}</option>
          ))}
        </select>
      )}
      <button className={"simple-button"} style={{ margin: "0 3px" }} onClick={() => setOpenModal("createProperty")}>
        Criar propriedade
      </button>

      {openModal === "createProperty" && <CreateProperty handleClose={() => setOpenModal("")} />}

      <ReactTable
        columns={columns}
        data={data.properties}
        updateMyData={updateProperties}
        type={"property"}
        activeFilters={activeFilters}
        handleSortFilters={handleSortFilters}
      />
      {data.properties?.length > 0 && data?.total_count && (
        <Pagination totalCount={data.total_count} currentPage={page} pageSize={pageSize} onPageChange={setPage} />
      )}

      {!data?.total_count && (
        <>
          <button
            className={"simple-button"}
            style={{ margin: "0 3px" }}
            disabled={isPreviousData || page === 1}
            onClick={() => setPage(prev => prev - 1)}
          >
            Previous
          </button>
          <button
            className={"simple-button"}
            style={{ margin: "0 3px" }}
            disabled={isPreviousData || noMoreDataEntries}
            onClick={() => setPage(prev => prev + 1)}
          >
            Next
          </button>
        </>
      )}
    </div>
  );
};

export default Properties;
