import { get, post } from "./index";
import axios from "axios";

//TODO get these non-property-related functions into their respective services

//marketing

async function getNewsletterEmails(page = 1) {
  let res = await get("/backoffice/newsletterEmails", { page });
  return res.data || [];
}

// Processes

async function getAllProcesses(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/listProcesses", { searchKey, page, ...activeFilters });
  return res.data || [];
}

async function getProcess(id) {
  let res = await get(`/backoffice/getProcess/${id}`);
  return res.data;
}

async function uploadProcessDocument(processId, data) {
  return await post(`/backoffice/uploadProcessDocument/${processId}`, data);
}

async function removeProcessDocument(processId, docName) {
  return await post(`/backoffice/removeProcessDocument/${processId}`, { doc: docName });
}

async function saveProcess(processId, process) {
  return await post(`/backoffice/saveProcess/${processId}`, process);
}

async function confirmSale(processId, process) {
  return await post(`/backoffice/confirmSale/${processId}`, process);
}

async function turnProcessInactive(processId) {
  return await post(`/backoffice/turnProcessInactive/${processId}`);
}

// User

async function getAllUsers(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/listUsers", { searchKey, page, ...activeFilters });
  return res.data || [];
}

async function updateUserStatus(userId, status) {
  return await post(`/backoffice/updateUserStatus/${userId}`, { status });
}

async function getUser(userId) {
  let res = await get(`/backoffice/getUser/${userId}`);
  return res.data;
}

async function updateUser(userId, user) {
  return await post(`/backoffice/updateUser/${userId}`, user);
}

async function recoverPasswordBO(credential) {
  return await post("/user/recoverPassword", { credential });
}

async function changeUserEmail(userId, email) {
  return await post(`/backoffice/changeUserEmail/${userId}`, { email });
}

async function deleteUser(userId) {
  return await post(`/backoffice/deleteUser/${userId}`);
}

async function reactivateUser(userId) {
  return await post(`/backoffice/reactivateUser/${userId}`);
}

export async function getFinancialProfiles(userId) {
  let res = await get(`/backoffice/users/${userId}/financialProfiles`);
  return res.data || [];
}

export async function createFinancialProfile(userId, form) {
  return await post(`/backoffice/users/${userId}/financialProfiles`, form);
}

export async function updateFinancialProfile(userId, profileId, form) {
  return await post(`/backoffice/users/${userId}/financialProfiles/${profileId}`, form);
}

// Requests

async function getAllRequests(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/listRequests", { searchKey: searchKey, page, ...activeFilters });
  return res.data || [];
}

async function handleRequestCompletion(id) {
  return await post(`/backoffice/handleRequestCompletion/${id}`);
}

// Visits

async function getVisits(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/getVisits", { searchKey, page, ...activeFilters });
  return res.data || [];
}

async function acceptVisit(visitId, selectedDate) {
  return await post(`/backoffice/acceptVisit/${visitId}`, { date: selectedDate });
}

async function rejectVisit(visitId) {
  return await post(`/backoffice/rejectVisit/${visitId}`);
}

async function createVisit(userId, propertyId, selectedDate, lead_id = null) {
  return await post(`/backoffice/createVisit`, { userId, propertyId, date: selectedDate, lead_id });
}

async function cancelVisitRequest(visitId) {
  return await post(`/backoffice/visit/${visitId}/cancel`);
}

// Proposals

async function getProposals(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/getProposals", { searchKey: searchKey, page, ...activeFilters });
  return res.data || [];
}

async function getProposalHistory(id) {
  let res = await get(`/backoffice/getProposalHistory/${id}`);
  return res.data || [];
}

// Evaluations

async function getEvaluations(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/getEvaluations", { searchKey: searchKey, page, ...activeFilters });
  return res.data || [];
}

async function updateEvaluation(evaluationId, evaluation) {
  return await post(`/backoffice/updateEvaluation/${evaluationId}`, evaluation);
}

// Properties

async function getAllProperties(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/listProperties", { searchKey, page, ...activeFilters });
  return res.data || [];
}

async function getCompleteProperty(id) {
  let res = await get(`/backoffice/completeProperty/${id}`);
  return res.data;
}

async function getPropertyPhotosZip(id) {
  return await get(`/backoffice/property/${id}/photosZip`, null, "blob");
}

async function updateProperty(propertyId, property) {
  return await post(`/backoffice/update/${propertyId}`, property);
}

async function verifyProperty(propertyId) {
  return await post(`/backoffice/verifyProperty/${propertyId}`);
}

async function deleteProperty(propertyId) {
  return await post(`/backoffice/deleteProperty/${propertyId}`);
}

async function archiveProperty(propertyId) {
  return await post(`/backoffice/archiveProperty/${propertyId}`);
}

async function preparePropertyCMI(propertyId) {
  return await post(`/backoffice/preparePropertyCMI/${propertyId}`);
}

async function getPropertyConditions() {
  let res = await get("/backoffice/listPropertyConditions");
  return res.data || [];
}

async function getPropertyTypes() {
  let res = await get("/backoffice/listPropertyTypes");
  return res.data || [];
}

async function getPropertyFeatures() {
  let res = await get("/backoffice/listPropertyFeatures");
  return res.data || [];
}

async function createProperty(property, userId) {
  return await post("/backoffice/createProperty", { property, userId });
}
// Documents

async function uploadDocument(propertyId, data) {
  return await post(`/backoffice/uploadDocument/${propertyId}`, data);
}

async function removeDocument(propertyId, docName) {
  return await post(`/backoffice/removeDocument/${propertyId}`, { doc: docName });
}

async function validateDocument(propertyId, docName, validation) {
  return await post(`/backoffice/validateDocument/${propertyId}`, { doc: docName, validation });
}

// Media

export async function uploadPhoto(propertyId, data) {
  return await post(`/backoffice/uploadPhoto/${propertyId}`, data);
}

export async function getS3SignedUrl(propertyId, type) {
  return (await get(`/backoffice/getS3SignedUrl/${propertyId}`, { type })).data;
}

export async function uploadVideo(s3Url, video, videoId, propertyId) {
  await axios.put(s3Url, video, {
    headers: {
      "Content-type": video.type,
      "Access-Control-Allow-Origin": "*",
    },
  });
  await addVideo(propertyId, videoId);
}

export async function addVideo(propertyId, videoId) {
  return await post("/backoffice/addPropertyVideo/" + propertyId + "/" + videoId);
}

export async function deletePhoto(propertyId, photoId) {
  return await post(`/backoffice/deletePhoto/${propertyId}/${photoId}`);
}

async function removeVideo(propertyId, type) {
  return await post(`/backoffice/removeVideo/${propertyId}`, { type });
}

async function upload3DVideo(propertyId, link) {
  return await post(`/backoffice/upload3DVideo/${propertyId}`, { link });
}

//FAQS

async function getAllFaqs() {
  let res = await get("/backoffice/getAllFaq");
  return res.data;
}

async function addFaq(faq) {
  return await post(`/backoffice/addFaq`, faq);
}

async function removeFaq(faqId) {
  return await post(`/backoffice/removeFaq/${faqId}`);
}

// Lead

async function addLead(lead) {
  return await post("/backoffice/addLead", lead);
}

async function getLead(id) {
  return await get(`/backoffice/getLead/${id}`);
}

async function getLeads(page = 1, searchKey, activeFilters) {
  let res = await get("/backoffice/getLeads", { searchKey, page, ...activeFilters });
  return res.data || [];
}

async function deleteLead(id) {
  return await post(`/backoffice/deleteLead/${id}`);
}

async function verifyUniqueEmail(email) {
  return await get("/backoffice/validateUniqueEmail", { email });
}

async function updateLead(id, lead) {
  return await post(`/backoffice/updateLead/${id}`, lead);
}

async function getVisit(id) {
  return await get(`/backoffice/getVisit/${id}`);
}

async function markVisitAsCompleted(visit_id) {
  return await post(`/backoffice/complete/${visit_id}`);
}

async function getPropertyProcesses(property_id) {
  return await get("/backoffice/getPropertyProcesses/" + property_id);
}

async function getStageHistory(property_id) {
  return await get(`/backoffice/getStageHistory/${property_id}`);
}

async function getPropertyIdFromProposalId(proposal_id) {
  return (await get(`/backoffice/getPropertyIdFromProposalId/${proposal_id}`)).data;
}

async function addPropertyClosingLog(log) {
  return await post(`/backoffice/addClosingLog`, log);
}

async function updatePropertyClosingLog(property_id, log) {
  return await post(`/backoffice/updateClosingLog/${property_id}`, log);
}

async function getPropertyClosingLog(property_id) {
  return await get(`/backoffice/getPropertyClosingLog/${property_id}`);
}

export {
  getAllProperties,
  getCompleteProperty,
  getPropertyPhotosZip,
  uploadDocument,
  updateProperty,
  verifyProperty,
  deleteProperty,
  preparePropertyCMI,
  getPropertyConditions,
  getPropertyTypes,
  getPropertyFeatures,
  createProperty,
  removeDocument,
  validateDocument,
  removeVideo,
  upload3DVideo,
  getAllUsers,
  getAllRequests,
  getEvaluations,
  getAllProcesses,
  getProcess,
  uploadProcessDocument,
  removeProcessDocument,
  saveProcess,
  confirmSale,
  turnProcessInactive,
  getVisits,
  acceptVisit,
  rejectVisit,
  createVisit,
  cancelVisitRequest,
  getProposals,
  getProposalHistory,
  getAllFaqs,
  addFaq,
  removeFaq,
  getNewsletterEmails,
  addLead,
  getLead,
  getLeads,
  verifyUniqueEmail,
  updateLead,
  getVisit,
  markVisitAsCompleted,
  deleteLead,
  archiveProperty,
  getPropertyProcesses,
  getStageHistory,
  getPropertyIdFromProposalId,
  addPropertyClosingLog,
  updateUserStatus,
  getUser,
  updateUser,
  recoverPasswordBO,
  changeUserEmail,
  deleteUser,
  reactivateUser,
  updatePropertyClosingLog,
  getPropertyClosingLog,
  handleRequestCompletion,
  updateEvaluation,
  // updateFinancialProfile
};
