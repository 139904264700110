import "./styles.scss";

const Modal = props => {
  return (
    <div id="myModal" className="ModalWrapper" onClick={props.onlyCross ? () => {} : props.closeModal}>
      <div className="Modal" onClick={e => e.stopPropagation()}>
        <span className="close" onClick={props.closeModal}>
          &times;
        </span>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
