import React, { useState, useEffect, useRef } from "react";
import { Button, Collapse, Flex, Spin } from "antd";
import { toast } from "react-toastify";
import FinancialProfile from "./FinancialProfile";
import ConfirmationModal from "./ConfirmationModal";
import {
  getFinancialProfiles,
  updateFinancialProfile,
  createFinancialProfile,
  getAllProperties,
} from "../../../services/propertyService";
import { capitalizeOnlyFirstLetter } from "../../../services/index";

const fields = [
  "id",
  "user_id",

  "created_at",
  "buyer",
  "type",
  "properties",

  "name",
  "address",
  "fiscal_number",
  "occupation",
  "marital_status",
  "marital_property_regime",

  "id_doc_type",
  "id_number",
  "id_doc_exp_date",
  "id_doc_front_file",
  "id_doc_back_file",

  "spouse_name",
  "spouse_fiscal_number",
  "spouse_occupation",
  "spouse_id_doc_type",
  "spouse_id_number",
  "spouse_id_doc_exp_date",
  "spouse_id_doc_front_file",
  "spouse_id_doc_back_file",

  "commercial_certificate_number",
  "commercial_certificate_file",

  "manager_fiscal_number",
  "manager_id_number",
  "manager_id_doc_exp_date",
  "manager_id_doc_front_file",
  "manager_id_doc_back_file",
];

const FinancialProfiles = ({ userId }) => {
  const [financialProfiles, setFinancialProfiles] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const [nextKey, setNextKey] = useState(null);
  const [defaultActiveKey, setDefaultActiveKey] = useState(null);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const fetchData = async () => {
    try {
      const { properties } = await getAllProperties(1, "", { user_id: userId });
      setAllProperties(properties);
      const financialProfilesData = await getFinancialProfiles(userId).then(res =>
        res
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map(financialProfile => ({
            key: financialProfile.id,
            label: `${financialProfile.name}, ${financialProfile.buyer === 1 ? "Comprador" : "Vendedor"} ${capitalizeOnlyFirstLetter(financialProfile.type === "individual" ? financialProfile.type : "comercial")}, ${new Date(financialProfile.created_at).toLocaleDateString()}`,
            children: (
              <FinancialProfile
                id={financialProfile.id}
                profile={financialProfile}
                onSave={handleSave}
                handleProfileChanges={handleProfileChanges}
                properties={properties}
                ref={childRef}
              />
            ),
            style: {
              marginBottom: 24,
              border: "1px solid #d9d9d9",
              borderRadius: 8,
            },
          })),
      );

      setFinancialProfiles(financialProfilesData);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const childRef = useRef();
  const callChildFunction = () => {
    childRef.current?.handleConfirmCancel();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (formData, profileId, isNewProfile) => {
    try {
      if (isNewProfile) {
        let res = await createFinancialProfile(userId, formData);
        profileId = res.data.profileId;
      } else {
        await updateFinancialProfile(userId, profileId, formData);
      }
      toast.info("Perfil atualizado com sucesso", {
        position: "top-right",
        autoClose: 2000,
      });

      await fetchData();
    } catch (e) {
      toast.error("Erro ao atualizar perfil. " + (e.data?.message || e), {
        position: "top-right",
        autoClose: 2000,
      });
      console.error(e);
    } finally {
      setKey(prevKey => prevKey + 1);
      setDefaultActiveKey(profileId);
      setChangesMade(false);
    }
  };

  const handleCreate = () => {
    let financialProfile = fields.reduce((obj, item) => {
      if (item === "user_id") {
        obj[item] = userId;
      } else {
        obj[item] = null;
      }

      return obj;
    }, {});

    const newId = Math.max(...financialProfiles.map(item => item.key), 0) + 1;
    const newFinancialProfile = {
      key: newId,
      label: `Novo Perfil`,
      children: (
        <FinancialProfile
          id={newId}
          profile={financialProfile}
          isNewProfile={true}
          onSave={handleSave}
          handleProfileChanges={handleProfileChanges}
          properties={allProperties}
          onRemove={handleRemove}
          ref={childRef}
        />
      ),
      style: {
        marginBottom: 24,
        border: "1px solid #d9d9d9",
        borderRadius: 8,
      },
    };

    setFinancialProfiles([newFinancialProfile, ...financialProfiles]);
    setActiveKey(newId);
  };

  const handleRemove = key => {
    setFinancialProfiles(financialProfiles.filter(profile => profile.key !== key));
  };

  const handleChange = key => {
    setNextKey(key);
    if (changesMade) {
      setIsCancelModalVisible(true);
    } else {
      setIsCancelModalVisible(false);
      setActiveKey(key);
      callChildFunction();
      setNextKey(null);
    }
  };

  const handleProfileChanges = value => {
    setChangesMade(value);
  };

  const handleCollapse = () => {
    setIsCancelModalVisible(false);
    setChangesMade(false);
    setActiveKey(nextKey);
    setNextKey(null);
    callChildFunction();
  };

  return (
    <>
      <Flex gap="small" justify="start" style={{ marginBottom: 20 }}>
        <Button type="primary" onClick={handleCreate}>
          Criar novo
        </Button>
      </Flex>

      {loading ? (
        <Spin />
      ) : (
        <MemoCollapse
          financialProfiles={financialProfiles}
          key={key}
          activeKey={activeKey}
          defaultActiveKey={defaultActiveKey}
          onChange={handleChange}
        />
      )}
      <ConfirmationModal
        message="Cancelar alterações?"
        isOpen={isCancelModalVisible}
        onConfirm={handleCollapse}
        onCancel={() => setIsCancelModalVisible(false)}
      />
    </>
  );
};

const MemoCollapse = React.memo(({ financialProfiles, key, activeKey, defaultActiveKey, onChange }) => {
  return (
    <Collapse
      key={key}
      accordion
      ghost
      size="large"
      items={financialProfiles}
      onChange={onChange}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      style={{
        backgroundColor: "white",
      }}
    />
  );
});

export default FinancialProfiles;
