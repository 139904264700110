import { useEffect, useState } from "react";
import Modal from "../../Modal";
import {
  addPropertyClosingLog,
  getPropertyClosingLog,
  updatePropertyClosingLog,
} from "../../../services/propertyService";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptPt from "date-fns/locale/pt";
import { useAuth } from "../../../services/auth";
import { toast } from "react-toastify";
import styles from "./styles.scss";
import { format } from "date-fns";

registerLocale("pt-PT", ptPt);
setDefaultLocale("pt-PT");

const PropertyClosingLog = ({ handleClose, property = null, save }) => {
  const modalStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const divStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
  };
  const labelStyle = {
    marginBottom: "5px",
    fontWeight: "bold",
    fontSize: "12px",
    marginRight: "auto",
  };

  const inputStyle = {
    width: "250px",
    padding: "5px",
    marginBottom: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  };

  const [formData, setFormData] = useState(
    property?.closing_log
      ? { ...property?.closing_log }
      : property
        ? {
            address: property.address,
            parish: property.parish,
            city: property.city,
            sold_by: "listoo",
            type: property.typeOf,
            listed_for: property.asking_price,
            area: property.gross_private_area,
            typology: `T${property.number_of_rooms - 1}`,
            property_id: property.id,
            sold_date: format(new Date(), "yyyy-MM-dd"),
            sold_for: property.sold_for,
          }
        : {},
  );
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    if (consent) setConsent(false);
  }, [handleClose]);

  const handleFieldChange = e => {
    const { name, value } = e.target;
    console.log(name, ": ", value);
    setFormData({ ...formData, [name]: value });
  };

  const handleDateSelection = sold_date => {
    const date = format(sold_date, "yyyy-MM-dd");
    if (!date) return;
    setFormData({ ...formData, sold_date: date });
  };

  const handleSubmit = async () => {
    (property?.closing_log && formData.id
      ? updatePropertyClosingLog(formData?.id, formData)
      : addPropertyClosingLog(formData)
    )
      .then(async res => {
        if (res) {
          toast.info(
            property?.closing_log ? "Log de fecho editado com sucesso!" : "Log de fecho adicionado com sucesso!",
            {
              position: "top-right",
              hideAfter: 2,
            },
          );
          const { data } = await getPropertyClosingLog(property?.id);
          await setFormData({ ...data });
          property.closing_log = data;
          save();
          handleClose();
        }
      })
      .catch(err => {
        if (err.data.stack.includes("already_exists")) {
          toast.error("Já existe um log de fecho para esta propriedade!", {
            position: "top-right",
            hideAfter: 2,
          });
          handleClose();
        } else
          toast.error("Erro ao adicionar log de fecho!", {
            position: "top-right",
            hideAfter: 2,
          });
        console.log(err);
      });
  };

  return (
    <Modal closeModal={handleClose}>
      <h3 style={{ marginBottom: "0" }}>
        {property?.closing_log ? "Já tinha um closing log" : "Desativou o anúncio da propriedade"}
      </h3>
      <span
        style={{
          margin: "5px 0 30px 0",
          fontSize: "14px",
        }}
      >
        {property?.closing_log ? "Pretende editar alguma informação?" : "Pretende guardar alguma informação?"}
      </span>
      <div style={modalStyle}>
        {/*TODO Make this a map*/}

        {/* Dropdown for "Vendido por" */}
        <div style={divStyle}>
          <label onClick={() => console.log(formData, consent)} style={labelStyle}>
            Vendido por:
          </label>
          <select
            style={{ ...inputStyle, width: "262px", paddingLeft: "0" }}
            name="sold_by"
            value={formData.sold_by || ""}
            onChange={handleFieldChange}
          >
            <option value={"listoo"}>Vendido por Listoo</option>
            <option value={"owner"}>Vendido pelo proprietário</option>
            <option value={"other_platform"}>Vendido por outra plataforma</option>
          </select>
        </div>
        {/* Data de venda */}
        <div style={divStyle}>
          <label style={labelStyle}>Data de venda:</label>
          <DatePicker
            selected={new Date(formData?.sold_date)}
            name={"sold_date"}
            id={"sold_date"}
            onChange={date => handleDateSelection(date)}
            locale="pt-PT"
            timeIntervals={10}
            //minDate={new Date()}
            placeholderText="Escolher data"
            dateFormat="dd/MM/yyyy"
          />
        </div>

        {/* Address */}
        <div style={divStyle}>
          <label style={labelStyle}>Morada:</label>
          <input
            style={inputStyle}
            type="text"
            name="address"
            value={formData.address || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* Parish */}
        <div style={divStyle}>
          <label style={labelStyle}>Freguesia:</label>
          <input
            style={inputStyle}
            type="text"
            name="parish"
            value={formData.parish || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* City */}
        <div style={divStyle}>
          <label style={labelStyle}>Cidade:</label>
          <input style={inputStyle} type="text" name="city" value={formData.city || ""} onChange={handleFieldChange} />
        </div>

        {/* Area */}
        <div style={divStyle}>
          <label style={labelStyle}>Area (m2):</label>
          <input style={inputStyle} type="text" name="area" value={formData.area || ""} onChange={handleFieldChange} />
        </div>

        {/* Buyer's Name */}
        <div style={divStyle}>
          <label style={labelStyle}>Comprador:</label>
          <input
            style={inputStyle}
            type="text"
            name="buyers_name"
            value={formData?.buyers_name || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* Comments */}
        <div style={divStyle}>
          <label style={labelStyle}>Comentários:</label>
          <textarea
            style={{ ...inputStyle, resize: "none" }}
            name="comments"
            rows={3}
            maxLength={140}
            value={formData.comments || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* Listed For */}
        <div style={divStyle}>
          <label style={labelStyle}>Preço original (€):</label>
          <input
            style={inputStyle}
            type="text"
            name="listed_for"
            value={formData.listed_for || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* Sold For */}
        <div style={divStyle}>
          <label style={labelStyle}>Vendido por (€):</label>
          <input
            style={inputStyle}
            type="text"
            name="sold_for"
            value={formData.sold_for || ""}
            onChange={handleFieldChange}
          />
        </div>

        {/* Type */}
        <div style={divStyle}>
          <label style={labelStyle}>Tipo:</label>
          <input style={inputStyle} type="text" name="type" value={formData.type || ""} onChange={handleFieldChange} />
        </div>

        {/* Typology */}
        <div style={divStyle}>
          <label style={labelStyle}>Tipologia:</label>
          <input
            style={inputStyle}
            type="text"
            name="typology"
            value={formData.typology || ""}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        <input
          style={{ marginTop: "10px" }}
          type={"checkbox"}
          checked={consent === true}
          onChange={() => setConsent(!consent)}
        />
        <label style={{ fontSize: "12px", color: "darkred" }}>
          Declaro que confirmei que esta recolha de dados é permitida
        </label>
      </div>

      <button
        disabled={!consent}
        className={"simple-button mt-1"}
        style={{ cursor: consent ? "pointer" : "not-allowed" }}
        onClick={handleSubmit}
      >
        Submeter
      </button>
    </Modal>
  );
};

export default PropertyClosingLog;
