import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { getCountries, registerUser } from "../../../services/user";
import Modal from "../../Modal";
import "./styles.scss";

const CreateUser = ({ handleClose }) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("pt");

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries().then(res => {
      console.log(res);
      setCountries(res);
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const user = {
        firstName,
        lastName,
        email,
        phone,
        dialCode,
      };
      await registerUser(user);

      toast.info("Utilizador registado com sucesso", {
        position: "top-right",
        autoClose: 2000,
      });
      handleClose();
    } catch (e) {
      toast.error("Erro ao registar utilizador. " + (e.data?.message || e), {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  };

  return (
    <Modal closeModal={handleClose}>
      <div className={"form-wrapper"}>
        <h1>Criar Utilizador</h1>
        <form onSubmit={handleSubmit}>
          <FormInput label="Primeiro nome" field={firstName} setField={setFirstName} type="text" required />

          <FormInput label="Último nome" field={lastName} setField={setLastName} type="text" required />

          <FormInput label="Email" field={email} setField={setEmail} type="text" fullWidth required />

          {countries && (
            <FormInput
              label="Indicativo"
              customInput={
                <Select
                  onChange={o => setDialCode(o.value)}
                  options={countries.map(country => ({
                    value: country.iso2.toLowerCase(),
                    label: country.name_pt + " | +" + country.dial_code,
                  }))}
                  // required
                  defaultValue={{ value: "pt", label: "Portugal | +351" }}
                />
              }
            />
          )}

          <FormInput label="Telefone" field={phone} setField={setPhone} type="text" required />

          <button className={"simple-button green"} disabled={loading} type="submit">
            Criar
          </button>
        </form>
      </div>
    </Modal>
  );
};

const FormInput = ({ required = false, label, customInput, field, setField, type, fullWidth }) => {
  return (
    <div className={"form-field " + (fullWidth ? "full-width" : "")}>
      <label>{label + ":"}</label>
      {customInput ? (
        customInput
      ) : (
        <input
          className={"simple-input"}
          type={type}
          value={field}
          onChange={e => setField(e.target.value)}
          required={required}
        />
      )}
    </div>
  );
};

export default CreateUser;
