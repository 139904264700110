import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import dayjs from "dayjs";
import {
    Button,
    Form,
    Input,
    Radio,
    Space,
    Select,
    Collapse,
    Divider,
    Flex,
    Typography,
    Descriptions,
    DatePicker,
    Upload,
    Row,
    Spin,
    Col,
    Modal
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import {
    getFinancialProfiles,
    updateFinancialProfile,
    createFinancialProfile,
    getAllProperties
} from "../../../../services/propertyService";
import { capitalizeOnlyFirstLetter } from "../../../../services/index";

const s3Url = process.env.REACT_APP_S3_AWS_URL;

const ConfirmationModal = ({ message, onConfirm, onCancel, isOpen, collapse }) => {
    return (
        <Modal
            title={message}
            centered
            open={isOpen}
            onOk={onConfirm}
            onCancel={onCancel}
        >
        </Modal>
    );
};

export default ConfirmationModal;