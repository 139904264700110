import { useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  createProperty,
  getAllUsers,
  getPropertyConditions,
  getPropertyFeatures,
  getPropertyTypes,
} from "../../../services/propertyService";
import Modal from "../../Modal";
import "./styles.scss";

const CreateProperty = ({ handleClose }) => {
  const navigate = useNavigate();
  const googleApiKey = process.env.REACT_APP_API_GOOGLE;

  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [users, setUsers] = useState([]);

  const [propertyCondition, setPropertyCondition] = useState(null);
  const [conditions, setConditions] = useState([]);

  const [propertyType, setType] = useState(null);
  const [types, setTypes] = useState([]);

  const [propertyFeatures, setPropertyFeatures] = useState(null);
  const [features, setFeatures] = useState([]);

  const [address, setAddress] = useState(null);
  const [addressNumber, setAddressNumber] = useState(null);
  const [city, setCity] = useState(null);
  const [county, setCounty] = useState(null);
  const [parish, setParish] = useState(null);
  const [postalCode, setPostalCode] = useState(null);

  const [locationLat, setLocationLat] = useState(null);
  const [locationLng, setLocationLng] = useState(null);

  const [bedrooms, setBedrooms] = useState(null);
  const [bathrooms, setBathrooms] = useState(null);
  const [floor, setFloor] = useState(null);
  const [constructionYear, setConstructionYear] = useState(null);
  const [totalArea, setTotalArea] = useState(null);

  useEffect(() => {
    //fetch users
    getAllUsers().then(res => {
      setUsers(res);
    });

    // fetch property conditions
    getPropertyConditions().then(res => {
      setConditions(res);
    });

    // fetch property types
    getPropertyTypes().then(res => {
      setTypes(res);
    });

    // fetch property features
    getPropertyFeatures().then(res => {
      setFeatures(res);
    });
  }, []);

  const handleAddressChange = address => {
    try {
      geocodeByAddress(address).then(res => {
        setAddress(address.split(",")[0]);
        res[0].address_components.forEach(comp => {
          if (comp.types[0] === "route") setAddress(comp.long_name);
          if (comp.types[0] === "street_number") setAddressNumber(comp.long_name);
          if (comp.types[0] === "locality") setCity(comp.long_name);
          if (comp.types[0] === "administrative_area_level_3") setParish(comp.long_name);
          if (comp.types[0] === "administrative_area_level_2") setCounty(comp.long_name);
          if (comp.types[0] === "postal_code") setPostalCode(comp.long_name);
        });

        setLocationLat(res[0].geometry.location.lat());
        setLocationLng(res[0].geometry.location.lng());
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = event => {
    setLoading(true);
    try {
      event.preventDefault();
      const property = {
        address,
        streetNumber: addressNumber,
        city,
        parish,
        county,
        postalCode,
        propertySubtype: propertyType,
        bathrooms,
        bedrooms,
        floor,
        propertyCondition,
        yearOfConstruction: constructionYear,
        locationLat,
        locationLng,
        grossPrivateArea: totalArea,
        propertyFeatures,
      };
      createProperty(property, userId).then(res => {
        if (res.success) {
          toast.info("Propriedade criada com sucesso", {
            position: "top-right",
            autoClose: 2000,
          });
          navigate(`/properties/${res.data.id}`);
        }
      });
    } catch (e) {
      toast.error("Erro ao criar propriedade. " + (e.data?.message || e), {
        position: "top-right",
        autoClose: 2000,
      });
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <Modal closeModal={handleClose}>
      <div className={"form-wrapper"}>
        <h1>Criar Propriedade</h1>
        <form onSubmit={handleSubmit}>
          {users && (
            <FormInput
              label="User"
              customInput={
                <Select
                  onChange={o => setUserId(o.value)}
                  options={users.map(user => ({
                    value: user.id,
                    label: user.name + " | " + user.email,
                  }))}
                  required
                />
              }
              fullWidth
            />
          )}

          <FormInput
            label="Address"
            customInput={
              <GooglePlacesAutocomplete
                apiKey={googleApiKey}
                apiOptions={{ region: "pt" }}
                minLengthAutocomplete={2}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["pt"],
                  },
                  types: ["address"],
                }}
                selectProps={{
                  address,
                  onChange: o => handleAddressChange(o.value.description),
                }}
                required
              />
            }
            fullWidth
          />
          <FormInput label="Adress Number" field={addressNumber} setField={setAddressNumber} type="number" required />

          <FormInput label="County" field={county} setField={setCounty} type="text" />

          <FormInput label="Parish" field={parish} setField={setParish} type="text" />

          <FormInput label="City" field={city} setField={setCity} type="text" />

          {types && (
            <FormInput
              label="Type"
              customInput={
                <Select
                  onChange={o => setType(o.value)}
                  options={types.map(type => ({
                    value: type.subtype,
                    label: type.subtype,
                  }))}
                  required
                />
              }
            />
          )}

          {conditions && (
            <FormInput
              label="Condition"
              customInput={
                <Select
                  onChange={o => setPropertyCondition(o.value)}
                  options={conditions.map(condition => ({
                    value: condition.type,
                    label: condition.type,
                  }))}
                  required
                />
              }
            />
          )}

          <FormInput label="Bedrooms" field={bedrooms} setField={setBedrooms} type="number" required={true} />

          <FormInput label="Bathrooms" field={bathrooms} setField={setBathrooms} type="number" />

          <FormInput label="Floor" field={floor} setField={setFloor} type="number" />

          <FormInput label="Construction year" field={constructionYear} setField={setConstructionYear} type="number" />

          <FormInput label="Total area" field={totalArea} setField={setTotalArea} type="number" required />

          {features && (
            <FormInput
              label="Features"
              customInput={
                <Select
                  isMulti
                  onChange={o => setPropertyFeatures(o.map(o => o.value))}
                  options={features.map(feature => ({
                    value: feature.feature_type,
                    label: feature.feature_type,
                  }))}
                />
              }
              fullWidth
            />
          )}

          <button className={"simple-button green"} disabled={loading} type="submit">
            Criar
          </button>
        </form>
      </div>
    </Modal>
  );
};

const FormInput = ({ required = false, label, customInput, field, setField, type, fullWidth }) => {
  return (
    <div className={"form-field " + (fullWidth ? "full-width" : "")}>
      <label>{label + ":"}</label>
      {customInput ? (
        customInput
      ) : (
        <input
          className={"simple-input"}
          type={type}
          value={field}
          onChange={e => setField(e.target.value)}
          required={required}
        />
      )}
    </div>
  );
};

export default CreateProperty;
