import Modal from "../../Modal";
import { useState } from "react";

const MissingDocNotifier = ({ handleClose, user_info, missingDocs }) => {
  const [formData, setFormData] = useState({
    email: user_info?.user_email,
    message: "",
  });

  return (
    <Modal closeModal={handleClose}>
      <h3>Contactar {user_info.user_name}</h3>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <span style={{ fontWeight: "600" }}>Documentos em falta:</span>
        {missingDocs?.map(doc => {
          return <p style={{ margin: "1px 0" }}>➤ {doc}</p>;
        })}
      </div>
      <textarea
        style={{ resize: "none", margin: "auto 0 0 0" }}
        rows={10}
        name="message"
        value={formData.message}
        onChange={e => setFormData({ ...formData, message: e.target.value })}
      />
      <button style={{ width: "200px", margin: "auto auto 0 auto" }} onClick={() => {}}>
        Enviar (Não funciona, à espera de template)
      </button>
    </Modal>
  );
};

export default MissingDocNotifier;
