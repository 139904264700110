import { capitalizeOnlyFirstLetter, dateFormat, isEmpty } from "../../../services";
import { Link, useParams } from "react-router-dom";
import { getFinancialProfiles, updateFinancialProfile, createFinancialProfile, getAllProperties } from "../../../services/propertyService";
import { updateProperty } from "../../../services/propertyService";
import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Select
} from "antd";


const UserTab = ({ property }) => {
  const s3Url = process.env.REACT_APP_S3_AWS_URL;

  const [profileId, setProfileId] = useState(null);
  const [saved, setSaved] = useState(false);
  const [financialProfiles, setFinancialProfiles] = useState(null);
  const [showSelect, setShowSelect] = useState(false);

  const fetchProfiles = async () => {
    try {
      const result = await getFinancialProfiles(property.user_id);
      return result;
    } catch (error) {
      // Handle errors if needed
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchDataAndSetData = async () => {
      const data = await fetchProfiles();
      setFinancialProfiles(data);
    };

    fetchDataAndSetData();
  }, []);

  useEffect(() => {
    if (saved) window.location.reload();
  }, [saved]);

  const handleAssociate = (e) => {
    setShowSelect(!showSelect);
  }

  const handleSelectChange = async (e) => {
    setProfileId(e);
  };

  const handleSave = async () => {
    try {
      const result = await updateProperty(property.id, { ...property, ["financial_profile_id"]: profileId });
      setSaved(true);
    } catch (e) {
      window.alert("Erro ao guardar a propriedade");
      console.error("Error gravar alterações", e);
    }
  };

  const styles = {
    labelStyles: {
      //textAlign: "center",
      fontSize: "16px",
      fontWeight: "bold",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
  };

  const financialProfileTrans = {
    created_at: "Preenchido em",
    name: "Nome",
    address: "Morada",
    type: "Tipo",
    fiscal_number: "NIF/NIPC",
    commercial_certificate_file: "Certificado Comercial",
    commercial_certificate_number: "Número de Certificado Comercial",
    id_doc_front_file: "Cartão de Cidadão (frente)",
    id_doc_back_file: "Cartão de Cidadão (verso)",
    id_doc_exp_date: "Validade Doc.",
    id_doc_type: "Tipo de Doc.",
    id_number: "Número do Doc.",
    manager_fiscal_number: "NIF/NIPC do Gestor",
    manager_id_doc_front_file: "Gestor Cartão de Cidadão (frente)",
    manager_id_doc_back_file: "Gestor Cartão de Cidadão (verso)",
    manager_id_doc_exp_date: "Gestor Validade Doc.",
    manager_id_number: "Gestor Numero do Doc.",
    marital_status: "Estado Civil",
    marital_property_regime: "Regime de Bens",
    occupation: "Ocupação",
    spouse_name: "Nome do Cônjuge",
    spouse_fiscal_number: "NIF do Cônjuge",
    spouse_id_doc_front_file: "Cônjuge Cartão de Cidadão (frente)",
    spouse_id_doc_back_file: "Cônjuge Cartão de Cidadão (verso)",
    spouse_id_doc_exp_date: "Cônjuge Validade Doc.",
    spouse_id_doc_type: "Cônjuge Tipo de Doc.",
    spouse_id_number: "Cônjuge Numero do Doc.",
    spouse_occupation: "Cônjuge Ocupação",
  };

  return (
    <div style={{ ...styles.pageStyles, textAlign: "center" }}>
      {["user_name", "user_email", "user_email_verified", "user_phone", "user_nationality"].some(
        (info, i) => !isEmpty(property[info]),
      ) && (
          <div>
            <h3 style={{ borderBottom: "2px solid black" }}>Informação do utilizador</h3>
            <div style={{ ...styles.labelStyles, textAlign: "initial", flexDirection: "column" }}>
              {["user_name", "user_email", "user_email_verified", "user_phone", "user_nationality"].map((userInfo, i) => {
                if (!isEmpty(property[userInfo])) {
                  return (
                    <span style={{ marginBottom: "15px" }} key={i}>
                      {`${capitalizeOnlyFirstLetter(userInfo.replace("user_", "").replace("_", " "))}: ${property[userInfo] === 0 ? "Não" : property[userInfo] === 1 ? "Sim" : property[userInfo]
                        }`}
                      <br />
                    </span>
                  );
                }
              })}
            </div>
          </div>
        )}
      <div>
        <h3 style={{ borderBottom: "2px solid black" }}>Informação do Proprietário</h3>
        {property.financial_profile ? (
          <div style={{ textAlign: "initial" }}>
            {Object.keys(property.financial_profile)
              .filter(k => k !== "id" && k !== "user_id" && property.financial_profile[k])
              .map((field, i) => {
                if (property.financial_profile[field] === "null") return;
                return (
                  <span key={i}>
                    <strong>{financialProfileTrans[field] || field}</strong>:{" "}
                    {field.includes("file") ? (
                      <Link target={"_blank"} to={s3Url + property.financial_profile[field]}>
                        Ver
                      </Link>
                    ) : financialProfileTrans[field] === "Preenchido em" ? (
                      dateFormat(property.financial_profile[field])
                    ) : (
                      property.financial_profile[field]
                    )}
                    <br />
                  </span>
                );
              })}
          </div>
        ) : (
          <>
            <p>Perfil financeiro não foi preenchido</p>
            <Flex gap="small" justify="start" style={{ marginBottom: 20 }}>
              {
                financialProfiles ? (
                  <>
                    {showSelect ? (
                      <>
                        <Button type="primary" onClick={handleSave}>Guardar</Button>
                        <Select
                          options={financialProfiles.map(profile => {
                            return {
                              label: `${profile.name}, ${profile.buyer === 1 ? "Comprador" : "Vendedor"} ${capitalizeOnlyFirstLetter(profile.type === "individual" ? profile.type : "comercial")}, ${(new Date(profile.created_at)).toLocaleDateString()}`,
                              value: profile.id
                            }
                          })}
                          onChange={handleSelectChange}
                          style={{
                            minWidth: 200,
                            // minWidth: "auto"
                          }}
                        />
                      </>

                    ) : (
                      <Button type="primary" onClick={handleAssociate}>Associar Perfil</Button>
                    )
                    }
                  </>
                ) : (
                  <Button type="primary"><Link to={`/users/${property.user_id}/financial_profile`}>Criar Novo</Link></Button>
                )
              }
            </Flex>
          </>
        )}
      </div>
    </div>
  );
};

export default UserTab;
