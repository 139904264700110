import { getAllProperties } from "../../services/propertyService";
import { useEffect, useState } from "react";
import Select from "react-select";
import { associateAdvisorProperties } from "../../services/user";
import { toast } from "react-toastify";

const CheckBoxProperty = ({ property, currentProperties, setCurrentProperties, propertyOption }) => {
  const [checked, setChecked] = useState(currentProperties.map(p => p.value).includes(property.id));

  useEffect(() => {
    setChecked(currentProperties.map(p => p.value).includes(property.id));
  }, [currentProperties.length]);

  return (
    <div className={"d-flex align-center"}>
      <input type={"checkbox"} checked={checked} readOnly />
      {propertyOption.label}
    </div>
  );
};
const AdvisorPropertiesSelect = ({ advisorProperties, advisorId, setAdvisor }) => {
  const [allProperties, setAllProperties] = useState([]);
  const [currentProperties, setCurrentProperties] = useState([]);

  useEffect(() => {
    getAllProperties(null, null, { allHouses: 1 }).then(res => {
      let _properties = res.properties.map(p => {
        if (["lisbon", "lisboa"].includes(p.county?.toLowerCase())) p.county = "Lisboa";
        return p;
      });
      setAllProperties(_properties);
      setCurrentProperties(
        _properties
          .filter(p => advisorProperties?.includes(p.id))
          .map(p => ({
            value: p.id,
            label: (
              <a onClick={e => e.stopPropagation()} href={"/properties/" + p.id} style={{ textDecoration: "none" }}>
                {p.reference + " | " + p.address}
              </a>
            ),
            county: p.county,
          })),
      );
    });
  }, []);

  const allPropertiesOptions = allProperties.map(p => ({
    value: p.id,
    label: (
      <a onClick={e => e.stopPropagation()} href={"/properties/" + p.id} style={{ textDecoration: "none" }}>
        {p.reference + " | " + p.address}
      </a>
    ),
    county: p.county,
  }));
  const counties = [...new Set(allProperties.map(p => p.county))];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: "280px",
      maxWidth: "670px",
      maxHeight: "550px",
      overflow: "auto",
    }),
  };

  const toggleCounty = county => {
    const toAdd = !isCountySelected(county);
    let countyProperties = allPropertiesOptions?.filter(o => (!county ? !o.county : o.county === county));
    if (toAdd) {
      let propertiesToAdd = countyProperties.filter(p => !currentProperties.map(p => p.value).includes(p.value));
      setCurrentProperties(prev => [...prev, ...propertiesToAdd]);
    } else {
      setCurrentProperties(prev => prev.filter(p => !countyProperties.map(p => p.value).includes(p.value)));
    }
  };
  const isCountySelected = county => {
    let properties = allProperties?.filter(p => (!county ? !p.county : p.county === county));
    //check if properties are inside currentProperties
    return properties.every(p => currentProperties.map(p => p.value).includes(p.id));
  };

  const MultiValueLabel = ({ children, innerProps, data }) => {
    return <div style={{ padding: "5px" }}>{data.title || data.label?.props?.children}</div>;
  };

  return (
    <Select
      styles={customStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder={"Associar propriedades"}
      isMulti
      onChange={o => setCurrentProperties(o)}
      onBlur={() => {
        toast.promise(
          associateAdvisorProperties(
            advisorId,
            currentProperties.map(p => p.value),
          ),
          {
            pending: "A associar propriedades",
            success: "Propriedades associadas com sucesso!",
            error: "Erro ao associar propriedades",
          },
        );
        setAdvisor(prev => ({ ...prev, properties: currentProperties.map(p => p.value) }));
      }}
      components={{ MultiValueLabel }}
      value={currentProperties}
      filterOption={(o, t) => {
        const optionLabel = o.label.props.propertyOption.label.props.children;
        return optionLabel.toLowerCase().includes(t.toLowerCase());
      }}
      options={counties.map(county => ({
        label: (
          <div>
            <input type={"checkbox"} checked={isCountySelected(county)} onChange={() => toggleCounty(county)} />
            {county || "Outros"}
          </div>
        ),
        options: allProperties
          .filter(p => p.county === county)
          .map(p => ({
            value: p.id,
            title: allPropertiesOptions.find(_p => _p.value === p.id).label,
            label: (
              <CheckBoxProperty
                property={p}
                currentProperties={currentProperties}
                setCurrentProperties={setCurrentProperties}
                propertyOption={allPropertiesOptions.find(_p => _p.value === p.id)}
              />
            ),
          })),
      }))}
    />
  );
};

export default AdvisorPropertiesSelect;
