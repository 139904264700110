import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import PageTabs from "../PageTabs";
import { getLead, getVisits } from "../../services/propertyService";
import LeadForm from "./createLead";
import { toast } from "react-toastify";
import GenericListing from "../GenericListing";

const Lead = () => {
  const params = useParams();
  const {
    isLoading,
    data: lead_data,
    isError,
    error,
    isPreviousData,
    refetch,
  } = useQuery([`lead`], async () => await getLead(Number(params.id)), {
    keepPreviousData: false,
    refetchOnMount: true,
    onSuccess: data => {
      setLead(data.data);
    },
  });
  const [activeFilters, setActiveFilters] = useState({ lead_id: Number(params.id) });
  const [page, setPage] = useState(1);

  const [selectedTab, setSelectedTab] = useState(params?.tab || "form");
  const [lead, setLead] = useState({ ...lead_data?.data });
  const navigate = useNavigate();

  useEffect(() => {
    if (lead_data) {
      setLead(lead_data.data);
      setPage(1);
      setActiveFilters(
        lead_data?.data?.user_id ? { user_id: Number(lead_data?.data?.user_id) } : { lead_id: Number(params.id) },
      );
    }
  }, [isLoading]);

  const tabs = {
    form: "Lead",
    visits: "Visitas",
  };

  const styles = {
    inputStyles: {
      margin: "10px",
    },
    pageStyles: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    containerStyles: {
      display: "flex",
      flexWrap: "wrap",
      width: "875px",
    },
    userDiv: {
      display: "flex",
      justifyContent: "flex-start",
      width: "400px",
      flexDirection: "column",
      alignItems: "center",
      fontWeight: "600",
    },
    infoDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  };

  useEffect(() => {
    setPage(1);
    setActiveFilters(
      lead_data?.data?.user_id ? { user_id: Number(lead_data?.data?.user_id) } : { lead_id: Number(params.id) },
    );
  }, [selectedTab]);

  const update = () => {
    refetch();
  };

  if (isError) {
    return <div>Something went wrong. {error.message}</div>;
  }

  //Leaving it like this for when listoo decides to add new tabs
  const listingProps = {
    visits: {
      name: "",
      url: "visits",
      table: "visit",
      getDataFunction: () => getVisits(page, "", activeFilters),
      fields: [
        ["ID", "id", ""],
        ["Casa", "property_reference", ""],
        ["Buyer", "buyer_name", "lead_name"],
        ["Pedido a", "created_at", "date"],
        ["Mensagem", "request_message", ""],
        ["Agendada para", "date", "hour"],
        ["Aceite", "accepted", "date"],
        ["Rejeitada", "rejected", "date"],
        ["Completa", "done", "boolean"],
        ["Rating", "rating", ""],
        ["Feedback", "feedback", ""],
        ["Ações", "", "visit"],
        ["Lead?", "lead_id", "boolean"],
      ],
      filters: [],
      user_id: Number(lead_data?.data?.user_id),
      lead_id: lead_data?.data?.id,
    },
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.pageStyles}>
      {!isLoading && <h1 onClick={() => console.log(lead)}>{lead.name}</h1>}

      <PageTabs tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} pathPrefix={"/leads"} />
      {selectedTab === "form" && (
        <div style={styles.infoDiv}>
          <LeadForm showForm={selectedTab === "form"} update={update} editableData={lead} />
          {!isLoading && lead.user_id && (
            <div style={styles.userDiv}>
              <p>Esta lead já têm uma conta de utilizador associada</p>
              <Link
                style={{ width: "80px", textDecoration: "none" }}
                className={"simple-button"}
                to={`/user/${lead.user_id}`}
              >
                Ver utilizador
              </Link>
            </div>
          )}
        </div>
      )}
      {selectedTab === "visits" && !isLoading && (
        <div>
          <GenericListing {...listingProps[String(selectedTab)]} />
        </div>
      )}
    </div>
  );
};

export default Lead;
