import { get, post } from "./index";

async function getAllAdvisors(page, searchKey, filters) {
  let res = await get("/backoffice/advisor/all");
  return res.data || [];
}

async function getAdvisor(advisorId) {
  return (await get("/backoffice/advisor/" + advisorId))?.data;
}

async function createAdvisor(name, email) {
  return await post("/backoffice/advisor/create", { name, email });
}

async function updateAdvisor(advisor_id, fields) {
  return await post(`/backoffice/advisor/${advisor_id}/update`, fields);
}

async function changeAdvisorPassword(advisor_id, password) {
  return await post(`/backoffice/advisor/changePassword/${advisor_id}`, { password });
}

async function deleteAdvisor(advisor_id) {
  return await post(`/backoffice/advisor/delete/${advisor_id}`);
}

async function associateDocumentToAdvisor(advisor_id, document_id, type) {
  return await post(`/backoffice/advisor/associateDocument/${type}`, { advisor_id, document_id });
}

async function removeAdvisorAssociation(advisor_id, document_id, type) {
  return await post(`/backoffice/advisor/removeAssociation/${type}`, { advisor_id, document_id });
}

async function associateAdvisorProperties(advisor_id, properties) {
  return await post("/backoffice/advisor/associateProperties", { advisor_id, properties });
}

async function changeUserPassword(user_id, password) {
  return await post(`/backoffice/changeUserPassword/${user_id}`, { password });
}

async function getNotifications() {
  let res = await get("/backoffice/notification/list");
  return res.data || [];
}

async function markNotificationRead(notification_id) {
  return await post(`/backoffice/notification/${notification_id}/read`);
}

async function markAllNotificationsRead() {
  return await post(`/backoffice/notification/all/read`);
}

async function registerUser(user) {
  return await post("/backoffice/registerUser", user);
}

async function getCountries() {
  let res = await get("/backoffice/countries");
  return res.data || [];
}

export {
  getAllAdvisors,
  getAdvisor,
  createAdvisor,
  updateAdvisor,
  deleteAdvisor,
  associateAdvisorProperties,
  changeAdvisorPassword,
  associateDocumentToAdvisor,
  removeAdvisorAssociation,
  changeUserPassword,
  getNotifications,
  markNotificationRead,
  markAllNotificationsRead,
  registerUser,
  getCountries,
};
