import React from "react";
import { useAuth } from "../../services/auth";
import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/listoo_logo.svg";
import "./styles.scss";
import Notifications from "../Notifications";

const Layout = ({ children }) => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const sideBarOptions = new Map([
    ["properties", "Casas"],
    ["visits", "Visitas"],
    ["proposals", "Ofertas"],
    ["processes", "Processos"],
    ["requests", "Pedidos"],
    ["users", "Utilizadores"],
    ["evaluations", "Avaliações"],
    ["leads", "Leads"],
  ]);

  if (user?.role === "admin") {
    sideBarOptions.set("advisors", "Advisors");
  }

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const isTabActive = tabName => {
    return location.pathname.startsWith("/" + tabName);
  };

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return (
      <div className={"Layout"}>
        <div className={"sidebar"}>
          <Link to={"/"} className={"logo-image"}>
            <img src={logo} alt={"listoo_logo"} />
          </Link>
          <div className={"tab-options"}>
            {Array.from(sideBarOptions.entries())
              ?.sort((a, b) => {
                return a[1].localeCompare(b[1]);
              })
              .map(([key, value]) => {
                return (
                  <Link
                    key={"link_" + key}
                    to={value === "Blog" ? key : "/" + key}
                    className={"option" + (isTabActive(key) ? " active" : "")}
                  >
                    {value}
                  </Link>
                );
              })}
          </div>
          <button className={"logout-button"} onClick={handleLogout}>
            Logout
          </button>
        </div>
        <div className={"tab-content"}>
          <Notifications />
          <Outlet />
        </div>
      </div>
    );
  }
};

export default Layout;
